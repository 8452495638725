import classNames from 'classnames'
import { ElementType } from 'react'

import { Box, BoxProps } from '@lounge-fe/ui-kit'

const defaultElement = 'div'

export const Grid = <C extends ElementType = typeof defaultElement>({
  as,
  className,
  ...props
}: BoxProps<C>) => {
  const element: React.ElementType = as || defaultElement
  return (
    <Box
      as={element}
      className={classNames(
        'grid',
        'grid-cols-2',
        'gap-x-2',
        'gap-y-6',
        'mb-4',
        String.raw`[&_>_*:nth-child(odd)_.product-card\_\_details]:pl-3`,
        String.raw`[&_>_*:nth-child(even)_.product-card\_\_details]:pr-3`,
        String.raw`md:[&_.product-card\_\_details]:!px-0`,
        'md:wrapper',
        'md:wrapper--size-md',
        'md:gap-x-4',
        'md:grid-cols-3',
        'xl:grid-cols-4',
        className
      )}
      {...props}
    />
  )
}
