import classnames from 'classnames'
import { useHash } from '@/hooks/useHash'
import { Icon, Modal, Text, usePopoverContext } from '@lounge-fe/ui-kit'
import { useEffect } from 'react'
import { Button } from './Button'
import { Video } from './Video'
import { CollectionProducts } from './CollectionProducts'
import { ModalGroupModalProps } from './types'
import { TextVariant } from '@lounge-fe/ui-kit/src/components/Text/types'

const blocksComponentMap: { [key: string]: React.ComponentType<any> } = {
  collection_products: CollectionProducts,
  button: Button,
  spacer: ({ spacer }: { spacer: string }) => <div className={spacer} />,
  text: ({
    text,
    tag,
    text_size: variant,
    margin_bottom: marginBottom,
  }: {
    text: string
    tag: 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
    text_size: TextVariant
    margin_bottom: string
  }) => (
    <Text
      as={tag}
      variant={variant as TextVariant}
      dangerouslySetInnerHTML={{ __html: text }}
      className={classnames(marginBottom)}
    />
  ),
}

export const BaseModalGroupModal = ({
  blocks,
  size,
  isMobileFullscreen,
  title,
  subtitle,
  sectionId,
  modalId,
  aspectRatio,
  video,
}: ModalGroupModalProps) => {
  const { isOpen, onOpen } = usePopoverContext()
  const [hash, updateHash] = useHash()

  useEffect(() => {
    if (hash && hash.replace('#', '') === modalId) {
      onOpen()
    }
  }, [hash])

  useEffect(() => {
    if (!isOpen && hash === `#${modalId}`) {
      updateHash('')
    }
  }, [isOpen])

  const hasVideo = video

  return (
    <>
      {hasVideo ? (
        <Modal.Content
          size="3xl"
          isMobileFullscreen={true}
          className="rounded-sm overflow-hidden"
        >
          <Video video={video} />

          <Modal.CloseButton className="!right-6 !bg-white" />
        </Modal.Content>
      ) : (
        <Modal.Content
          id={`shopify-section-${sectionId}`}
          size={size}
          isMobileFullscreen={isMobileFullscreen}
        >
          <Modal.Header>
            {title && (
              <Text
                dangerouslySetInnerHTML={{ __html: title }}
                variant="title-four"
                className="modal__title"
              />
            )}
            {subtitle && (
              <Text
                dangerouslySetInnerHTML={{ __html: subtitle }}
                variant="body-md"
                className="modal__subtitle text-[#7D7D7D] mt-3"
              />
            )}
            <Modal.CloseButton />
          </Modal.Header>

          <Modal.Body aspectRatio={aspectRatio}>
            {blocks.map(({ type, settings }, index) => {
              const Comp = blocksComponentMap[type]
              return <Comp key={index} {...settings} />
            })}
          </Modal.Body>
        </Modal.Content>
      )}
    </>
  )
}

export const ModalGroupModal = (props: ModalGroupModalProps) => {
  return (
    <Modal>
      <BaseModalGroupModal {...props} />
    </Modal>
  )
}
