import { ElementType } from 'react'
import classNames from 'classnames'

import { Box } from '../../components/Box'

import { ImageWrapperProps } from './types'

export const ImageWrapper = ({ className, ...props }: ImageWrapperProps) => (
  <Box
    as="div"
    className={classNames('product-card__image-wrapper', className)}
    {...props}
  />
)
