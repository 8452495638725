import {
  ExtendedProductHitWithVariants,
  ExtendedVariant,
  LiquidProduct,
} from '../../types'

const mapVariant = (
  variant: LiquidProduct['variants'][number],
  options: LiquidProduct['options']
): ExtendedVariant => ({
  id: variant.id,
  availableForSale: variant.available,
  title: variant.title,
  selectedOptions: options.map((option, idx) => ({
    name: option.name,
    value: variant.options[idx],
  })),
})

export const createLiquidExtendedProductHit = (
  product: LiquidProduct
): ExtendedProductHitWithVariants => {
  return {
    id: product.id,
    href: `/products/${product.handle}`,
    swatches: [],
    isFavourited: false,
    isLoadingAdditionalData: false,
    options: product.options.map((option) => ({
      name: option.name,
      values: option.values,
    })),
    title: product.title,
    handle: product.handle,
    color: product.color,
    images: product.images.map((image) => ({ src: image.src, alt: image.alt })),
    price: product.price,
    price_compare_at: product.compare_at_price ?? undefined,
    availableForSale: product.available,
    tags: product.tags ?? [],
    linkedBottoms:
      product.linked_bottoms?.map((linkedBottom) => ({
        id: linkedBottom.id,
        handle: linkedBottom.handle,
        images: linkedBottom.images.map((y) => ({ src: y.src, alt: y.alt })),
        title: linkedBottom.title,
        price: linkedBottom.price,
        price_compare_at: linkedBottom.compare_at_price ?? undefined,
        options: linkedBottom.options.map((y) => ({
          name: y.name,
          values: y.values,
        })),
        isFavourited: false,
        href: `/products/${linkedBottom.handle}`,
        availableForSale: linkedBottom.available,
        isLoadingAdditionalData: false,
        variants: linkedBottom.variants.map((variant) =>
          mapVariant(variant, linkedBottom.options)
        ),
      })) ?? [],
    variants: product.variants.map((variant) =>
      mapVariant(variant, product.options)
    ),
  }
}
