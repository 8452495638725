import React from 'react'

import { SvgProps } from '../types'
import { Icon } from '../Icon'

export const Bag: React.FC<SvgProps> = (props) => {
  return (
    <Icon
      fillRule="evenodd"
      clipRule="evenodd"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M3.42274 6.24414C3.35013 6.24414 3.28049 6.27298 3.22913 6.32433C3.22912 6.32433 3.22914 6.32432 3.22913 6.32433M16.7711 6.32431C16.7198 6.27301 16.6501 6.24414 16.5775 6.24414H3.42274M16.7711 6.32431C16.8225 6.37571 16.8513 6.44538 16.8513 6.51795V17.3513C16.8513 17.6291 16.7409 17.8956 16.5445 18.0921C16.348 18.2885 16.0815 18.3989 15.8037 18.3989H4.19654C3.91869 18.3989 3.65224 18.2885 3.45578 18.0921C3.2593 17.8956 3.14893 17.6291 3.14893 17.3513V6.51795C3.14893 6.44534 3.17778 6.37568 3.22913 6.32433M2.52202 5.61722C2.7609 5.37835 3.08489 5.24414 3.42274 5.24414H16.5775C16.9153 5.24414 17.2394 5.37833 17.4782 5.61726C17.717 5.85609 17.8513 6.18006 17.8513 6.51795V17.3513C17.8513 17.8943 17.6356 18.4151 17.2516 18.7992C16.8675 19.1832 16.3467 19.3989 15.8037 19.3989H4.19654C3.65349 19.3989 3.13266 19.1832 2.74865 18.7991C2.36466 18.4151 2.14893 17.8943 2.14893 17.3513V6.51795C2.14893 6.18011 2.28316 5.8561 2.52202 5.61722Z"
        fill="currentColor"
      />
      <path
        d="M9.99997 1.60107C9.20903 1.60107 8.45053 1.91526 7.89127 2.47452C7.33201 3.03378 7.01782 3.79231 7.01782 4.58322V8.06536C7.01782 8.3415 6.79396 8.56536 6.51782 8.56536C6.24168 8.56536 6.01782 8.3415 6.01782 8.06536V4.58322C6.01782 3.52709 6.43737 2.51421 7.18416 1.76742M7.18416 1.76742C7.93096 1.02062 8.94381 0.601074 9.99997 0.601074C11.0561 0.601074 12.069 1.02062 12.8158 1.76742C13.5626 2.51421 13.9821 3.52709 13.9821 4.58322V8.06536C13.9821 8.3415 13.7583 8.56536 13.4821 8.56536C13.206 8.56536 12.9821 8.3415 12.9821 8.06536V4.58322C12.9821 3.7923 12.6679 3.03378 12.1087 2.47452C11.5494 1.91527 10.7909 1.60107 9.99997 1.60107"
        fill="currentColor"
      />
    </Icon>
  )
}
