import React from 'react'

import { SvgProps } from '../types'
import { Icon } from '../Icon'

export const Account: React.FC<SvgProps> = (props) => {
  return (
    <Icon viewBox="0 0 20 20" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.17389 13.4307C4.94624 12.6379 7.35977 12.1602 9.99941 12.1602C12.6391 12.1602 15.0527 12.638 16.8251 13.4308C17.7108 13.827 18.4582 14.3116 18.9909 14.8748C19.5261 15.4406 19.8635 16.1083 19.8635 16.8479V19.8607H0.135742V16.8479C0.135742 16.1083 0.473046 15.4405 1.00822 14.8746C1.54091 14.3114 2.28831 13.8268 3.17389 13.4307ZM1.73477 15.5618C1.32239 15.9978 1.13574 16.4315 1.13574 16.8479V18.8607H18.8635V16.8479C18.8635 16.4317 18.6769 15.998 18.2644 15.562C17.8495 15.1233 17.2257 14.7055 16.4168 14.3437C14.8002 13.6205 12.5318 13.1602 9.99941 13.1602C7.46699 13.1602 5.19868 13.6204 3.58221 14.3435C2.77339 14.7053 2.14963 15.1231 1.73477 15.5618Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.78531 1.47796C7.63715 0.621298 8.79303 0.139771 9.99858 0.139771C11.2041 0.139771 12.36 0.621298 13.2119 1.47796C14.063 2.33439 14.5413 3.49507 14.5413 4.70545C14.5413 5.91571 14.0636 7.07638 13.2119 7.93293C12.36 8.78958 11.2046 9.27113 9.99858 9.27113C8.79258 9.27113 7.63713 8.78958 6.78531 7.93293C5.9336 7.07638 5.45584 5.91571 5.45584 4.70545C5.45584 3.49519 5.9336 2.33451 6.78531 1.47796ZM9.99858 1.13977C9.05999 1.13977 8.15909 1.51462 7.49442 2.18307C6.82963 2.85163 6.45584 3.75855 6.45584 4.70545C6.45584 5.65235 6.82963 6.55927 7.49442 7.22783C8.15911 7.89629 9.05959 8.27113 9.99858 8.27113C10.9376 8.27113 11.8381 7.89629 12.5028 7.22783C13.1675 6.55927 13.5413 5.65235 13.5413 4.70545C13.5413 3.75872 13.1672 2.85174 12.5028 2.18307M9.99858 1.13977C10.9371 1.13977 11.8381 1.51469 12.5028 2.18307Z"
        fill="currentColor"
      />
    </Icon>
  )
}
