import { HTMLAttributes } from 'react'

export const Skeleton = (props: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div className="product-card flex flex-col" {...props}>
      <div className="product-card__image aspect-product-card-image bg-background-primary-default animate-pulse" />
      <div className="product-card__details">
        <div className="bg-background-primary-default animate-pulse w-full h-4" />
        <div className="bg-background-primary-default animate-pulse w-[50%] h-4" />
        <div className="bg-background-primary-default animate-pulse w-[25%] h-4" />
      </div>
    </div>
  )
}
