import classNames from 'classnames'

import { Box, Image as BaseImage } from '../../components'

import { TileImageProps } from './types'

export const Image = ({
  children,
  className,
  alt,
  ...props
}: TileImageProps) => (
  <Box className={classNames('tile__image', className)}>
    <BaseImage alt={alt} {...props} />
  </Box>
)
