import { Controller } from 'react-hook-form'

import { ChoiceChips as BaseChoiceChips } from '../../ChoiceChips'

import type { ChoiceChipProps } from './types'

export const ChoiceChips = ({
  name,
  options,
  ...props
}: ChoiceChipProps): JSX.Element => (
  <Controller
    name={name}
    render={({ field }) => (
      <BaseChoiceChips {...field} {...props}>
        {options.map(({ label, value, disabled, className }, idx) => (
          <BaseChoiceChips.Item
            key={value}
            value={value}
            disabled={disabled}
            className={className}
          >
            <span>{label}</span>
          </BaseChoiceChips.Item>
        ))}
      </BaseChoiceChips>
    )}
  />
)
