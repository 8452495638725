import {
  createContext,
  PropsWithChildren,
  useContext,
  useId,
  useMemo,
  useState,
} from 'react'

import { PopoverGroupContextState } from './types'

const PopoverGroupContext = createContext<PopoverGroupContextState>({
  isInGroup: false,
  id: null,
  popoverIds: [],
  setPopoverIds: () => {},
})

export const usePopoverGroupContext = () => useContext(PopoverGroupContext)

export const Group = (props: PropsWithChildren) => {
  const id = useId()
  const [popoverIds, setPopoverIds] = useState<string[]>([])

  const context = useMemo(
    () => ({ id, popoverIds, setPopoverIds, isInGroup: true }),
    [id, popoverIds]
  )

  return <PopoverGroupContext.Provider value={context} {...props} />
}
