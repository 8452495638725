import React from 'react'

import { SvgProps } from '../types'
import { Icon } from '../Icon'

export const LinkedIn: React.FC<SvgProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.429 8.96924H13.143V10.8192C13.678 9.75524 15.05 8.79924 17.111 8.79924C21.062 8.79924 22 10.9172 22 14.8032V22.0002H18V15.6882C18 13.4752 17.465 12.2272 16.103 12.2272C14.214 12.2272 13.429 13.5722 13.429 15.6872V22.0002H9.429V8.96924ZM2.57 21.8302H6.57V8.79924H2.57V21.8302ZM7.143 4.55024C7.14315 4.88552 7.07666 5.21748 6.94739 5.52683C6.81812 5.83618 6.62865 6.11676 6.39 6.35224C5.9064 6.83287 5.25181 7.1019 4.57 7.10024C3.88939 7.09979 3.23631 6.83144 2.752 6.35324C2.51421 6.11696 2.32539 5.83607 2.19634 5.52668C2.0673 5.21728 2.00058 4.88547 2 4.55024C2 3.87324 2.27 3.22524 2.753 2.74724C3.23689 2.2684 3.89024 1.99994 4.571 2.00024C5.253 2.00024 5.907 2.26924 6.39 2.74724C6.872 3.22524 7.143 3.87324 7.143 4.55024Z"
      />
    </Icon>
  )
}
