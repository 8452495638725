import { useService } from '@/hooks/useService'
import { RestResponse } from '@lounge-fe/network'
import {
  Product,
  ProductConnection,
} from '@shopify/hydrogen-react/storefront-api-types'
import {
  InfiniteData,
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'
import { WishlistService } from '../services'
import { DeleteWishlistRequest } from '../services/WishlistService/types'

export const useWishlistDelete = (
  options?: Omit<
    UseMutationOptions<
      RestResponse<ProductConnection>,
      unknown,
      DeleteWishlistRequest,
      unknown
    >,
    'mutationFn'
  >
) => {
  const wishlistService = useService(new WishlistService())
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: wishlistService.delete,
    ...options,
    onMutate: (variables) => {
      queryClient.setQueryData<InfiniteData<{ wishlist: Product[] }>>(
        ['wishlist'],
        (data) => ({
          ...data,
          pages:
            data?.pages.map((page) => ({
              ...page,
              wishlist: page.wishlist.filter(
                (product) => product.id !== variables?.id
              ),
            })) ?? [],
          pageParams: data?.pageParams ?? [],
        })
      )
    },
  })
}
