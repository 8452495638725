import { useCallback, useState } from 'react'

import { UseDisclosureOptions } from './types'

export const useDisclosure = ({
  defaultOpen = false,
}: UseDisclosureOptions = {}) => {
  const [isOpen, setIsOpen] = useState<boolean>(defaultOpen)

  const onClose = useCallback(() => setIsOpen(false), [])

  const onOpen = useCallback(() => setIsOpen(true), [])

  const onToggle = useCallback(() => setIsOpen((prev) => !prev), [])

  return { isOpen, onOpen, onClose, onToggle }
}
