import { createContext, PropsWithChildren, useContext, useMemo } from 'react'

import { DisclosureContextProps, UseDisclosureReturn } from './types'
import { useDisclosure } from './useDisclosure'

const DisclosureContext = createContext<UseDisclosureReturn>(
  {} as UseDisclosureReturn
)

export const DisclosureProvider = ({
  isOpen: parentIsOpen,
  onOpen: parentOnOpen,
  onClose: parentOnClose,
  onToggle: parentOnToggle,
  ...props
}: PropsWithChildren<DisclosureContextProps>) => {
  const disclosure = useDisclosure()

  const context: UseDisclosureReturn = useMemo(
    () => ({
      isOpen: parentIsOpen ?? disclosure.isOpen,
      onOpen: parentOnOpen ?? disclosure.onOpen,
      onClose: parentOnClose ?? disclosure.onClose,
      onToggle: parentOnToggle ?? disclosure.onToggle,
    }),
    [parentIsOpen, parentOnClose, parentOnClose, parentOnToggle, disclosure]
  )

  return <DisclosureContext.Provider value={context} {...props} />
}

export const useDisclosureContext = () => useContext(DisclosureContext)
