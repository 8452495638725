import { useEffect, useState } from 'react'

const MILLISECONDS_IN_A_SECOND = 1000
const SECONDS_IN_A_MINUTE = 60
const MINUTES_IN_AN_HOUR = 60
const HOURS_IN_A_DAY = 24

/**
 * Custom hook to countdown to a target date
 * @param targetDate target date to countdown in "DD/MM/YYYY HH:MM" format
 * @returns Object containing hasEnded boolean and remainingTime string
 */
const useCountdown = (targetDate?: string) => {
  if (!targetDate)
    return {
      hasEnded: true,
      remainingTime: {
        day: 0,
        hour: 0,
        minute: 0,
        second: 0,
      },
    }

  const [[day, month, year], [time]] = targetDate
    .split(' ')
    .map((date) => date.split('/'))

  const [hour, minute] = time.split(':')

  const countDownDate = new Date(
    Date.UTC(parseInt(year), parseInt(month) - 1, parseInt(day))
  )
  countDownDate.setUTCHours(parseInt(hour))
  countDownDate.setUTCMinutes(parseInt(minute))

  const [countDown, setCountDown] = useState(
    countDownDate.getTime() - Date.now()
  )

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate.getTime() - Date.now())
    }, 1000)

    return () => clearInterval(interval)
  }, [countDownDate])

  return {
    hasEnded: countDown <= 0,
    remainingTime: getRemainingTime(countDown),
  }
}

const getRemainingTime = (countDown: number) => {
  const totalSeconds = countDown / MILLISECONDS_IN_A_SECOND
  const totalMinutes = totalSeconds / SECONDS_IN_A_MINUTE
  const totalHours = totalMinutes / MINUTES_IN_AN_HOUR

  const day = Math.floor(totalHours / HOURS_IN_A_DAY)
  const hour = Math.floor(totalHours % HOURS_IN_A_DAY)
  const minute = Math.floor(totalMinutes % MINUTES_IN_AN_HOUR)
  const second = Math.floor(totalSeconds % SECONDS_IN_A_MINUTE)

  return {
    day,
    hour,
    minute,
    second,
  }
}

export { useCountdown }
