import classNames from 'classnames'

import { HTMLAttributes, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Icon } from '../../Icon'
import { useCarouselContext } from '../Carousel'
import { Arrow } from './Arrow'
import { IconButtonProps } from '../../IconButton/types'

export const ArrowPrevious: React.FC<
  Omit<IconButtonProps, 'icon' | 'aria-label'>
> = ({ className, ...props }) => {
  const { t } = useTranslation()
  const { canScrollPrev, embla } = useCarouselContext()
  const onClick = useCallback(() => embla?.scrollPrev(), [embla])

  if (!canScrollPrev) {
    return null
  }
  return (
    <Arrow
      icon={<Icon.ChevronLeft className="w-[6px] !text-base-primary" />}
      className={classNames(className, 'left-0 -translate-x-1/2')}
      onClick={onClick}
      aria-label={t('action.back')}
      {...props}
    />
  )
}
