import classNames from 'classnames'
import { MouseEventHandler, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Icon, IconButton } from '../../../components'
import { useCarouselContext } from '../../../components/Carousel/Carousel'
import { IconButtonProps } from '../../../components/IconButton/types'

const Arrow = ({ className, onClick, icon, ...props }: IconButtonProps) => (
  <IconButton
    icon={icon}
    variant="ghost"
    className={classNames(
      className,
      '!bg-white/60 backdrop-blur-sm z-10 top-1/2 hidden md:inline-flex absolute group-hover:opacity-100 opacity-0 transition-opacity'
    )}
    onClick={onClick}
    size="sm"
    {...props}
  />
)

export const ArrowLeft = () => {
  const { t } = useTranslation()
  const { canScrollPrev, embla } = useCarouselContext()
  const onClick: MouseEventHandler = useCallback(
    (evt) => {
      evt.preventDefault()
      embla?.scrollPrev()
    },
    [embla]
  )

  if (!canScrollPrev) {
    return null
  }
  return (
    <Arrow
      icon={<Icon.ChevronLeft className="w-[6px] !text-base-primary" />}
      className="left-4"
      onClick={onClick}
      aria-label={t('action.back')}
    />
  )
}

export const ArrowRight = () => {
  const { t } = useTranslation()
  const { canScrollNext, embla } = useCarouselContext()
  const onClick: MouseEventHandler = useCallback(
    (evt) => {
      evt.preventDefault()
      embla?.scrollNext()
    },
    [embla]
  )

  if (!canScrollNext) {
    return null
  }

  return (
    <Arrow
      icon={<Icon.ChevronRight className="w-[6px] !text-base-primary" />}
      className={classNames('right-4')}
      onClick={onClick}
      aria-label={t('action.next')}
    />
  )
}
