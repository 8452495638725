import { SvgProps } from '../types'
import { Icon } from '../Icon'

export const ChevronRight = (props: SvgProps) => {
  return (
    <Icon fillRule="evenodd" clipRule="evenodd" viewBox="0 0 10 14" {...props}>
      <path
        d="M1.31662 0.613021C1.1029 0.438158 0.787885 0.469659 0.613021 0.683381C0.438158 0.897104 0.469659 1.21211 0.683381 1.38698L1.31662 0.613021ZM9 7.54545L9.28167 7.95857L9.83413 7.58189L9.31662 7.15848L9 7.54545ZM0.683381 1.38698L8.68338 7.93243L9.31662 7.15848L1.31662 0.613021L0.683381 1.38698ZM8.71833 7.13234L0.718332 12.5869L1.28167 13.4131L9.28167 7.95857L8.71833 7.13234Z"
        fill="currentColor"
      />
    </Icon>
  )
}
