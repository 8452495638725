import React from 'react'

import { SvgProps } from '../types'
import { Icon } from '../Icon'

export const Eye: React.FC<SvgProps> = (props) => {
  return (
    <Icon width="16" height="10" viewBox="0 0 16 10" fill="none" {...props}>
      <path d="M15.5125 4.59688C13.5156 2.1375 10.8468 0.78125 7.99995 0.78125C5.15308 0.78125 2.48433 2.1375 0.487451 4.59688C0.296826 4.83125 0.296826 5.16562 0.487451 5.4C2.48433 7.8625 5.15308 9.21875 7.99995 9.21875C10.8468 9.21875 13.5156 7.8625 15.5125 5.40312C15.7031 5.16875 15.7031 4.83125 15.5125 4.59688V4.59688ZM7.99995 7.09062C6.84683 7.09062 5.90933 6.15313 5.90933 5C5.90933 3.84687 6.84683 2.90938 7.99995 2.90938C9.15308 2.90938 10.0906 3.84687 10.0906 5C10.0906 6.15313 9.15308 7.09062 7.99995 7.09062ZM1.8187 5C2.99995 3.68125 4.40933 2.77813 5.92808 2.34688C5.14058 2.9625 4.6312 3.92188 4.6312 5C4.6312 6.075 5.13745 7.03437 5.92808 7.65312C4.40933 7.22187 3.00308 6.31875 1.8187 5ZM10.0718 7.65312C10.8593 7.0375 11.3687 6.07812 11.3687 5C11.3687 3.925 10.8625 2.96563 10.0718 2.34688C11.5906 2.77813 13 3.68125 14.1812 5C12.9968 6.31875 11.5906 7.22187 10.0718 7.65312Z" />
    </Icon>
  )
}
