import { Controller } from 'react-hook-form'

import { Select as BaseSelect } from '../../Select'

import { SelectProps } from './types'

export const Select = ({
  as = 'div',
  name,
  label,
  options,
  'aria-label': ariaLabel,
  placeholder,
  ...props
}: SelectProps): JSX.Element => (
  <Controller
    name={name}
    render={({ field }) => (
      <BaseSelect as={as} {...props}>
        {label && (
          <BaseSelect.Label>
            {props.required ? `${label}*` : label}
          </BaseSelect.Label>
        )}
        <BaseSelect.Menu
          aria-label={ariaLabel}
          placeholder={placeholder}
          {...field}
          value={field.value ?? ''}
        >
          {placeholder && (
            <BaseSelect.Option
              data-testid="option-placeholder"
              value={placeholder}
              disabled
            >
              {placeholder}
            </BaseSelect.Option>
          )}
          {options.map((option) => (
            <BaseSelect.Option
              key={option.value}
              value={option.value}
              disabled={option.disabled}
            >
              {option.label}
            </BaseSelect.Option>
          ))}
        </BaseSelect.Menu>
      </BaseSelect>
    )}
  />
)
