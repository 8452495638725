import { useEffect, useMemo, useState } from 'react'
import { theme, Theme } from '@lounge-fe/tailwind'

export const useMediaQuery = (
  queryFn: string | ((theme: Theme) => string)
): boolean => {
  const matchMedia =
    typeof window !== 'undefined' ? window.matchMedia : undefined

  const query = useMemo(
    () => (typeof queryFn === 'function' ? queryFn(theme) : queryFn),
    [queryFn]
  )

  const getMatches = (query: string): boolean =>
    matchMedia ? matchMedia?.(query).matches : false

  const [matches, setMatches] = useState<boolean>(
    typeof query === 'boolean' ? query : getMatches(query)
  )

  function handleChange() {
    setMatches(getMatches(query))
  }

  useEffect(() => {
    const matchedMedia = matchMedia ? matchMedia(query) : null
    handleChange()

    if (matchedMedia?.addEventListener) {
      matchedMedia.addEventListener('change', handleChange)
    } else {
      // Support for old browsers
      matchedMedia?.addListener(handleChange)
    }

    return () => {
      if (matchedMedia?.removeEventListener) {
        matchedMedia?.removeEventListener('change', handleChange)
      } else {
        // Support for old browsers
        matchedMedia?.removeListener(handleChange)
      }
    }
  }, [query])

  return matches
}
