import { useMemo } from 'react'

import { useToggleGroupContext } from './ToggleGroup'
import { ToggleGroupItemProps } from './types'

export const Item = ({ children, value, ...props }: ToggleGroupItemProps) => {
  const { selectedValue, onChange } = useToggleGroupContext()

  const selected = useMemo(
    () =>
      Array.isArray(selectedValue)
        ? selectedValue.includes(value)
        : selectedValue === value,
    [selectedValue, value]
  )

  return (
    <button
      role={Array.isArray(selectedValue) ? 'checkbox' : 'radio'}
      aria-checked={selected}
      type="button"
      onClick={() => onChange(value)}
      {...props}
    >
      {typeof children === 'function' ? children({ selected }) : children}
    </button>
  )
}
