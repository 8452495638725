import React from 'react'

import { SvgProps } from '../types'
import { Icon } from '../Icon'

export const Trash: React.FC<SvgProps> = (props) => {
  return (
    <Icon width="17" height="16" viewBox="0 0 17 16" {...props}>
      <path
        d="M4.50065 12.6665C4.50065 13.0202 4.64113 13.3593 4.89118 13.6094C5.14122 13.8594 5.48036 13.9999 5.83398 13.9999H11.1673C11.5209 13.9999 11.8601 13.8594 12.1101 13.6094C12.3602 13.3593 12.5007 13.0202 12.5007 12.6665V4.66654H4.50065V12.6665ZM5.83398 5.99988H11.1673V12.6665H5.83398V5.99988ZM10.834 2.66654L10.1673 1.99988H6.83398L6.16732 2.66654H3.83398V3.99988H13.1673V2.66654H10.834Z"
        fill="currentColor"
      />
    </Icon>
  )
}
