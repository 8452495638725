import {
  Children,
  cloneElement,
  createContext,
  forwardRef,
  isValidElement,
  KeyboardEventHandler,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'

import { ListboxProps } from './types'

const ListboxContext = createContext({
  selectedIndex: 0,
  hasFocus: false,
  setSelectedIndex: (_: number) => {},
})

export const useListboxContext = () => useContext(ListboxContext)

export const Listbox = forwardRef<HTMLUListElement | null, ListboxProps>(
  ({ children, onKeyDown: parentOnKeyDown, onSelect, ...props }, ref) => {
    const [hasFocus, setHasFocus] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState(0)
    const innerRef = useRef<HTMLUListElement | null>(null)

    useImperativeHandle<HTMLUListElement | null, HTMLUListElement | null>(
      ref,
      () => innerRef.current
    )

    useEffect(() => {
      innerRef?.current
        ?.querySelector('[aria-selected="true"]')
        ?.scrollIntoView({ block: 'nearest' })
    }, [selectedIndex])

    const onKeyDown: KeyboardEventHandler<HTMLUListElement> = (evt) => {
      switch (evt.key) {
        case 'ArrowUp':
          evt.preventDefault()
          setSelectedIndex((idx) => {
            const nextIndex = idx - 1
            return nextIndex < 0 ? children.length - 1 : nextIndex
          })
          break
        case 'ArrowDown':
          evt.preventDefault()
          setSelectedIndex((idx) => {
            const nextIndex = idx + 1
            return nextIndex > children.length - 1 ? 0 : nextIndex
          })
          break
        case 'Enter':
        case ' ':
          onSelect?.(selectedIndex)
          break
      }

      parentOnKeyDown?.(evt)
    }

    const context = useMemo(
      () => ({
        hasFocus,
        setSelectedIndex,
        selectedIndex,
      }),
      [hasFocus, selectedIndex]
    )

    return (
      <ListboxContext.Provider value={context}>
        <ul
          role="listbox"
          onKeyDown={onKeyDown}
          onFocus={() => setHasFocus(true)}
          onBlur={() => setHasFocus(false)}
          onMouseEnter={() => {
            innerRef.current?.focus()
            setHasFocus(true)
          }}
          onClick={() => onSelect?.(selectedIndex)}
          tabIndex={0}
          ref={innerRef}
          {...props}
        >
          {Children.map(children, (child, index) =>
            isValidElement(child) ? cloneElement(child, { index }) : child
          )}
        </ul>
      </ListboxContext.Provider>
    )
  }
)

Listbox.displayName = 'Listbox'
