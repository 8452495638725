import classNames from 'classnames'
import { forwardRef } from 'react'

import { Popover } from '../Popover'
import { Content as PopoverContent } from '../Popover/Content'
import { Portal } from '../Portal'
import { Transition } from '../Transition'

import { ModalContentProps, ModalSize } from './types'

const sizeClassnames: { [key in ModalSize]: string } = {
  sm: 'sm:max-w-[343px]',
  md: 'sm:max-w-[544px]',
  lg: 'sm:max-w-[656px]',
  xl: 'sm:max-w-[838px]',
  '2xl': 'sm:max-w-[1024px]',
  '3xl': 'sm:max-w-[1280px]',
}

export const Content = forwardRef<HTMLDivElement, ModalContentProps>(
  (
    { children, size = 'md', isMobileFullscreen = false, className, ...props },
    ref
  ) => {
    return (
      <Portal>
        <Popover.Backdrop className="z-[60]" />
        <Transition.Fade
          className={classNames(
            `fixed left-0 right-0 z-[70] flex items-center justify-center pointer-events-none`,
            isMobileFullscreen
              ? 'top-0 bottom-0 sm:top-10 sm:bottom-10'
              : 'top-10 bottom-10'
          )}
        >
          <PopoverContent
            className={classNames(
              'flex flex-col bg-white max-h-full w-full pointer-events-auto relative',
              isMobileFullscreen
                ? 'sm:rounded-md h-full sm:h-auto mx-0 sm:mx-4'
                : 'rounded-md h-auto mx-4',
              sizeClassnames[size],
              className
            )}
            ref={ref}
            {...props}
          >
            {children}
          </PopoverContent>
        </Transition.Fade>
      </Portal>
    )
  }
)

Content.displayName = 'ModalContent'
