import { useCallback, useMemo, useRef } from 'react'

export const useTimer = (callback: () => unknown, delay: number) => {
  const timer = useRef(0)
  const remainingMs = useRef(delay)
  const startMs = useRef(0)

  const start = useCallback(() => {
    clearTimeout(timer.current)
    timer.current = window.setTimeout(callback, delay)
    startMs.current = Date.now()
    remainingMs.current = delay
  }, [])

  const resume = useCallback(() => {
    startMs.current = Date.now()
    window.clearTimeout(timer.current)
    timer.current = window.setTimeout(callback, remainingMs.current)
  }, [])

  const pause = useCallback(() => {
    window.clearTimeout(timer.current)
    remainingMs.current -= Date.now() - startMs.current
  }, [])

  const clear = useCallback(() => {
    window.clearTimeout(timer.current)
    startMs.current = 0
    remainingMs.current = delay
  }, [])

  return useMemo(
    () => ({
      start,
      resume,
      pause,
      clear,
    }),
    []
  )
}
