import cookie from 'js-cookie'
import jwtDecode from 'jwt-decode'
import { RestClient, RestResponse } from '@lounge-fe/network'

import { AUTH_ID_COOKIE, DEFAULT_PAGES } from './constants'
import {
  AuthInitOptions,
  AuthPages,
  IdTokenResult,
  LoginRequest,
  LoginResponse,
  LogoutResponse,
  RecoverPasswordRequest,
  RegisterRequest,
  RegisterResponse,
  ResetPasswordRequest,
  ResetPasswordResponse,
  UpdatePasswordRequest,
  UpdatePasswordResponse,
  User,
} from './types'

export class Auth {
  private idCookieName: string
  private shopifyStore: string
  private pages: AuthPages
  private client: RestClient

  constructor({
    baseURL,
    shopifyStore,
    idCookieName = AUTH_ID_COOKIE,
    pages = {},
  }: AuthInitOptions) {
    this.client = new RestClient({
      baseURL,
      headers: {
        'x-shopify-store': shopifyStore,
      },
    })

    this.shopifyStore = shopifyStore
    this.idCookieName = idCookieName
    this.pages = {
      ...DEFAULT_PAGES,
      ...pages,
    }
  }

  getPages = (): AuthPages => this.pages

  /**
   * Calls login endpoint and fetches a multipass token to login with shopify.
   * @param req.email The email address of the user.
   * @param req.password The password of the user.
   * @returns A return_to url which must be re-directed to in order to sign in with multipass.
   */
  login = (req: LoginRequest): Promise<RestResponse<LoginResponse>> =>
    this.client.post('/auth/login', req)

  /**
   * Calls logout endpoint.
   * @returns A return_to url which must be re-directed to in order to logout with shopify
   */
  logout = (): Promise<RestResponse<LogoutResponse>> =>
    this.client.get('/auth/logout')

  /**
   * Send a forgot password email which reset password instructions.
   * @param req.email The email address to send the email to.
   * @returns null
   */
  recoverPassword = async (
    req: RecoverPasswordRequest
  ): Promise<RestResponse<null>> => this.client.post('/auth/recover', req)

  /**
   * Resets the password for a given user if they have a valid token from a reset password email.
   * @param userId The user ID to reset. Should be included in link for reset password email.
   * @param req.password The new password to use.
   * @param req.resetToken The reset token obtained from the reset password email.
   * @returns A return_to url which must be re-directed to in order to sign in with multipass.
   */
  resetPassword = async (
    userId: string,
    req: ResetPasswordRequest
  ): Promise<RestResponse<ResetPasswordResponse>> =>
    this.client.post(`/auth/${userId}/reset-password`, req)

  /**
   * Updates the password for a currently signed in user.
   * @param req.password The new password to use.
   * @returns
   */
  updatePassword = async (
    req: UpdatePasswordRequest
  ): Promise<RestResponse<UpdatePasswordResponse>> =>
    this.client.post('/auth/update-password', req)

  /**
   * Creates a new user account.
   * @param req.email The email address for the user.
   * @param req.password The password for the user.
   * @param req.firstName Optional
   * @param req.lastName Optional
   * @param req.phone Optional
   * @returns The login URL to redirect to after successfully creating the account.
   */
  register = async (
    req: RegisterRequest
  ): Promise<RestResponse<RegisterResponse>> =>
    this.client.post('/auth/register', req)

  /**
   * Checks the current time against expiry cookie received on login.
   * @returns Whether the user is currently authenticated.
   */
  getUser(onError?: (error: unknown) => unknown): User | null {
    const idCookie = cookie.get(this.idCookieName)
    try {
      if (idCookie) {
        const idToken = jwtDecode<IdTokenResult>(idCookie)
        const expiryDate = new Date(idToken.exp * 1000)
        const nowDate = new Date()
        const isValid =
          nowDate < expiryDate && idToken.store === this.shopifyStore

        if (isValid) {
          return {
            id: idToken.sub,
            email: idToken.email,
          }
        }
      }
    } catch (err) {
      onError?.(err)
    }

    return null
  }

  hasIdCookie(): boolean {
    return Boolean(cookie.get(this.idCookieName))
  }
}
