import classNames from 'classnames'
import { FC } from 'react'

import { Checkbox } from '../Checkbox'
import { Text } from '../Text'

import { useCheckboxGroupContext } from './CheckboxGroup'
import { CheckboxGroupItemProps } from './types'

export const Item: FC<CheckboxGroupItemProps> = ({
  value,
  label,
  disabled,
  checked,
  className,
  ...props
}) => {
  const { name, onChange, value: selectedValue } = useCheckboxGroupContext()

  const itemId = `${name}-${value}`

  return (
    <div
      className={classNames(
        'radio-group__item',
        disabled && 'radio-group__item--disabled',
        className
      )}
      {...props}
    >
      <Checkbox
        id={itemId}
        name={itemId}
        disabled={disabled}
        checked={checked ?? selectedValue.includes(value)}
        onChange={onChange ? () => onChange(value) : undefined}
        label={label}
      />
    </div>
  )
}
