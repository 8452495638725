import classNames from 'classnames'

import { TableProps, TableSize, TableVariant } from './types'

const defaultVariant: TableVariant = 'basic'

const variantClasses: {
  [key in Exclude<TableVariant, typeof defaultVariant>]: string
} = {
  'sticky-column': 'table--sticky-column',
}

const defaultSize: TableSize = 'md'
const sizeClasses: { [key in Exclude<TableSize, typeof defaultSize>]: string } =
  {
    lg: 'table--size-lg',
  }

export const Table = ({
  className,
  variant = defaultVariant,
  size = defaultSize,
  ...props
}: TableProps) => (
  <table
    cellPadding={0}
    cellSpacing={0}
    className={classNames(
      'table',
      variant !== defaultVariant && variantClasses[variant],
      size !== defaultSize && sizeClasses[size],
      className
    )}
    {...props}
  />
)
