import { ShopifySectionComponent } from '@/types/ShopifySectionComponent'
import {
  Card,
  Badge,
  Text,
  useMediaQuery,
  Carousel,
  Link,
  Wrapper,
} from '@lounge-fe/ui-kit'
import useEmblaCarousel from 'embla-carousel-react'

import { TipsData } from './types'
import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures'

export const Tips: ShopifySectionComponent<TipsData> = ({ data: schema }) => {
  const { tips, card_background } = schema!

  const isSmallScreen = useMediaQuery(
    ({ screens }) => `(max-width: ${screens.md})`
  )

  const [emblaRef, embla] = useEmblaCarousel(
    {
      align: 'start',
      containScroll: 'trimSnaps',
      skipSnaps: true,
      active: isSmallScreen,
      loop: false,
    },
    [WheelGesturesPlugin()]
  )

  return (
    <Wrapper className="mt-8">
      <Carousel emblaRef={emblaRef} embla={embla}>
        <Carousel.Viewport showOverflow>
          <Carousel.Container className="gap-4 auto-cols-[75%] md:gap-6 md:grid-cols-2 xl:grid-cols-4 md:!grid-flow-row">
            {tips.map((tip, idx) => (
              <Carousel.Slide index={idx} key={idx}>
                <Card
                  border={false}
                  className={`justify-center min-h-[176px] lg:min-h-[268px] items-center px-[56px] text-center gap-6 py-10 h-full ${card_background}`}
                >
                  {tip.badge && (
                    <Badge color="tertiary" size="lg">
                      {tip.badge}
                    </Badge>
                  )}
                  <Text variant="title-five">{tip.title}</Text>

                  {tip.button_url && (
                    <Link
                      className="underline font-medium typography typography--body-sm"
                      href={tip.button_url}
                    >
                      {tip.button_label}
                    </Link>
                  )}
                </Card>
              </Carousel.Slide>
            ))}
          </Carousel.Container>
        </Carousel.Viewport>
      </Carousel>
    </Wrapper>
  )
}
