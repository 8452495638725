import { useWishlistAdd } from '@/features/account/hooks/useWishlistAdd'
import { useWishlistDelete } from '@/features/account/hooks/useWishlistDelete'
import { useWishlistItems } from '@/features/account/hooks/useWishlistItems'
import { useAuth } from '@lounge-fe/auth'
import { ProductCard } from '@lounge-fe/ui-kit'
import { useQueryClient } from '@tanstack/react-query'
import { useMemo } from 'react'
import { AddToWishlistProps } from './types'
import { LoginModal } from '@/features/auth/components/LoginModal'
import { useTranslation } from 'react-i18next'

export const AddToWishlist = ({ productId }: AddToWishlistProps) => {
  const { t } = useTranslation()
  const gid = `gid://shopify/Product/${productId}`

  const queryClient = useQueryClient()
  const { mutateAsync: addToWishlist, isPending: isAdding } = useWishlistAdd()
  const { mutateAsync: removeFromWishlist, isPending: isDeleting } =
    useWishlistDelete()
  const {
    data: wishlistResponse,
    refetch,
    isLoading,
    queryKey,
    error,
  } = useWishlistItems()
  const { status } = useAuth()

  const isWishlisted = useMemo(
    () => wishlistResponse?.some((id) => id === gid) ?? false,
    [wishlistResponse]
  )

  const handleChange = async (isWishlisted: boolean) => {
    await queryClient.cancelQueries({ queryKey })
    if (isWishlisted) {
      queryClient.setQueryData(queryKey, (old: string[] | undefined = []) => [
        ...old,
        gid,
      ])
      await addToWishlist({ id: gid })
    } else {
      queryClient.setQueryData(queryKey, (old: string[] | undefined = []) =>
        old.filter((id) => id !== gid)
      )
      await removeFromWishlist({ id: gid })
    }

    await refetch()
  }

  if (status !== 'authenticated' || error) {
    return (
      <LoginModal
        title={t('account.wishlist.title')}
        description={t('account.wishlist.unauthenticated_modal_description')}
        onSuccess={async () => {
          await handleChange(!isWishlisted)
        }}
        trigger={
          <ProductCard.FavouriteButton
            isFavourited={false}
            onChange={() => {}}
            disabled={isLoading || isAdding || isDeleting}
          />
        }
      />
    )
  }

  return (
    <ProductCard.FavouriteButton
      isFavourited={isWishlisted}
      onChange={handleChange}
      disabled={isLoading || isAdding || isDeleting}
    />
  )
}
