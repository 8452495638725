import { Button as BaseButton } from '@lounge-fe/ui-kit'
import { ButtonProps } from './types'

export const Button = ({ label, link, size, color, variant }: ButtonProps) => (
  <div className="flex justify-center">
    <BaseButton
      as="a"
      href={link}
      size={size}
      color={color}
      variant={variant}
      className="mx-auto"
    >
      {label}
    </BaseButton>
  </div>
)
