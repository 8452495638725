import i18n from 'i18next'
import * as z from 'zod'
import { makeZodI18nMap } from 'zod-i18n-map'
import ChainedBackend from 'i18next-chained-backend'
import { initReactI18next } from 'react-i18next'
import resourcesToBackend from 'i18next-resources-to-backend'

const lng = window.Shopify.locale.includes('-')
  ? window.Shopify.locale
  : `${window.Shopify.locale}-${window.Shopify.country}`

const resources: { [key: string]: { [key: string]: object } } = {}

const additionalNamespaces = ['validation']

i18n
  .use(ChainedBackend)
  .use(initReactI18next)
  .init({
    backend: {
      backends: [
        resourcesToBackend(
          async (lang: string, namespace: string, callback) => {
            try {
              if (!resources[lang]) {
                resources[lang] = await import(
                  `../shopify/locales/${lang}.json`
                )
              }

              if (additionalNamespaces.includes(namespace)) {
                return callback(null, resources[lang][namespace])
              }

              const defaultNamespaceEntries = Object.fromEntries(
                Object.entries(resources[lang]).filter(
                  ([key]) => !additionalNamespaces.includes(key)
                )
              )

              return callback(null, defaultNamespaceEntries)
            } catch (error) {
              return callback(error as Error, null)
            }
          }
        ),
      ],
    },
    ns: ['default', ...additionalNamespaces],
    defaultNS: 'default',
    fallbackLng: 'en-GB',
    lng: lng,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })
  .then(() => {
    z.setErrorMap(
      makeZodI18nMap({
        t: i18n.t,
        ns: 'validation',
      })
    )
  })

i18n.addResourceBundle(lng, 'data', window.Lounge.data_translations ?? {})

export default i18n
