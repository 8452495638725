import { SvgProps } from '../types'
import { Icon } from '../Icon'

export const MapMarker: React.FC<SvgProps> = (props) => (
  <Icon width="16" height="20" viewBox="0 0 16 20" fill="none" {...props}>
    <path
      d="M8.00012 10.001C8.55012 10.001 9.02112 9.80498 9.41312 9.41298C9.80512 9.02098 10.0008 8.55031 10.0001 8.00098C10.0001 7.45098 9.80412 6.97998 9.41212 6.58798C9.02012 6.19598 8.54946 6.00031 8.00012 6.00098C7.45012 6.00098 6.97912 6.19698 6.58712 6.58898C6.19512 6.98098 5.99946 7.45164 6.00012 8.00098C6.00012 8.55098 6.19612 9.02198 6.58812 9.41398C6.98012 9.80598 7.45079 10.0016 8.00012 10.001ZM8.00012 17.351C10.0335 15.4843 11.5418 13.7883 12.5251 12.263C13.5085 10.7376 14.0001 9.38364 14.0001 8.20098C14.0001 6.38431 13.4208 4.89698 12.2621 3.73898C11.1035 2.58098 9.68279 2.00164 8.00012 2.00098C6.31679 2.00098 4.89579 2.58031 3.73712 3.73898C2.57846 4.89764 1.99946 6.38498 2.00012 8.20098C2.00012 9.38431 2.49179 10.7386 3.47512 12.264C4.45846 13.7893 5.96679 15.485 8.00012 17.351ZM8.00012 20.001C5.31679 17.7176 3.31279 15.597 1.98812 13.639C0.663455 11.681 0.000788737 9.86831 0.00012207 8.20098C0.00012207 5.70098 0.804456 3.70931 2.41312 2.22598C4.02179 0.742643 5.88412 0.000976562 8.00012 0.000976562C10.1168 0.000976562 11.9795 0.742643 13.5881 2.22598C15.1968 3.70931 16.0008 5.70098 16.0001 8.20098C16.0001 9.86764 15.3375 11.6803 14.0121 13.639C12.6868 15.5976 10.6828 17.7183 8.00012 20.001Z"
      fill="#7D7D7D"
    />
  </Icon>
)
