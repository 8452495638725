import { Controller } from 'react-hook-form'

import { Input as BaseInput } from '../../Input'

import { InputProps } from './types'

export const Input = ({
  name,
  errorText,
  ...props
}: InputProps): JSX.Element => (
  <Controller
    name={name}
    render={({ field, fieldState }) => (
      <BaseInput
        errorText={errorText ?? fieldState.error?.message}
        {...field}
        value={field.value ?? ''}
        {...props}
      />
    )}
  />
)
