import { ElementType } from 'react'

import type { BoxProps } from './types'

const defaultElement = 'div'

export const Box = <C extends ElementType = typeof defaultElement>({
  as,
  children,
  ...props
}: BoxProps<C>): JSX.Element => {
  const Component: React.ElementType = as || defaultElement
  return <Component {...props}>{children}</Component>
}
