import { ElementType } from 'react'
import classNames from 'classnames'

import { Box } from '../../components'

import { TileContentProps } from './types'

const defaultElement = 'div'

export const Content = <C extends ElementType = typeof defaultElement>({
  children,
  as,
  className,
  contentPlacement,
  ...props
}: TileContentProps<C>) => {
  const element: React.ElementType = as || defaultElement

  return (
    <Box
      as={element}
      className={classNames('tile__content', className)}
      {...props}
    >
      {children}
    </Box>
  )
}
