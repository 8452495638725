import React from 'react'

import { SvgProps } from '../types'
import { Icon } from '../Icon'

export const ChevronLeft: React.FC<SvgProps> = (props) => {
  return (
    <Icon fillRule="evenodd" clipRule="evenodd" viewBox="0 0 10 14" {...props}>
      <path
        d="M8.68338 13.387C8.8971 13.5618 9.21211 13.5303 9.38698 13.3166C9.56184 13.1029 9.53034 12.7879 9.31662 12.613L8.68338 13.387ZM1 6.45454L0.718332 6.04143L0.165873 6.41811L0.683382 6.84152L1 6.45454ZM9.31662 12.613L1.31662 6.06757L0.683382 6.84152L8.68338 13.387L9.31662 12.613ZM1.28167 6.86766L9.28167 1.41311L8.71833 0.586886L0.718332 6.04143L1.28167 6.86766Z"
        fill="currentColor"
      />
    </Icon>
  )
}
