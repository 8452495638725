import * as z from 'zod'
import { makeZodI18nMap } from 'zod-i18n-map'
import type { Translations } from '@lounge-fe/i18n'

const ns: keyof Translations = 'validation'

z.setErrorMap(
  makeZodI18nMap({
    ns,
  })
)

export default z
