import { createCompoundComponent } from '../../utilities'

import { Accordion as BaseAccordion } from './Accordion'
import { Item } from './Item'
import { AccordionComposition } from './types'

export const Accordion: AccordionComposition = createCompoundComponent(
  BaseAccordion,
  {
    Item,
  }
)

export type { AccordionProps, AccordionItemProps } from './types'
