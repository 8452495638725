import { AnimatePresence, motion, PanInfo } from 'framer-motion'
import {
  useCallback,
  createContext,
  useContext,
  useMemo,
  useState,
  createElement,
} from 'react'
import classNames from 'classnames'

import { useTimer } from '../../hooks/useTimer'
import { Icon, SvgProps } from '../../components/Icon'
import { Text } from '../../components/Text'

import {
  ToastContextState,
  ToastItem,
  ToastProviderProps,
  ToastSeverity,
} from './types'
import { Toast } from './Toast'

const ToastContext = createContext<ToastContextState>({} as ToastContextState)

export const ToastProvider = ({
  children,
  delay = 4000,
  dismissDragOffset = 50,
  className,
  ...props
}: ToastProviderProps): JSX.Element => {
  const [currentToast, setCurrentToast] = useState<
    (ToastItem & { id: number }) | null
  >(null)

  const timer = useTimer(() => setCurrentToast(null), delay)

  const toast = useCallback(
    (item: ToastItem) => {
      setCurrentToast({ ...item, id: Date.now() * Math.random() })
      timer.start()
    },
    [currentToast, timer.start]
  )

  const clear = useCallback(() => {
    setCurrentToast(null)
    timer.clear()
  }, [timer.clear])

  const onDragEnd = useCallback(
    (evt: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => {
      if (info.offset.x > dismissDragOffset) {
        clear()
        evt.stopPropagation()
      }
    },
    [clear]
  )

  const context = useMemo<ToastContextState>(
    () => ({ toast, clear }),
    [toast, clear]
  )

  return (
    <ToastContext.Provider value={context}>
      <AnimatePresence>
        {currentToast && (
          <motion.div
            className="fixed bottom-6 right-0 left-6 md:left-auto md:pl-0 pr-6 z-50"
            key={currentToast.id}
            initial={{ x: '100%' }}
            animate={{ x: '0%' }}
            exit={{ x: '100%' }}
            dragConstraints={{ left: 0, right: 0 }}
            dragElastic={{ left: 0, right: 0.9 }}
            transition={{
              duration: 0.2,
            }}
            onMouseEnter={timer.pause}
            onMouseLeave={timer.resume}
            onDragEnd={onDragEnd}
            drag="x"
          >
            <Toast
              className={className}
              title={currentToast.title}
              message={currentToast.message}
              severity={currentToast.severity}
            />
          </motion.div>
        )}
      </AnimatePresence>
      {children}
    </ToastContext.Provider>
  )
}

export const useToasts = () => useContext(ToastContext)
