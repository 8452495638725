import React from 'react'

import { SvgProps } from '../types'
import { Icon } from '../Icon'

export const CycleArrow: React.FC<SvgProps> = (props) => {
  return (
    <Icon width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M0.666667 8.65333C1.03467 8.65333 1.33333 8.35467 1.33333 7.98667C1.33333 4.318 4.30533 1.33333 7.95833 1.33333C9.56067 1.33333 11.066 1.90067 12.2527 2.91967H11.6417C11.2737 2.91967 10.975 3.21833 10.975 3.58633C10.975 3.95433 11.2737 4.253 11.6417 4.253H13.8297C14.1977 4.253 14.4963 3.95433 14.4963 3.58633V1.389C14.4963 1.021 14.1977 0.722333 13.8297 0.722333C13.4617 0.722333 13.163 1.021 13.163 1.389V1.94467C11.731 0.696 9.90467 0 7.95833 0C3.57 0 0 3.583 0 7.98667C0 8.35467 0.298667 8.65333 0.666667 8.65333Z"
        fill="black"
      />
      <path
        d="M15.3296 7.34375C14.9616 7.34375 14.663 7.64242 14.663 8.01042C14.663 11.6791 11.691 14.6637 8.03798 14.6637C6.45431 14.6637 4.96498 14.1104 3.78498 13.1137H4.42531C4.79331 13.1137 5.09198 12.8151 5.09198 12.4471C5.09198 12.0791 4.79331 11.7804 4.42531 11.7804H2.23698C2.23098 11.7804 2.22565 11.7821 2.21998 11.7821C2.19465 11.7827 2.16965 11.7861 2.14431 11.7897C2.12565 11.7924 2.10698 11.7941 2.08865 11.7984C2.06665 11.8034 2.04498 11.8114 2.02331 11.8187C2.00331 11.8257 1.98298 11.8317 1.96365 11.8404C1.94531 11.8487 1.92798 11.8594 1.91031 11.8694C1.88931 11.8814 1.86865 11.8931 1.84898 11.9071C1.84398 11.9107 1.83831 11.9127 1.83331 11.9167C1.82198 11.9254 1.81331 11.9361 1.80265 11.9454C1.78498 11.9607 1.76731 11.9761 1.75131 11.9934C1.73631 12.0094 1.72331 12.0261 1.71031 12.0431C1.69731 12.0601 1.68465 12.0771 1.67298 12.0951C1.66131 12.1137 1.65131 12.1331 1.64165 12.1524C1.63198 12.1714 1.62298 12.1907 1.61531 12.2107C1.60765 12.2314 1.60165 12.2521 1.59598 12.2734C1.59065 12.2937 1.58498 12.3141 1.58165 12.3351C1.57765 12.3587 1.57598 12.3824 1.57431 12.4064C1.57331 12.4201 1.57031 12.4334 1.57031 12.4474V14.6447C1.57031 15.0127 1.86898 15.3114 2.23698 15.3114C2.60498 15.3114 2.90365 15.0127 2.90365 14.6447V14.1147C4.32465 15.3244 6.12365 15.9971 8.03798 15.9971C12.4263 15.9971 15.9963 12.4141 15.9963 8.01042C15.9963 7.64242 15.6976 7.34375 15.3296 7.34375Z"
        fill="black"
      />
    </Icon>
  )
}
