import React from 'react'

import { SvgProps } from '../types'
import { Icon } from '../Icon'

export const ArrowRight: React.FC<SvgProps> = (props) => {
  return (
    <Icon
      fillRule="evenodd"
      clipRule="evenodd"
      width="24"
      height="24"
      viewBox="0 0 25 14"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.499999 7.52319C0.223857 7.52319 -2.68916e-07 7.29934 -2.80987e-07 7.02319C-2.93057e-07 6.74705 0.223857 6.52319 0.499999 6.52319L22.4628 6.52319L17.3304 1.62322C17.1307 1.43254 17.1234 1.11604 17.314 0.916307C17.5047 0.716576 17.8212 0.709245 18.021 0.899934L23.9806 6.58978C24.1244 6.727 24.1685 6.92938 24.1108 7.1065C24.1685 7.28363 24.1244 7.48599 23.9806 7.62321L18.021 13.3131C17.8212 13.5037 17.5047 13.4964 17.314 13.2967C17.1234 13.097 17.1307 12.7805 17.3304 12.5898L22.6373 7.52319L0.499999 7.52319Z"
        fill="currentColor"
      />
    </Icon>
  )
}
