import { SvgProps } from '../types'
import { Icon } from '../Icon'

export const Energy: React.FC<SvgProps> = (props) => {
  return (
    <Icon width="64" height="64" viewBox="0 0 64 64" fill="none" {...props}>
      <circle cx="32" cy="32" r="31.5" stroke="currentColor" />
      <path
        d="M32.7433 22.3504C32.5657 22.1048 32.2917 21.9609 32.0014 21.9609C31.711 21.9609 31.437 22.1048 31.2594 22.3504C31.0367 22.6587 25.8022 29.9645 25.8022 35.4113C25.8022 39.0651 28.5832 42.0377 32.0014 42.0377C35.4196 42.0377 38.2005 39.0651 38.2005 35.4113C38.2005 29.9645 32.966 22.6587 32.7433 22.3504ZM32.0015 40.0335C29.6171 40.0335 27.6773 37.96 27.6773 35.4114C27.6773 31.7793 30.5945 26.8439 32.0018 24.6745C33.4091 26.8422 36.3255 31.7736 36.3257 35.4114C36.3257 37.9601 34.3858 40.0335 32.0015 40.0335Z"
        fill="currentColor"
      />
      <path
        d="M46.5639 28.2946C44.9855 21.1803 38.9975 16 31.9999 16C26.0069 16 20.4558 19.8506 18.1197 26.0095L17.1838 25.7381C16.4882 25.5361 15.8478 26.2172 16.032 26.9615L16.9622 30.7191C17.146 31.4614 18.0167 31.7184 18.5293 31.1737L21.1084 28.4337C21.62 27.8905 21.388 26.9574 20.6931 26.7558L19.9436 26.5385C21.922 21.5701 26.5154 18.0042 31.9999 18.0042C38.322 18.0042 43.4032 22.7336 44.7396 28.7571C44.8591 29.2956 45.3641 29.6287 45.8681 29.5009C46.3719 29.3732 46.6834 28.8331 46.5639 28.2946Z"
        fill="currentColor"
      />
      <path
        d="M47.9675 37.0381L47.0373 33.2804C46.8528 32.5352 45.9808 32.2833 45.4703 32.826L42.8912 35.5659C42.3796 36.1092 42.6115 37.0423 43.3065 37.2438L44.056 37.4612C42.0775 42.4295 37.4841 45.9955 31.9997 45.9955C25.6776 45.9955 20.5964 41.266 19.2599 35.2425C19.1404 34.7041 18.6357 34.3711 18.1314 34.4988C17.6277 34.6265 17.3162 35.1665 17.4357 35.7051C19.0141 42.8194 25.002 47.9997 31.9997 47.9997C37.9927 47.9997 43.5437 44.1491 45.8798 37.9901L46.8158 38.2616C47.508 38.4625 48.1528 37.7866 47.9675 37.0381Z"
        fill="currentColor"
      />
    </Icon>
  )
}
