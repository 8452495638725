import 'isomorphic-fetch'

import qs from 'qs'

import { RestError } from './RestError'
import { RestClientInit, RestResponse } from './types'

export const CSRF_SESSION_STORAGE_KEY = 'csrf-token'

const CSRF_RETRY_COUNT = 3

const BASE_HEADERS: HeadersInit = {
  'Content-Type': 'application/json',
}

export class RestClient {
  private baseURL: string
  private headers: HeadersInit
  private useCSRF: boolean

  constructor({ baseURL = '', headers = {}, useCSRF = true }: RestClientInit) {
    this.baseURL = baseURL
    this.headers = headers
    this.useCSRF = useCSRF
  }

  public get = <TResponse = unknown, TQuery = unknown, TError = TResponse>(
    url: string,
    query?: TQuery | null,
    requestInit?: RequestInit
  ): Promise<RestResponse<TResponse>> => {
    const params = query ? `?${qs.stringify(query)}` : ''
    return this.request<TResponse, TError>(`${url}${params}`, {
      method: 'GET',
      ...requestInit,
    })
  }

  public post = async <
    TResponse = unknown,
    TBody = unknown,
    TError = TResponse
  >(
    url: string,
    body?: TBody | null,
    requestInit?: RequestInit
  ): Promise<RestResponse<TResponse>> =>
    this.request<TResponse, TError>(url, {
      method: 'POST',
      body: body ? JSON.stringify(body) : null,
      ...requestInit,
    })

  public put = async <TResponse = unknown, TBody = unknown, TError = TResponse>(
    url: string,
    body?: TBody | null,
    requestInit?: RequestInit
  ): Promise<RestResponse<TResponse>> =>
    this.request<TResponse, TError>(url, {
      method: 'PUT',
      body: body ? JSON.stringify(body) : null,
      ...requestInit,
    })

  public patch = async <
    TResponse = unknown,
    TBody = unknown,
    TError = TResponse
  >(
    url: string,
    body?: TBody | null,
    requestInit?: RequestInit
  ): Promise<RestResponse<TResponse>> =>
    this.request<TResponse, TError>(url, {
      method: 'PATCH',
      body: body ? JSON.stringify(body) : null,
      ...requestInit,
    })

  public delete = async <
    TResponse = unknown,
    TQuery = unknown,
    TError = TResponse
  >(
    url: string,
    query?: TQuery | null,
    requestInit?: RequestInit
  ): Promise<RestResponse<TResponse>> => {
    const params = query ? `?${qs.stringify(query)}` : ''
    return this.request<TResponse, TError>(`${url}${params}`, {
      method: 'DELETE',
      ...requestInit,
    })
  }

  private request = async <TResponse, TError>(
    url: string,
    init: RequestInit,
    retryCount = 0
  ): Promise<RestResponse<TResponse>> => {
    const endpointUrl = this.getEndpointUrl(url)
    const headers = { ...BASE_HEADERS, ...this.headers, ...init.headers }
    const res = await fetch(endpointUrl, {
      credentials: 'include',
      ...init,
      headers: this.useCSRF ? await this.withCSRFToken(headers) : headers,
    })

    const data = await this.parseResponseData(res)
    if (res.ok) {
      return {
        status: res.status,
        data,
      }
    } else {
      if (
        data?.code?.toUpperCase() === 'INVALID_CSRF_TOKEN' &&
        retryCount <= CSRF_RETRY_COUNT
      ) {
        window.sessionStorage.removeItem(CSRF_SESSION_STORAGE_KEY)
        return this.request(url, init, retryCount + 1)
      }

      throw new RestError<TError>({
        status: res.status,
        message: res.statusText,
        data,
      })
    }
  }

  private parseResponseData = async (res: Response) => {
    const contentType = res.headers.get('Content-Type')
    if (
      contentType?.includes('application/json') ||
      contentType?.includes('text/javascript')
    ) {
      return res.json()
    }

    const text = await res.text()
    return text.length ? text : null
  }

  private getEndpointUrl = (url: string) => `${this.baseURL ?? ''}${url}`

  private withCSRFToken = async (
    headers: HeadersInit
  ): Promise<HeadersInit> => {
    // const storedToken = window.sessionStorage.getItem(CSRF_SESSION_STORAGE_KEY)
    // if (storedToken) {
    //   return { ...headers, 'CSRF-Token': storedToken }
    // }

    const res = await fetch(this.getEndpointUrl('/auth/csrf'), {
      credentials: 'include',
      headers: {
        ...this.headers,
      },
    })

    const { csrfToken } = await res.json()

    window.sessionStorage.setItem(CSRF_SESSION_STORAGE_KEY, csrfToken)
    return { ...headers, 'CSRF-Token': csrfToken }
  }
}
