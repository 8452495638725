import { useTranslation } from 'react-i18next'
import { FormProvider, useForm } from 'react-hook-form'
import { useEffect } from 'react'

import { Icon } from '../../../components/Icon'
import { Form } from '../../../components/Form'
import { Text } from '../../../components/Text'
import { getVariantFromOptions } from '../../../utilities'

import { QuickAddFormProps, ChoiceChipFieldItem } from './types'
import { ChoiceChipsField } from './ChoiceChipsField'
import { useQuickAddSubmitStatus } from './useQuickAddSubmitStatus'

const flattenSelectedOptions = (
  selectedOptions: QuickAddFormProps['variants'][number]['selectedOptions']
) => selectedOptions.map(({ value }) => value)

const flattenVariantOptions = (variants: QuickAddFormProps['variants']) =>
  variants.map((variant) => ({
    ...variant,
    options: flattenSelectedOptions(variant.selectedOptions),
  }))

const WrappedQuickAddForm = ({
  options,
  variants,
  linkedBottoms,
  onSubmit: parentOnSubmit,
}: Omit<QuickAddFormProps, 'isLoading'>) => {
  const [status, setStatus] = useQuickAddSubmitStatus()

  const { t } = useTranslation()

  const getDefaultValues = () => {
    const defaultLinkedBottoms =
      linkedBottoms.find(({ availableForSale }) => availableForSale) ??
      linkedBottoms[0]

    return {
      linkedBottoms: defaultLinkedBottoms
        ? {
            id: defaultLinkedBottoms.id,
            options: flattenSelectedOptions(
              (
                defaultLinkedBottoms.variants.find(
                  ({ availableForSale }) => availableForSale
                ) ?? defaultLinkedBottoms.variants[0]
              )?.selectedOptions ?? []
            ),
          }
        : undefined,
      options: flattenSelectedOptions(
        (
          variants.find(({ availableForSale }) => availableForSale) ??
          variants[0]
        )?.selectedOptions ?? []
      ),
    }
  }

  const methods = useForm({
    defaultValues: getDefaultValues(),
  })

  useEffect(() => {
    methods.reset(getDefaultValues())
  }, [variants, linkedBottoms])

  const { isSubmitting } = methods.formState

  const [
    selectedOptions,
    selectedLinkedBottomsId,
    selectedLinkedBottomsOptions,
  ] = methods.watch(['options', 'linkedBottoms.id', 'linkedBottoms.options'])

  const selectedLinkedBottoms = linkedBottoms.find(
    ({ id }) => id === selectedLinkedBottomsId
  )

  const flattenedVariants = flattenVariantOptions(variants)
  const selectedVariant = getVariantFromOptions(
    selectedOptions ?? [],
    flattenedVariants
  )

  const selectedLinkedBottomsVariant = selectedLinkedBottoms
    ? getVariantFromOptions(
        selectedLinkedBottomsOptions ?? [],
        flattenVariantOptions(selectedLinkedBottoms.variants)
      )
    : undefined

  let selectedInStock = selectedVariant?.availableForSale

  if (selectedLinkedBottomsVariant) {
    selectedInStock =
      selectedInStock && selectedLinkedBottomsVariant.availableForSale
  }

  const onSubmit = async () => {
    setStatus('idle')
    if (selectedVariant) {
      try {
        const { success } = await parentOnSubmit(
          selectedVariant?.id,
          selectedLinkedBottomsVariant?.id
        )

        if (!success) {
          setStatus('idle')
          return
        }

        setStatus('success')
      } catch (error) {
        setStatus('idle')
      }
    }
  }

  return (
    <>
      {options.length === 1 && !linkedBottoms.length ? (
        <ChoiceChipsField
          onValueSelect={parentOnSubmit}
          values={options[0].values.reduce(
            (accum: ChoiceChipFieldItem[], value) => {
              const variant = getVariantFromOptions([value], flattenedVariants)
              return variant
                ? [
                    ...accum,
                    {
                      value,
                      variantId: variant.id,
                      disabled: !variant.availableForSale,
                    },
                  ]
                : accum
            },
            []
          )}
        />
      ) : (
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="flex gap-2">
              {options.map((option, index) => (
                <Form.Select
                  className="flex-1"
                  key={option.name}
                  name={`options.${index}`}
                  label={option.name}
                  filled
                  options={option.values.map((option) => {
                    const newOptions = [...selectedOptions]
                    newOptions[index] = option
                    const variant = getVariantFromOptions(
                      newOptions,
                      flattenedVariants
                    )

                    return {
                      value: option,
                      label:
                        options.length === 1
                          ? variant?.availableForSale
                            ? option
                            : `${option} (${t('product.out_of_stock')})`
                          : option,
                    }
                  })}
                />
              ))}
            </div>
            {linkedBottoms.length > 0 && (
              <div className="mt-2 w-full">
                <Text variant="body-sm" className="font-medium mb-2">
                  {t('product.choose_your_bottoms')}
                </Text>
                {linkedBottoms.length > 1 && (
                  <Form.Select
                    name="linkedBottoms.id"
                    options={linkedBottoms.map((product) => ({
                      label: product.title,
                      value: product.id.toString(),
                    }))}
                  />
                )}
                {selectedLinkedBottoms && (
                  <div className="flex gap-2">
                    {selectedLinkedBottoms.options.map((option, index) => {
                      return (
                        <Form.Select
                          className="flex-1"
                          key={option.name}
                          name={`linkedBottoms.options.${index}`}
                          aria-label={option.name}
                          filled
                          options={option.values.map((optionValue) => {
                            const newOptions = [...selectedLinkedBottomsOptions]
                            newOptions[index] = optionValue

                            const variant = getVariantFromOptions(
                              newOptions,
                              flattenVariantOptions(
                                selectedLinkedBottoms.variants
                              )
                            )

                            return {
                              value: optionValue,
                              label: variant?.availableForSale
                                ? optionValue
                                : `${optionValue} (${t(
                                    'product.out_of_stock'
                                  )})`,
                            }
                          })}
                        />
                      )
                    })}
                  </div>
                )}
              </div>
            )}
            <Form.Submit
              size="lg"
              className="w-full mt-4"
              disabled={!selectedInStock}
              isLoading={isSubmitting}
              onClick={methods.handleSubmit(onSubmit)}
            >
              {status === 'success' ? (
                <Icon.CheckCircle
                  width={18}
                  height={18}
                  className="max-w-full"
                />
              ) : (
                <>
                  {selectedInStock
                    ? t('product.add_to_bag')
                    : t('product.out_of_stock')}
                </>
              )}
            </Form.Submit>
          </form>
        </FormProvider>
      )}
    </>
  )
}

export const QuickAddForm = ({ isLoading, ...props }: QuickAddFormProps) => {
  if (isLoading || !props.variants.length) {
    return (
      <div className="w-full flex justify-center items-center py-10">
        <Icon.Loading className="animate-spin w-6 h-6" />
      </div>
    )
  }

  return <WrappedQuickAddForm {...props} />
}
