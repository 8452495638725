import classNames from 'classnames'
import { HTMLAttributes } from 'react'

export const FormContainer = ({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      className={classNames(
        'h-full w-full flex flex-col items-center justify-center  max-w-[600px] justify-self-center px-6 py-12',
        className
      )}
      {...props}
    >
      {children}
    </div>
  )
}
