import { VideoProps } from './types'

export const Video = ({ video }: VideoProps) => {
  return (
    <div className="flex justify-center h-full items-center bg-black">
      <video
        className="w-full aspect-video object-cover"
        autoPlay
        controls
        playsInline
        title={video.alt}
        poster={video.preview_image?.src}
      >
        {video.sources
          .sort((a, b) => b.height - a.height)
          .map(({ url, mime_type, height }, i) => (
            <source key={i} src={url} type={mime_type} height={height} />
          ))}
      </video>
    </div>
  )
}
