import classNames from 'classnames'
import { FC, HTMLAttributes } from 'react'

export const Actions: FC<HTMLAttributes<HTMLDivElement>> = ({
  className,
  ...props
}) => (
  <div
    role="group"
    className={classNames(
      'grid grid-flow-col gap-4 auto-cols-auto px-6 pb-6',
      className
    )}
    {...props}
  />
)
