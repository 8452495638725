import { ElementType, useEffect, useId } from 'react'

import { Box } from '../Box'

import { useSelectContext } from './Select'
import { SelectHelperTextProps } from './types'

const defaultElement = 'p'

export const HelperText = <E extends ElementType = typeof defaultElement>({
  children,
}: SelectHelperTextProps<E>) => {
  const id = useId()

  const { setHelperTextId } = useSelectContext()

  useEffect(() => {
    setHelperTextId(id)
    return () => {
      setHelperTextId(undefined)
    }
  }, [])

  return (
    <Box as={defaultElement} id={id} className="select__helper-text">
      {children}
    </Box>
  )
}
