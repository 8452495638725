import { useCart } from '@/features/cart/hooks'
import { useShopify } from '@/hooks/useShopify'
import { replaceSchemaVariables } from '@/utils'
import { Icon, Link, Progress, Text } from '@lounge-fe/ui-kit'
import { useMoney } from '@shopify/hydrogen-react'
import classNames from 'classnames'
import { useMemo } from 'react'
import { DiscountProgressProps } from './types'

export const DiscountProgress = ({
  minimumSpend,
  eligibleText,
  eligibleUrl,
  eligibleUrlText,
  eligibleCollections = [],
  ineligibleText,
  className,
  ...props
}: DiscountProgressProps) => {
  const { data: { items = [] } = {} } = useCart()
  const { currency } = useShopify()

  const eligibleItemsTotal = useMemo(
    () =>
      items.reduce((prev, item) => {
        const isEligible =
          eligibleCollections.some((collection) =>
            item.collections.includes(collection)
          ) || !eligibleCollections.length

        if (isEligible) {
          return prev + item.finalLinePrice
        }

        return prev
      }, 0) / 100,
    [items]
  )
  const minimumSpendMoney = useMoney({
    amount: minimumSpend.toString(),
    currencyCode: currency.active,
  })

  const remainingSpendMoney = useMoney({
    amount: (minimumSpend - eligibleItemsTotal).toString(),
    currencyCode: currency.active,
  })

  const isEligibleForDiscount = eligibleItemsTotal >= minimumSpend

  return (
    <div
      className={classNames(
        'cart-discount-progress space-y-3 [&_strong]:text-accent bg-surface-secondary p-6',
        className
      )}
      {...props}
    >
      <div className="flex items-center gap-x-2">
        <div className="cart-discount-progress__icon rounded-circle bg-accent w-8 h-8 flex justify-center items-center shrink-0">
          <Icon.Gift className="text-white w-4 h-4" />
        </div>
        {isEligibleForDiscount ? (
          <>
            <Text
              variant="body-md"
              className="flex-1"
              dangerouslySetInnerHTML={{
                __html: replaceSchemaVariables(
                  { minimum_spend: minimumSpendMoney.localizedString },
                  eligibleText
                ),
              }}
            />
            <Link
              variant="body-md"
              href={eligibleUrl}
              className="whitespace-nowrap align-self-end capitalize underline"
            >
              {eligibleUrlText}
            </Link>
          </>
        ) : (
          <Text
            variant="body-sm"
            className="flex-1"
            dangerouslySetInnerHTML={{
              __html: replaceSchemaVariables(
                {
                  minimum_spend: minimumSpendMoney.localizedString,
                  remaining_spend: remainingSpendMoney.localizedString,
                },
                ineligibleText
              ),
            }}
          />
        )}
      </div>
      {!isEligibleForDiscount && (
        <Progress>
          <Progress.Bar
            className="bg-accent"
            min={0}
            max={minimumSpend}
            value={eligibleItemsTotal}
          />
        </Progress>
      )}
    </div>
  )
}
