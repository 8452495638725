import { ShopifySectionComponent } from '@/types/ShopifySectionComponent'
import { useMediaQuery } from '@lounge-fe/ui-kit'
import { Layout } from './Layout'

import { PromotionBannerProps } from './types'
import { SignupModal } from '@/components/SignupModal'
import classNames from 'classnames'

export const PromoBanner: ShopifySectionComponent<PromotionBannerProps> = ({
  data,
}) => {
  const {
    image,
    heading,
    body,
    spacer,
    modalBody,
    modalTitle,
    modalSuccessTitle,
    modalSuccessPromo,
    modalSuccessBody,
  } = data!

  return (
    <div className={classNames(spacer, image && 'wrapper')}>
      <SignupModal
        data={{
          title: modalTitle,
          subtitle: modalBody,
          modal_title: modalTitle,
          modal_success_title: modalSuccessTitle,
          modal_success_body: modalSuccessBody,
          modal_success_promo: modalSuccessPromo,
          image: image && { src: image.src },
        }}
      >
        {({ onOpen }) => (
          <Layout
            image={image}
            heading={heading}
            body={body}
            onOpen={onOpen}
          ></Layout>
        )}
      </SignupModal>
    </div>
  )
}

export default PromoBanner
