import { useEffect, useRef, useState } from 'react'
import { useAnimate, AnimationPlaybackControls } from 'framer-motion'
import { useMediaQuery } from '@lounge-fe/ui-kit'
import { Video } from '@shopify/hydrogen-react/storefront-api-types'
import { VideoData } from '../types'

export const useVideoScaleAnimation = ({
  video,
  video_mobile,
  video_animation,
}: VideoData) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const isDesktop = useMediaQuery(({ screens }) => `(min-width: ${screens.sm})`)
  const [src, setSrc] = useState<Video>()

  useEffect(() => {
    setSrc(isDesktop ? video : video_mobile)
    videoRef.current?.load()
  }, [src, isDesktop])

  const scaleFactor = video_animation
    ? useCalculateScaleFactor(
        videoRef.current?.clientHeight ?? window.innerHeight,
        videoRef.current?.clientWidth ?? window.innerWidth
      )
    : 1

  return { videoRef, src, scaleFactor }
}

export const useCalculateScaleFactor = (height: number, width: number) => {
  const elementHeight = height
  const elementWidth = width

  let scaledY = window.innerHeight / elementHeight
  let scaledX = window.innerWidth / elementWidth
  let scaleFactor = Math.min(Math.max(scaledY, scaledX), 1.75)
  return { scaleFactor }
}
