import { ChoiceChips, Icon, Text, useTimer } from '@lounge-fe/ui-kit'
import { CartChoiceChipQuickAddData } from '../../Header/types'
import { useAddCartItem } from '@/features/cart/hooks'
import { useState } from 'react'
import { useDataTranslation } from '@/features/localisation/hooks/useDataTranslation/useDataTranslation'
import { Skeleton } from '@lounge-fe/ui-kit/src/components/Skeleton'

// TODO: A lot of this logic is duplicated from the ProductCard.QuickAdd component / ProductQuickAdd blocks.
// When time permits, we should refactor this to be a little more reusable.

export const ChoiceChipQuickAdd = ({
  title,
  body,
  product,
}: CartChoiceChipQuickAddData) => {
  // Keep track of our own recently added state to prevent cart closing.
  const addItem = useAddCartItem({ useRecentlyAdded: false })
  const [hasRecentlyAdded, setHasRecentlyAdded] = useState(false)
  const addedTimer = useTimer(() => setHasRecentlyAdded(false), 3000)
  const { isLoading: isTranslationLoading, t } = useDataTranslation()

  const onSelect = (variantId: string | undefined) => {
    if (variantId) {
      addItem.mutate(
        { id: Number(variantId), quantity: 1 },
        {
          onSuccess: () => {
            setHasRecentlyAdded(true)
            addedTimer.start()
          },
        }
      )
    }
  }

  const optionLabel =
    product?.options.length === 1 && !isTranslationLoading
      ? t(product?.options[0].name)
      : null

  return (
    <div className="bg-background-primary-default rounded-md p-6 space-y-3">
      {title && (
        <Text variant="title-five" className="text-accent">
          {title}
        </Text>
      )}
      {body && <Text dangerouslySetInnerHTML={{ __html: body }} />}

      {product?.options.length === 1 && (
        <>
          {optionLabel && (
            <Text variant="body-lg" className="mb-3 font-medium leading-[1em]">
              {optionLabel}:
            </Text>
          )}
          <ChoiceChips
            className="grid grid-cols-[repeat(auto-fit,minmax(min(100%/2,max(64px,100%/4)),1fr))]"
            value=""
            onChange={onSelect}
          >
            {product.variants.map((variant) => {
              const isSelectedVariant = addItem.variables?.id === variant.id
              const isLoading = addItem.isPending && isSelectedVariant
              const isRecentlyAdded = hasRecentlyAdded && isSelectedVariant
              return (
                <ChoiceChips.Item
                  key={variant.id}
                  value={variant.id.toString()}
                  disabled={
                    Boolean(variant.inventory_management) &&
                    variant.inventory_policy === 'deny' &&
                    variant.inventory_quantity <= 0
                  }
                  className="flex items-center justify-center h-12"
                >
                  {isLoading ? (
                    <Icon.Loading
                      className="animate-spin"
                      width={18}
                      height={18}
                    />
                  ) : isRecentlyAdded ? (
                    <Icon.CheckCircle width={18} height={18} />
                  ) : (
                    variant.title
                  )}
                </ChoiceChips.Item>
              )
            })}
          </ChoiceChips>
        </>
      )}
    </div>
  )
}
