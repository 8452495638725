import React from 'react'

import { SvgProps } from '../types'
import { Icon } from '../Icon'

export const Check: React.FC<SvgProps> = (props) => {
  return (
    <Icon viewBox="0 0 8 8" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.76036 1.24482C7.44086 0.918395 6.92284 0.918395 6.60334 1.24482L2.55373 5.3821L1.39665 4.19996C1.07715 3.87354 0.559131 3.87354 0.239627 4.19996C-0.0798759 4.52639 -0.0798758 5.05562 0.239628 5.38204L1.97516 7.15515C2.05193 7.23358 2.14016 7.29316 2.23434 7.3339C2.53215 7.46277 2.88951 7.4032 3.13227 7.15518L7.76036 2.42689C8.07987 2.10047 8.07987 1.57124 7.76036 1.24482Z"
        stroke="none"
        fill="currentColor"
      />
    </Icon>
  )
}
