import { useMemo, useRef } from 'react'
import { motion } from 'framer-motion'
import classNames from 'classnames'

import { Popover, Portal, useMediaQuery } from '@lounge-fe/ui-kit'

import { NavigationProps } from '../types'
import { useScrollAnimation } from '../../../hooks/useScrollAnimation'
import { useNavigationContext } from '../Navigation'

import { MenuPopover } from './MenuPopover'
import { Header } from './Header'
import { AccountMenu } from './AccountMenu'
import { useShopify } from '@/hooks/useShopify'

export const Navigation = ({
  className,
  children,
  mobileCarouselTitle = 'INSIDE LOUNGE',
}: NavigationProps) => {
  const scrollAnimationProps = useScrollAnimation()
  const isTablet = useMediaQuery((theme) => `(min-width: ${theme.screens.sm})`)

  const { exposeSearch } = useShopify()

  const { hasScrollAnimation, searchComponent, basketComponent } =
    useNavigationContext()

  const headerRef = useRef<HTMLDivElement | null>(null)

  const allTiles = useMemo(
    () =>
      Array.isArray(children)
        ? children.map((child) => child.props.tiles).flat()
        : children.props.tiles,
    [children]
  )

  return (
    <motion.header
      className={classNames(
        'mobile-navigation',
        'fixed',
        'top-0',
        'left-0',
        'right-0',
        'z-20',
        className
      )}
      ref={headerRef}
      {...{ ...(hasScrollAnimation.mobile ? scrollAnimationProps : {}) }}
    >
      <Popover.Group>
        <Popover>
          {({ isOpen }) => (
            <>
              <Portal>
                <Popover.Backdrop className="z-30" />
              </Portal>
              <Header
                searchComponent={searchComponent}
                showSearchForm={exposeSearch}
                actionsComponent={
                  isOpen && !isTablet ? <AccountMenu /> : basketComponent
                }
                aria-hidden={isOpen}
              />
              <MenuPopover
                carouselTitle={mobileCarouselTitle}
                tiles={allTiles}
                isTablet={isTablet}
                headerComponent={
                  <>
                    {/* Popover always shows search so use header height with search */}
                    <Header
                      searchComponent={searchComponent}
                      actionsComponent={<AccountMenu />}
                      className="h-[var(--mobile-header-height-with-search)]"
                      showSearchForm
                    />
                  </>
                }
              >
                {children}
              </MenuPopover>
            </>
          )}
        </Popover>
      </Popover.Group>
    </motion.header>
  )
}
