import { LoginModal } from '@/features/auth/components/LoginModal'
import { useShopify } from '@/hooks/useShopify'
import { useAuth } from '@lounge-fe/auth'
import { IconButton, Icon } from '@lounge-fe/ui-kit'
import { IconButtonProps } from '@lounge-fe/ui-kit/src/components/IconButton/types'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

const WishlistIcon = memo(
  ({ href }: Partial<Pick<IconButtonProps, 'href'>>) => {
    const { t } = useTranslation()
    return (
      <IconButton
        icon={<Icon.Heart fill="none" width={20} height={20} />}
        size="lg"
        aria-label={t('account.wishlist.title')}
        href={href}
      />
    )
  }
)

export const WishlistButton = () => {
  const { t } = useTranslation()
  const { status } = useAuth()
  const { routes } = useShopify()

  const wishlistRoute = `${routes.account_url}#/wishlist`

  return status === 'authenticated' ? (
    <WishlistIcon href={wishlistRoute} />
  ) : (
    <LoginModal
      title={t('account.wishlist.title')}
      description={t('account.wishlist.unauthenticated_modal_description')}
      onSuccess={() => {
        location.href = wishlistRoute
      }}
      trigger={<WishlistIcon />}
    />
  )
}
