import { useOneTrust } from '@/hooks/useOneTrust'
import { useSearchInsights } from '@/hooks/useSearchInsights'
import { PropsWithChildren, useEffect } from 'react'

export const SearchInsights = ({ children }: PropsWithChildren) => {
  const { init } = useSearchInsights()
  const { hasConsent } = useOneTrust()

  useEffect(() => {
    init({ userHasOptedOut: !hasConsent('performance') })

    const initSearchInsights = (evt: CoookieConsentEvent) =>
      init({ userHasOptedOut: !evt.detail?.consent?.performance ?? true })

    document.addEventListener('cookie_consent', initSearchInsights)

    return () => {
      document.removeEventListener('cookie_consent', initSearchInsights)
    }
  }, [])

  return <>{children}</>
}
