import useEmblaCarousel from 'embla-carousel-react'
import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures'
import React from 'react'
import classNames from 'classnames'

import { ProductCard } from '../ProductCard'
import { Carousel, Heading, Wrapper } from '../../components'

import { ProductCarouselProps } from './types'

export const ProductCarousel = ({
  heading,
  loop,
  wrapper,
  controls,
  children,
}: ProductCarouselProps) => {
  const [emblaRef, embla] = useEmblaCarousel(
    {
      align: loop && !wrapper ? 'center' : 'start',
      containScroll: 'trimSnaps',
      skipSnaps: true,
      loop,
    },
    [WheelGesturesPlugin()]
  )
  return (
    <div className="flex flex-col gap-y-4 sm:gap-y-6 2xl:gap-y-6">
      <Carousel emblaRef={emblaRef} embla={embla}>
        <div
          className={classNames(
            'flex items-center',
            heading ? 'justify-between' : 'justify-end',
            !wrapper ? 'wrapper' : ''
          )}
        >
          {heading && <Heading variant="heading-five">{heading}</Heading>}
          {controls && (
            <Carousel.Arrows className="hidden sm:flex sm:[&_button]:!w-10 sm:[&_button]:!h-10 lg:[&_button]:!w-8 lg:[&_button]:!h-8 2xl:[&_button]:!w-10 2xl:[&_button]:!h-10" />
          )}
        </div>
        <Carousel.Viewport showOverflow className="md:overflow-hidden">
          <Carousel.Container className="auto-cols-[calc(75%-12px)] sm:auto-cols-[calc(50%-12px)] md:auto-cols-[calc(33.33%-12px)] xl:auto-cols-[calc(25%-12px)] gap-x-4">
            {React.Children.map(children, (child, idx) => (
              <Carousel.Slide
                key={idx}
                index={idx}
                className={loop ? 'last:mr-3' : ''}
              >
                {React.cloneElement(child)}
              </Carousel.Slide>
            ))}
          </Carousel.Container>
        </Carousel.Viewport>
      </Carousel>
    </div>
  )
}
