import classNames from 'classnames'

import { ToggleGroup, ToggleGroupProps } from '../ToggleGroup'

export const Swatches = ({
  className,
  ...props
}: ToggleGroupProps): JSX.Element => (
  <ToggleGroup className={classNames('swatches', className)} {...props} />
)
