import { SummaryRowProps } from './types'
import { Money } from '@shopify/hydrogen-react'
import { Text } from '@lounge-fe/ui-kit'
import { HtmlHTMLAttributes, PropsWithChildren } from 'react'
import classNames from 'classnames'

const Row: React.FC<PropsWithChildren<HtmlHTMLAttributes<HTMLDivElement>>> = ({
  children,
  className,
  ...props
}) => (
  <div
    className={classNames('flex items-center justify-between py-1', className)}
    {...props}
  >
    {children}
  </div>
)

export { Row }
