import { useCountdown } from '@/hooks/useCountdown'
import { ShopifySectionComponent } from '@/types/ShopifySectionComponent'
import { Text } from '@lounge-fe/ui-kit'
import classNames from 'classnames'
import { CountdownProps, TimeUnits } from './types'
import { useTranslation } from 'react-i18next'

export const Countdown: ShopifySectionComponent<CountdownProps> = ({
  data: { endTime, postCountdownText, className } = {},
}): JSX.Element | null => {
  const { hasEnded, remainingTime } = useCountdown(endTime)
  const { t } = useTranslation()

  return hasEnded && !postCountdownText ? null : (
    <div className={classNames(className, 'text-center')}>
      {hasEnded ? (
        <Text variant="heading-four">{postCountdownText}</Text>
      ) : (
        <div className="grid grid-cols-4">
          {Object.entries(remainingTime).map(([unit, value]) => (
            <div key={unit}>
              <Text
                variant="heading-five"
                className="mb-1 md:typography--heading-four"
              >
                {value}
              </Text>
              <Text
                variant="body-md"
                className="mb-1 md:typography--body-lg capitalize"
              >
                {t(`date.${unit as TimeUnits}_short`, unit)}
              </Text>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
