import { createCompoundComponent } from '../../utilities'
import { Trigger } from '../Popover/Trigger'
import { Group } from '../Popover/Group'

import { Modal as BaseModal } from './Modal'
import { Content } from './Content'
import { Header } from './Header'
import { Body } from './Body'
import { Actions } from './Actions'
import { CloseButton } from './CloseButton'

export const Modal = createCompoundComponent(BaseModal, {
  Trigger,
  Group,
  Content,
  Header,
  Body,
  Actions,
  CloseButton,
})
