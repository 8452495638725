import React from 'react'

import { SvgProps } from '../types'
import { Icon } from '../Icon'

export const Menu: React.FC<SvgProps> = (props) => {
  return (
    <Icon width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3086 4H1.50858C1.19298 4 0.937134 4.25585 0.937134 4.57145C0.937134 4.88705 1.19298 5.1429 1.50858 5.1429H10.7484C11.222 4.6996 11.7454 4.31506 12.3086 4ZM8.13793 10.1715H1.50858C1.19298 10.1715 0.937134 10.4274 0.937134 10.743C0.937134 11.0586 1.19298 11.3144 1.50858 11.3144H8.04068C8.04914 10.9265 8.08208 10.545 8.13793 10.1715ZM9.45544 16.3431H1.50858C1.19298 16.3431 0.937134 16.5989 0.937134 16.9145C0.937134 17.2301 1.19298 17.486 1.50858 17.486H10.3487C10.0208 17.134 9.72171 16.7516 9.45544 16.3431Z"
        fill="black"
      />
      <path
        d="M20.3726 16.1427L23.3956 19.357"
        stroke="black"
        strokeWidth="1.1429"
        strokeLinecap="round"
      />
      <path
        d="M21.615 11.3212C21.615 14.7671 18.996 17.4999 15.838 17.4999C12.6799 17.4999 10.061 14.7671 10.061 11.3212C10.061 7.87525 12.6799 5.14249 15.838 5.14249C18.996 5.14249 21.615 7.87525 21.615 11.3212Z"
        stroke="black"
        strokeWidth="1.1429"
        fill="none"
      />
    </Icon>
  )
}
