import classNames from 'classnames'
import { Children, cloneElement } from 'react'
import { AnimatePresence, motion } from 'framer-motion'

import { Wrapper, Text, Box, Flex, Grid } from '@lounge-fe/ui-kit'

import { NavigationTile } from './NavigationTile'
import { ItemProps } from './types'

const Underline = () => (
  <motion.div
    layoutId="underline"
    className="h-1 bg-black absolute bottom-[-1px] left-0 right-0 w-full z-50 pointer-events-none"
  />
)

export const Item = ({
  title,
  isOpen = false,
  onOpen,
  onClose,
  tiles,
  children,
}: ItemProps) => (
  <motion.li className="list-none" animate role="menuitem">
    <Box
      as="button"
      className="h-full m-0 bg-transparent border-0"
      onMouseEnter={onOpen}
      onClick={isOpen ? onClose : onOpen}
      aria-expanded={isOpen}
    >
      <Flex className="relative items-center justify-center h-full mx-5">
        <Text variant="body-sm" className="py-4">
          {title}
        </Text>
        {isOpen && <Underline />}
      </Flex>
    </Box>
    <Box
      className={classNames(
        'absolute left-0 right-0 top-full',
        !isOpen && 'hidden'
      )}
      aria-hidden={!isOpen}
      role="menu"
    >
      {!isOpen ? (
        Children.map(children, (child) => cloneElement(child, { tabIndex: -1 }))
      ) : (
        <Box className="relative">
          <motion.div
            className="absolute inset-0 bg-white -z-10"
            transition={{
              layout: { ease: 'easeOut', duration: 0.3 },
            }}
            layoutId="megamenu"
          />
          <Wrapper size="sm" className="z-10 py-12">
            <AnimatePresence>
              <motion.div
                className="flex items-start justify-between gap-20"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{
                  opacity: {
                    duration: 0.3,
                  },
                }}
                key={title}
              >
                <Flex className="gap-20">{children}</Flex>
                <Grid
                  className={classNames(
                    'flex-1 gap-2',
                    tiles.length > 1
                      ? 'grid-cols-2 max-w-[708px]'
                      : 'grid-cols-1 max-w-[350px]'
                  )}
                >
                  {tiles.map((props, idx) => (
                    <NavigationTile key={idx} showButton {...props} />
                  ))}
                </Grid>
              </motion.div>
            </AnimatePresence>
          </Wrapper>
        </Box>
      )}
    </Box>
  </motion.li>
)
