import { Checkbox } from '@lounge-fe/ui-kit'
import { ChangeEventHandler, type ReactNode } from 'react'

interface BasketAddonProps {
  handleChange: ChangeEventHandler<HTMLInputElement>
  disabled: boolean
  checked: boolean
  label: ReactNode
}

export const BasketAddon = ({
  handleChange,
  checked,
  disabled,
  label,
}: BasketAddonProps) => {
  return (
    <div className="flex items-center rounded-sm p-4 bg-background-secondary-default">
      <Checkbox
        className="justify-start gap-y-2 [&_label]:text-md [&_label]:font-regular [&_input]:bg-white"
        checked={Boolean(checked)}
        onChange={handleChange}
        disabled={disabled}
        label={label}
      />
    </div>
  )
}
