import { ElementType } from 'react'

import { Box } from '../../components/Box'

import { ContainerProps } from './types'

const defaultElement = 'div'

export const Container = <C extends ElementType = typeof defaultElement>({
  children,
  as,
  ...props
}: ContainerProps<C>) => {
  const element: React.ElementType = as || defaultElement

  return (
    <Box as={element} className="captioned-image__item" {...props}>
      {children}
    </Box>
  )
}
