import React, { useId } from 'react'

import { SvgProps } from '../types'
import { Icon } from '../Icon'

export const Youtube: React.FC<SvgProps> = (props) => {
  const id = useId()
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <g clipPath={`url(#${id})`}>
        <path
          fill="currentColor"
          d="M19.615 3.1841c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816ZM9 16.0001v-8l8 3.993-8 4.007Z"
        />
      </g>
      <defs>
        <clipPath id={id}>
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </Icon>
  )
}
