import classNames from 'classnames'
import { Text, TextProps } from '@lounge-fe/ui-kit'

export const Title = ({
  className,
  as = 'h1',
  children,
  ...props
}: TextProps<'h1'>) => (
  <Text
    as={as}
    variant="heading-two"
    className={classNames(
      'text-center mb-2 [&_em]:font-[Didot] [&_em]:font-[400]',
      className
    )}
    dangerouslySetInnerHTML={{ __html: children?.toString() ?? '' }}
    {...props}
  />
)
