import classNames from 'classnames'
import { useMemo, useRef } from 'react'

import { useListboxContext } from './Listbox'
import { ListboxItemProps } from './types'

export const Item = ({ className, index = 0, ...props }: ListboxItemProps) => {
  const ref = useRef<HTMLLIElement | null>(null)
  const { selectedIndex, hasFocus, setSelectedIndex } = useListboxContext()

  const selected = useMemo(
    () => selectedIndex === index && hasFocus,
    [selectedIndex, hasFocus, index]
  )

  return (
    <li
      role="option"
      aria-selected={selected}
      className={classNames(
        selected && 'bg-background-primary-default',
        className
      )}
      onMouseEnter={() => setSelectedIndex(index)}
      tabIndex={-1}
      data-index={index}
      ref={ref}
      {...props}
    />
  )
}
