export const getColors = /* GraphQL */ `
  query getColorMetaobjects {
    metaobjects(type: "color", first: 250) {
      nodes {
        id
        handle
        title: field(key: "title") {
          value
        }
        color: field(key: "colors") {
          value
        }
      }
    }
  }
`
