import classNames from 'classnames'

import { ToggleGroup, ToggleGroupItemProps } from '../ToggleGroup'

export const Item = ({
  className,
  disabled,
  enableDisabledStyles = true,
  ...props
}: ToggleGroupItemProps): JSX.Element => (
  <ToggleGroup.Item
    disabled={disabled}
    className={classNames(
      'choice-chips__item',
      enableDisabledStyles && disabled && 'choice-chips__item--disabled',
      className
    )}
    {...props}
  />
)
