import classNames from 'classnames'
import { ElementType } from 'react'

import { Box } from '../../components/Box'
import { Badge } from '../../components/Badge'

import { DetailsProps } from './types'
import { Swatches } from './Swatches'
import { useProductCardContext } from './ProductCard'

const defaultElement = 'div'

export const Details = <C extends ElementType = typeof defaultElement>({
  className,
  as,
  leftIndent = false,
  title,
  price,
  color,
  compareAtPrice,
  badge,
  selectedSwatchId,
  swatches = [],
  onSwatchSelect = () => {},
  availableColors = {},
  canonicalColors = {},
  isLoadingAdditionalData = false,
  useSwatches = false,
  ...props
}: DetailsProps<C>) => {
  const { href } = useProductCardContext()
  const element: React.ElementType = as || defaultElement

  return (
    <Box
      as={element}
      className={classNames(
        'product-card__details',
        { 'product-card__details--left-indent': leftIndent },
        className
      )}
      {...props}
    >
      {useSwatches && swatches.length > 0 && (
        <Swatches
          swatches={swatches}
          selectedSwatchId={selectedSwatchId}
          onSwatchSelect={onSwatchSelect}
          availableColors={availableColors}
          canonicalColors={canonicalColors}
          isLoading={isLoadingAdditionalData}
        />
      )}
      <a href={href}>
        <p className="product-card__details__title">{title}</p>
        {!useSwatches && color && (
          <p className="product-card__details__color">{color}</p>
        )}
        <p className="product-card__details__price">
          {price} <s>{compareAtPrice}</s>
        </p>
      </a>
      {badge && (
        <Badge color={badge?.color} size={badge?.size} outline={badge.outline}>
          {badge?.text}
        </Badge>
      )}
    </Box>
  )
}
