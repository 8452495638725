import i18next from 'i18next'
import * as zod from 'zod'

export const MIN_PASSWORD_LENGTH = 8

const email = zod.string().email()

const password = zod.string().min(1)

export const createPasswordRequirements = [
  {
    isValid: (value: string) => value.length >= MIN_PASSWORD_LENGTH,
    key: 'password_min_length',
    getMessage: () =>
      i18next.t('account.errors.password_min_length', {
        minimum: MIN_PASSWORD_LENGTH,
      }),
  },
  {
    isValid: (value: string) => /[A-Z]/.test(value),
    key: 'password_requires_uppercase',
    getMessage: () => i18next.t('account.errors.password_requires_uppercase'),
  },
  {
    isValid: (value: string) => /[a-z]/.test(value),
    key: 'password_requires_lowercase',
    getMessage: () => i18next.t('account.errors.password_requires_lowercase'),
  },
]

export const createPassword = zod.string().superRefine((val, ctx) => {
  createPasswordRequirements.forEach((requirement) => {
    if (!requirement.isValid(val)) {
      ctx.addIssue({
        code: zod.ZodIssueCode.custom,
        params: { key: requirement.key },
        message: requirement.getMessage(),
      })
    }
  })
})

export const checkConfirmPassword = (
  { confirmPassword, password }: { confirmPassword: string; password: string },
  ctx: zod.RefinementCtx
) => {
  if (confirmPassword !== password) {
    ctx.addIssue({
      code: 'custom',
      message: i18next.t('account.errors.password_mismatch'),
      path: ['confirmPassword'],
    })
  }
}

export type LoginValues = zod.infer<typeof login>
export const login = zod.object({
  email,
  password,
})

export type RegisterValues = zod.infer<typeof register>

export const preRefinedRegister = zod.object({
  firstName: zod.string().min(1),
  lastName: zod.string().min(1),
  email,
  password: createPassword,
  confirmPassword: zod.string(),
  acceptsMarketing: zod.boolean(),
})

export const register = preRefinedRegister.superRefine(checkConfirmPassword)

export type ForgotPasswordValues = zod.infer<typeof forgotPassword>
export const forgotPassword = zod.object({
  email,
})

export type ResetPasswordValues = zod.infer<typeof resetPassword>
export const resetPassword = zod
  .object({
    password: createPassword,
    confirmPassword: zod.string(),
  })
  .superRefine(checkConfirmPassword)
