import classNames from 'classnames'
import { FC } from 'react'

import { DividerProps } from './types'

export const Divider: FC<DividerProps> = ({
  orientation = 'horizontal',
  className,
  ...props
}) => (
  <hr
    className={classNames('divider', className)}
    aria-orientation={orientation}
    {...props}
  />
)
