import React from 'react'

import { Icon } from '../../Icon'

import { PaymentProviderSvgProps } from './types'

export const Klarna: React.FC<PaymentProviderSvgProps> = ({
  borderColor = 'currentColor',
  foregroundColor = 'currentColor',
  fill = 'none',
  ...props
}) => {
  return (
    <Icon viewBox="0 0 35.19 22.225" width="40" {...props}>
      <path
        d="M32.147.265h.264a2.514 2.514 0 0 1 2.514 2.513v16.669a2.514 2.514 0 0 1-2.514 2.513H2.778a2.514 2.514 0 0 1-2.513-2.513V2.778A2.514 2.514 0 0 1 2.778.265z"
        fill={fill}
        stroke={borderColor}
        strokeWidth=".52916"
      />
      <path
        d="M30.46 12.444c-.42 0-.76.347-.76.776 0 .428.34.776.76.776s.76-.348.76-.776a.768.768 0 0 0-.76-.776zm-2.503-.6c0-.587-.492-1.063-1.098-1.063-.607 0-1.098.476-1.098 1.063s.491 1.062 1.098 1.062c.606 0 1.098-.475 1.098-1.062zm.004-2.065h1.212v4.13h-1.212v-.264a2.095 2.095 0 0 1-1.201.378c-1.18 0-2.137-.976-2.137-2.18 0-1.203.957-2.178 2.137-2.178.446 0 .859.14 1.201.378zm-9.7.538v-.538h-1.24v4.13h1.243V11.98c0-.651.691-1 1.17-1h.015V9.779c-.492 0-.945.215-1.188.538zm-3.09 1.527c0-.587-.493-1.063-1.1-1.063-.606 0-1.097.476-1.097 1.063s.491 1.062 1.098 1.062c.606 0 1.098-.475 1.098-1.062zm.003-2.065h1.212v4.13h-1.212v-.264a2.096 2.096 0 0 1-1.201.378c-1.18 0-2.137-.976-2.137-2.18 0-1.203.957-2.178 2.137-2.178.445 0 .859.14 1.201.378V9.78zm7.296-.111c-.484 0-.943.153-1.25.576V9.78h-1.206v4.13h1.222v-2.17c0-.629.412-.936.91-.936.532 0 .839.325.839.927v2.179h1.21v-2.627c0-.96-.749-1.614-1.725-1.614zm-12.4 4.24h1.27v-5.97h-1.27zm-5.572.002H5.84V7.937H4.498zm4.699-5.973a4.8 4.8 0 0 1-1.375 3.39L9.68 13.91H8.02l-2.018-2.807.52-.398a3.453 3.453 0 0 0 1.36-2.768h1.315z"
        fill={foregroundColor}
      />
    </Icon>
  )
}
