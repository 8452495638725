import { FC, HTMLAttributes } from 'react'
import { motion, HTMLMotionProps } from 'framer-motion'
import { Icon, Text } from '@lounge-fe/ui-kit'
import { useTranslation } from 'react-i18next'

export const ItemAddedAlert: FC<HTMLMotionProps<'div'> & { id?: string }> = ({
  id,
  ...props
}) => {
  const { t } = useTranslation()
  return (
    <motion.div
      data-testid="item-added-alert"
      className="flex px-4 py-5 2xl:px-10 bg-base-primary gap-x-4"
      {...props}
    >
      <div className="flex items-center justify-center w-6 h-6 p-2 bg-white rounded-circle [&_svg]:max-w-full">
        <Icon.Check className="text-bg-base-primary" />
      </div>
      <Text id={id} className="text-text-on-default">
        {t('cart.items_added')}
      </Text>
    </motion.div>
  )
}
