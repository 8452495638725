import React from 'react'

import { SvgProps } from '../types'
import { Icon } from '../Icon'

export const Upload: React.FC<SvgProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        d="M6 23C5.45 23 4.97933 22.8043 4.588 22.413C4.196 22.021 4 21.55 4 21V10C4 9.45 4.196 8.979 4.588 8.587C4.97933 8.19567 5.45 8 6 8H9V10H6V21H18V10H15V8H18C18.55 8 19.021 8.19567 19.413 8.587C19.8043 8.979 20 9.45 20 10V21C20 21.55 19.8043 22.021 19.413 22.413C19.021 22.8043 18.55 23 18 23H6ZM11 16V4.825L9.4 6.425L8 5L12 1L16 5L14.6 6.425L13 4.825V16H11Z"
        fill="currentColor"
      />
    </Icon>
  )
}
