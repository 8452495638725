import { Text, TextProps } from '../Text'

import { useSelectContext } from './Select'

const elementType = 'label'

export const Label = (props: TextProps<typeof elementType>) => {
  const { id } = useSelectContext()
  return <Text htmlFor={id} as={elementType} {...props} />
}
