import React from 'react'

import { SvgProps } from '../types'
import { Icon } from '../Icon'

export const Play: React.FC<SvgProps> = (props) => {
  return (
    <Icon viewBox="0 0 25 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3018 21C13.4837 21 14.654 20.7672 15.7459 20.3149C16.8378 19.8626 17.83 19.1997 18.6657 18.364C19.5014 17.5282 20.1644 16.5361 20.6167 15.4442C21.069 14.3522 21.3018 13.1819 21.3018 12C21.3018 10.8181 21.069 9.64778 20.6167 8.55585C20.1644 7.46392 19.5014 6.47177 18.6657 5.63604C17.83 4.80031 16.8378 4.13738 15.7459 3.68508C14.654 3.23279 13.4837 3 12.3018 3C9.91481 3 7.62562 3.94821 5.9378 5.63604C4.24997 7.32387 3.30176 9.61305 3.30176 12C3.30176 14.3869 4.24997 16.6761 5.9378 18.364C7.62562 20.0518 9.91481 21 12.3018 21ZM12.3018 23C18.3768 23 23.3018 18.075 23.3018 12C23.3018 5.925 18.3768 1 12.3018 1C6.22676 1 1.30176 5.925 1.30176 12C1.30176 18.075 6.22676 23 12.3018 23Z"
        fill="currentColor"
      />
      <path
        d="M16.3018 12L10.3018 16.33V7.67001L16.3018 12Z"
        fill="currentColor"
      />
    </Icon>
  )
}
