import { useService } from '@/hooks/useService'
import { useAuth } from '@lounge-fe/auth'
import { flattenConnection } from '@shopify/hydrogen-react'
import { ProductConnection } from '@shopify/hydrogen-react/storefront-api-types'
import { useQuery } from '@tanstack/react-query'
import { WishlistService } from '../services/WishlistService'

const QUERY_KEY = ['wishlist-items']

export const useWishlistItems = () => {
  const wishlistService = useService(new WishlistService())
  const { status } = useAuth()
  const query = useQuery({
    queryKey: QUERY_KEY,
    queryFn: async () => {
      const { data, ...res } = await wishlistService.get({
        // There's a bug with the storefront API where subsequent pages always return an empty page.
        // https://community.shopify.com/c/storefront-api-and-sdks/metafield-references-pagination-always-returns-an-empty-page/m-p/2026544
        perPage: 250,
      })
      return flattenConnection<ProductConnection>(data).map(
        (product) => product.id
      )
    },
    enabled: status === 'authenticated',
  })

  return { ...query, queryKey: QUERY_KEY }
}
