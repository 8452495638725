import classNames from 'classnames'

import { Text } from '../../components/Text'

import { ShareSourceProps } from './types'

export const ShareSource = ({
  href,
  onClick,
  icon,
  text,
  iconClassName,
}: ShareSourceProps) => {
  const Comp = href ? 'a' : 'button'
  return (
    <Comp
      href={href}
      target={href ? '_blank' : undefined}
      onClick={!href ? onClick : undefined}
      className="flex gap-x-2 items-center py-1"
    >
      <div
        className={classNames(
          'w-8 h-8 rounded-circle flex justify-center items-center',
          iconClassName
        )}
      >
        {icon}
      </div>
      <Text variant="body-sm">{text}</Text>
    </Comp>
  )
}
