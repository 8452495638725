import { motion } from 'framer-motion'

import { Box, Grid, Wrapper } from '@lounge-fe/ui-kit'

import { SearchMenuProps, SearchTileProps } from './types'
import { SearchTile } from './SearchTile'

export const Menu = ({ children, tiles = [] }: SearchMenuProps) => (
  <Wrapper>
    <Grid
      as={motion.div}
      className="items-start justify-between grid-cols-1 gap-4 md:grid-cols-4"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        opacity: {
          delay: 0.1,
          duration: 0.3,
        },
      }}
    >
      <Box>{children}</Box>
      <Grid className="grid-cols-2 col-span-3 gap-4 lg:grid-cols-3">
        {tiles?.map((tile: SearchTileProps, index) => {
          const { title, image, href } = tile
          return (
            <SearchTile title={title} key={index} image={image} href={href} />
          )
        })}
      </Grid>
    </Grid>
  </Wrapper>
)
