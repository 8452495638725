import { FocusEventHandler } from 'react'

export const preventBlurWithin =
  <TCallbackReturn = unknown>(
    callback: () => TCallbackReturn
  ): FocusEventHandler<HTMLElement> =>
  (event) => {
    // if the blur was because of outside focus
    // currentTarget is the parent element, relatedTarget is the clicked element
    if (!event.currentTarget.contains(event.relatedTarget)) {
      return callback()
    }
  }
