import { useEffect, useMemo, useState } from 'react'
import * as Sentry from '@sentry/react'

import { storefrontClient } from '@/lib'
import { parseGID } from '@/utils/parseGID'
import {
  getProductsVariantsData,
  GetProductsVariantsResponse,
} from '../../queries/getProductsVariantsData'
import type { Cache } from './types'
import { ExtendedProductHit, ExtendedProductHitWithVariants } from '../../types'
import { GetProductsVariantsResponseNode } from '../../queries/getProductsVariantsData'

const flattenProductIds = (products: ExtendedProductHit[]): number[] =>
  products.reduce<number[]>(
    (accum: number[], product) => [
      ...accum,
      product.id,
      ...(product.linkedBottoms?.map((linkedBottom) => linkedBottom.id) ?? []),
      ...(product.swatches?.flatMap((swatch) => [
        swatch.id,
        ...swatch.linkedBottoms.map(({ id }) => id),
      ]) ?? []),
    ],
    []
  )

const filterProductIds = (productIds: number[], data: Cache) =>
  productIds.filter((id) => !data.has(id))

const id = {
  toGid: (id: number, childObjectType: string) =>
    `gid://shopify/${childObjectType}/${id}`,
  fromGid: (gid: string) => Number(parseGID(gid).childObjectId),
}

const fetchProductsVariants = async (
  gids: string[]
): Promise<GetProductsVariantsResponseNode[]> => {
  const response = await storefrontClient.request<GetProductsVariantsResponse>(
    getProductsVariantsData,
    { variables: { ids: gids } }
  )

  return response.data?.nodes.filter(Boolean) ?? []
}

const cacheNewItems =
  (nodes: GetProductsVariantsResponseNode[]) => (previous: Cache) => {
    const newCache = new Map(previous)
    nodes.forEach((node) => {
      newCache.set(id.fromGid(node.id), node)
    })
    return newCache
  }

const mapVariantsData = (
  variants: GetProductsVariantsResponseNode['variants']['nodes']
) =>
  variants.map((variant) => ({
    ...variant,
    id: id.fromGid(variant.id),
  }))

type TResponse<TEnabled extends boolean> = TEnabled extends true
  ? ExtendedProductHitWithVariants[]
  : ExtendedProductHit[]

function useProductsVariants<TEnabled extends boolean>(
  products: ExtendedProductHit[],
  enabled: TEnabled
): TResponse<TEnabled> {
  const [cache, setCache] = useState(
    new Map<number, GetProductsVariantsResponseNode>()
  )

  const allProductIds = useMemo(() => flattenProductIds(products), [products])

  useEffect(() => {
    if (!enabled) return

    const update = async () => {
      const filteredIds = filterProductIds(allProductIds, cache).map((pid) =>
        id.toGid(pid, 'Product')
      )
      if (filterProductIds.length === 0) return

      try {
        const response = await fetchProductsVariants(filteredIds)
        setCache(cacheNewItems(response))
      } catch (error) {
        Sentry.captureException(error)
      }
    }

    if (allProductIds?.length > 0) {
      update()
    }
  }, [allProductIds])

  if (enabled) {
    return products.map((product) => ({
      ...product,
      variants: mapVariantsData(cache.get(product.id)?.variants.nodes ?? []),
      linkedBottoms: product.linkedBottoms?.map((linkedBottom) => ({
        ...linkedBottom,
        variants: mapVariantsData(
          cache.get(linkedBottom.id)?.variants.nodes ?? []
        ),
      })),
      swatches: product.swatches?.map((swatch) => ({
        ...swatch,
        variants: mapVariantsData(cache.get(swatch.id)?.variants.nodes ?? []),
        linkedBottoms: swatch.linkedBottoms?.map((linkedBottom) => ({
          ...linkedBottom,
          variants: mapVariantsData(
            cache.get(linkedBottom.id)?.variants.nodes ?? []
          ),
        })),
      })),
    }))
  } else {
    return products as TResponse<TEnabled>
  }
}

export { useProductsVariants }
