import { createCompoundComponent } from '../../utilities'

import { Carousel as BaseCarousel } from './Carousel'
import { Container } from './Container'
import { Arrows, ArrowPrevious, ArrowNext } from './Arrows'
import { Progress } from './Progress'
import { Slide } from './Slide'
import { Thumbnails } from './Thumbnails'
import { Viewport } from './Viewport'

export const Carousel = createCompoundComponent(BaseCarousel, {
  Container,
  Slide,
  Arrows,
  Progress,
  Thumbnails,
  Viewport,
  ArrowPrevious,
  ArrowNext,
})
