import {
  ChangeEventHandler,
  forwardRef,
  useCallback,
  useId,
  useMemo,
  useState,
} from 'react'
import classnames from 'classnames'

import { Box } from '../Box'

import { InputProps } from './types'

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      id: providedId,
      label,
      errorText,
      required,
      startAdornment,
      endAdornment,
      value,
      onChange: parentOnChange,
      className,
      color = 'light',
      labelPosition = 'inside',
      multiline,
      filled = false,
      ...props
    },
    ref
  ) => {
    const generatedId = useId()
    const id = providedId ?? generatedId

    const InputComp = multiline ? 'textarea' : 'input'

    const hasError = Boolean(errorText)

    const helperTextId = `${id}-helper-text`

    const handleChange: ChangeEventHandler<
      HTMLInputElement | HTMLTextAreaElement
    > = useCallback(
      (evt) => {
        parentOnChange?.(evt)
      },
      [parentOnChange]
    )

    const hasValue = useMemo(() => Boolean(value), [value])

    return (
      <Box
        className={classnames(
          'input-group',
          {
            'input-group--error': hasError,
            'input-group--has-value': hasValue || Boolean(props.placeholder),
            'input-group--dark': color === 'dark',
            'input-group--filled': filled,
          },
          className
        )}
      >
        {label && labelPosition === 'outside' && (
          <label htmlFor={id}>
            {label}
            {required && '*'}
          </label>
        )}
        <div className="input-group__control">
          {startAdornment && (
            <div className="input-group__adornment input-group__adornment--placement-start">
              {startAdornment}
            </div>
          )}
          <Box className="input-group__input">
            {label && labelPosition === 'inside' && (
              <label htmlFor={id}>
                {label}
                {required && '*'}
              </label>
            )}
            <InputComp
              id={id}
              value={value}
              onChange={handleChange}
              aria-invalid={hasError}
              aria-describedby={helperTextId}
              autoComplete="off"
              ref={ref as any}
              {...props}
            />
          </Box>
          {endAdornment && (
            <div className="input-group__adornment input-group__adornment--placement-end">
              {endAdornment}
            </div>
          )}
        </div>
        {multiline && props.maxLength && (
          <div className="text-right pt-3">
            {value?.toString().length ?? 0}/<b>{props.maxLength}</b>
          </div>
        )}

        {errorText && (
          <p className="input-group__helper-text" id={helperTextId}>
            {errorText}
          </p>
        )}
      </Box>
    )
  }
)

Input.displayName = 'Input'
