import React from 'react'

import { SvgProps } from '../types'
import { Icon } from '../Icon'

export const Facebook: React.FC<SvgProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path d="M14 13.5002H16.5L17.5 9.50024H14V7.50024C14 6.47024 14 5.50024 16 5.50024H17.5V2.14024C17.174 2.09724 15.943 2.00024 14.643 2.00024C11.928 2.00024 10 3.65724 10 6.70024V9.50024H7V13.5002H10V22.0002H14V13.5002Z" />
    </Icon>
  )
}
