import { ShopifySectionComponent } from '@/types/ShopifySectionComponent'
import { ProductCarousel as BaseProductCarousel } from '@lounge-fe/ui-kit'

import { ProductCarouselData } from './types'
import { StandardProductCard } from '@/features/product/components/StandardProductCard'
import { createLiquidExtendedProductHit } from '@/features/product/utils/createExtendedProductHit'

export const ProductCarousel: ShopifySectionComponent<ProductCarouselData> = ({
  data,
}) => {
  const { heading, loop, controls, wrapper, products } = data!

  return (
    <BaseProductCarousel
      heading={heading}
      loop={loop}
      controls={controls}
      wrapper={wrapper}
    >
      {products.map((product) => {
        return (
          <StandardProductCard
            key={product.id}
            product={createLiquidExtendedProductHit(product)}
            useQuickAdd
          />
        )
      })}
    </BaseProductCarousel>
  )
}
