import { RestErrorInit } from './types'

export class RestError<TData = unknown> extends Error {
  public status: number
  public data: TData

  constructor({ status, message, data }: RestErrorInit<TData>) {
    super(message)
    this.status = status
    this.data = data
  }
}
