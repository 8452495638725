import { ICartPlugin } from '../types'
import type { CartManager } from '../CartManager'

class CartPlugin implements ICartPlugin {
  protected manager!: Readonly<CartManager>
  private _enabled: boolean

  get enabled(): boolean {
    return this._enabled
  }

  constructor(enabled = true) {
    this._enabled = enabled
  }

  init = (manager: CartManager): CartPlugin => {
    this.manager = manager
    return this
  }
}

export default CartPlugin
