import React from 'react'

import { SvgProps } from '../types'
import { Icon } from '../Icon'

export const BurgerMenu: React.FC<SvgProps> = (props) => {
  return (
    <Icon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M1 4H23M1 12H23M1 20H23"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
