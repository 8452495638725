import { AUS } from './AUS'
import { CA } from './CA'
import { CH } from './CH'
import { DE } from './DE'
import { DK } from './DK'
import { EU } from './EU'
import { FR } from './FR'
import { GB } from './GB'
import { NL } from './NL'
import { SV } from './SV'
import { US } from './US'
import { ROW } from './ROW'

export const Flags = {
  AUS,
  CA,
  CH,
  DE,
  DK,
  EU,
  FR,
  GB,
  NL,
  SV,
  US,
  ROW,
}
