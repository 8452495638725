import React from 'react'

import { SvgProps } from '../types'
import { Icon } from '../Icon'

export const Tick: React.FC<SvgProps> = (props) => {
  return (
    <Icon viewBox="0 0 32 32" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32ZM21.8584 9.53553C22.5773 8.82149 23.7429 8.82149 24.4618 9.53553C25.1807 10.2496 25.1807 11.4073 24.4618 12.1213L14.0484 22.4644C13.394 23.1145 12.3693 23.1727 11.6484 22.6391C11.627 22.6232 11.6058 22.6068 11.5849 22.5899C11.5366 22.5508 11.4899 22.5089 11.4449 22.4642L7.5399 18.5856C6.82101 17.8715 6.82101 16.7138 7.5399 15.9998C8.25879 15.2858 9.42434 15.2858 10.1432 15.9998L12.7468 18.5858L21.8584 9.53553Z"
        fill="currentColor"
      />
    </Icon>
  )
}
