import React from 'react'

import { SvgProps } from '../types'
import { Icon } from '../Icon'

export const EyeSlash: React.FC<SvgProps> = (props) => {
  return (
    <Icon width="30" height="25" viewBox="0 0 30 25" fill="none" {...props}>
      <path d="M1.66671 2.02671L3.37337 0.333374L25.6667 22.6267L23.9734 24.3334L19.8667 20.2267C18.3334 20.7334 16.7067 21 15 21C8.33337 21 2.64004 16.8534 0.333374 11C1.25337 8.65337 2.72004 6.58671 4.58671 4.94671L1.66671 2.02671ZM15 7.00004C16.0609 7.00004 17.0783 7.42147 17.8285 8.17161C18.5786 8.92176 19 9.93918 19 11C19.0007 11.4541 18.9241 11.905 18.7734 12.3334L13.6667 7.22671C14.0951 7.07602 14.546 6.99937 15 7.00004ZM15 1.00004C21.6667 1.00004 27.36 5.14671 29.6667 11C28.5785 13.7643 26.7293 16.1637 24.3334 17.92L22.44 16.0134C24.2839 14.7379 25.771 13.0121 26.76 11C25.6821 8.79996 24.0086 6.9464 21.9297 5.65005C19.8509 4.35369 17.45 3.66655 15 3.66671C13.5467 3.66671 12.12 3.90671 10.7867 4.33337L8.73337 2.29337C10.6534 1.46671 12.7734 1.00004 15 1.00004ZM3.24004 11C4.31796 13.2001 5.99147 15.0537 8.07035 16.35C10.1492 17.6464 12.5501 18.3335 15 18.3334C15.92 18.3334 16.8267 18.24 17.6667 18.0534L14.6267 15C13.6989 14.9006 12.8331 14.4865 12.1733 13.8267C11.5135 13.1669 11.0995 12.3012 11 11.3734L6.46671 6.82671C5.14671 7.96004 4.04004 9.37337 3.24004 11Z" />
    </Icon>
  )
}
