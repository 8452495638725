import { useRef, useState } from 'react'

import { QuickAddSubmitStatus } from './types'

export const useQuickAddSubmitStatus = (successDelay = 3000) => {
  const [status, setStatus] = useState<QuickAddSubmitStatus>('idle')
  const timeout = useRef<number | undefined>()

  const updateStatus = (newStatus: QuickAddSubmitStatus) => {
    if (timeout.current) {
      clearTimeout(timeout.current)
      timeout.current = undefined
    }

    setStatus(newStatus)

    if (newStatus !== 'idle') {
      timeout.current = window.setTimeout(() => {
        setStatus('idle')
      }, successDelay)
    }
  }

  return [status, updateStatus] as const
}
