import classNames from 'classnames'
import { cloneElement, ElementType } from 'react'

import { Button } from '../Button'

import { IconButtonProps } from './types'

const defaultElement = 'button'

export const IconButton = <E extends ElementType = typeof defaultElement>({
  icon,
  as,
  className,
  variant = 'ghost',
  ...props
}: IconButtonProps<E>) => {
  const element: React.ElementType = as || defaultElement
  return (
    <Button
      as={element}
      className={classNames('button--icon', className)}
      variant={variant}
      {...props}
    >
      {cloneElement(icon, {
        width: icon.props.width,
        height: icon.props.height,
      })}
    </Button>
  )
}
