import classNames from 'classnames'
import type { ElementType } from 'react'

import type { BadgeProps, colorVariant, sizeVariant } from './types'
import { Box } from '../Box'

const variantColors: { [key in colorVariant]: string } = {
  secondary: 'badge--color-secondary',
  white: 'badge--color-white',
  tertiary: 'badge--color-tertiary',
  critical: 'badge--color-critical',
}

const variantSizes: { [key in sizeVariant]: string } = {
  sm: 'badge--size-sm',
  lg: 'badge--size-lg',
}

const defaultElement = 'div'

export const Badge = <C extends ElementType = typeof defaultElement>({
  color = 'secondary',
  size = 'sm',
  as,
  outline = false,
  className,
  ...props
}: BadgeProps<C>): JSX.Element => {
  const element: React.ElementType = as || defaultElement
  return (
    <Box
      as={element}
      className={classNames(
        'badge',
        variantColors[color],
        variantSizes[size],
        outline && 'badge--outline',
        className
      )}
      {...props}
    />
  )
}
