import { createCompoundComponent } from '../../utilities'

import { Icon as BaseIcon } from './Icon'
import { Account } from './Icons/Account'
import { ArrowDown } from './Icons/ArrowDown'
import { ArrowRight } from './Icons/ArrowRight'
import { ArrowLeft } from './Icons/ArrowLeft'
import { Bag } from './Icons/Bag'
import { Box } from './Icons/Box'
import { Bra } from './Icons/Bra'
import { BurgerMenu } from './Icons/BurgerMenu'
import { Calendar } from './Icons/Calendar'
import { Check } from './Icons/Check'
import { ChevronUp } from './Icons/ChevronUp'
import { ChevronDown } from './Icons/ChevronDown'
import { ChevronLeft } from './Icons/ChevronLeft'
import { ChevronRight } from './Icons/ChevronRight'
import { Close } from './Icons/Close'
import { Cog } from './Icons/Cog'
import { Copy } from './Icons/Copy'
import { Email } from './Icons/Email'
import { Energy } from './Icons/Energy'
import { Eye } from './Icons/Eye'
import { EyeSlash } from './Icons/EyeSlash'
import { Facebook } from './Icons/Facebook'
import { FacebookAlt } from './Icons/FacebookAlt'
import { Filters } from './Icons/Filters'
import { Gift } from './Icons/Gift'
import { Globe } from './Icons/Globe'
import { Heart } from './Icons/Heart'
import { House } from './Icons/House'
import { Information } from './Icons/Information'
import { Link } from './Icons/Link'
import { LinkedIn } from './Icons/LinkedIn'
import { Loading } from './Icons/Loading'
import { Lock } from './Icons/Lock'
import { MapMarker } from './Icons/MapMarker'
import { Menu } from './Icons/Menu'
import { Minus } from './Icons/Minus'
import { ParcelReturn } from './Icons/ParcelReturn'
import { Play } from './Icons/Play'
import { Plus } from './Icons/Plus'
import { Ruler } from './Icons/Ruler'
import { Search } from './Icons/Search'
import { ShoppingBag } from './Icons/ShoppingBag'
import { Tick } from './Icons/Tick'
import { Truck } from './Icons/Truck'
import { Twitter } from './Icons/Twitter'
import { PaymentProviders } from './Icons/PaymentProviders'
import { Upload } from './Icons/Upload'
import { Instagram } from './Icons/Instagram'
import { Pinterest } from './Icons/Pinterest'
import { Youtube } from './Icons/Youtube'
import { TikTok } from './Icons/TikTok'
import { Flags } from './Icons/Flags'
import { CheckCircle } from './Icons/CheckCircle'
import { Returns } from './Icons/Returns'
import { CycleArrow } from './Icons/CycleArrow'
import { Trash } from './Icons/Trash'

export type { SvgProps } from './types'

export const Icon = createCompoundComponent(BaseIcon, {
  Account,
  ArrowDown,
  ArrowRight,
  ArrowLeft,
  Bag,
  Box,
  Bra,
  BurgerMenu,
  Calendar,
  Cog,
  Copy,
  Check,
  CheckCircle,
  ChevronUp,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  Close,
  Email,
  Energy,
  Eye,
  EyeSlash,
  Facebook,
  FacebookAlt,
  Filters,
  Flags,
  Gift,
  Globe,
  Heart,
  House,
  Information,
  Instagram,
  Link,
  LinkedIn,
  Loading,
  Lock,
  MapMarker,
  Menu,
  Minus,
  ParcelReturn,
  PaymentProviders,
  Pinterest,
  Play,
  Plus,
  Ruler,
  Search,
  ShoppingBag,
  Tick,
  TikTok,
  Trash,
  Truck,
  Twitter,
  Upload,
  Youtube,
  Returns,
  CycleArrow,
})
