import { SelectStoreModal } from '@/features/localisation/components/SelectStoreModal'

export const CountrySelector = () => {
  return (
    <SelectStoreModal>
      <SelectStoreModal.Trigger />
      <SelectStoreModal.Content />
    </SelectStoreModal>
  )
}
