import { ShopifySectionComponent } from '@/types/ShopifySectionComponent'
import {
  Icon,
  IconButton,
  Image,
  Text,
  Wrapper,
  useMediaQuery,
} from '@lounge-fe/ui-kit'

import { ContentCarouselData } from './types'
import { useTranslation } from 'react-i18next'
import { motion, useDragControls, useMotionValue } from 'framer-motion'
import { useState } from 'react'

const ContentCarousel: ShopifySectionComponent<ContentCarouselData> = ({
  data,
}) => {
  const { t } = useTranslation()
  const { title, slides } = data!

  const isDesktop = useMediaQuery(({ screens }) => `(min-width: ${screens.sm})`)
  const [activeSlide, setActiveSlide] = useState(0)
  const controls = useDragControls()

  const x = useMotionValue(0)

  const onDragEnd = (event: any, info: any) => {
    const velocity = info.velocity.x
    const direction = velocity < 0 ? 1 : -1

    // Update the active slide based on the direction of the drag
    setActiveSlide((prevSlide) =>
      Math.min(Math.max(prevSlide + direction, 0), slides.length - 1)
    )
  }

  return (
    <div className="overflow-hidden">
      <Wrapper className="flex flex-col gap-y-6 py-[68px] md:py-[132px]">
        <Text className="text-[#E7E7E7]" variant="display">
          {title}
        </Text>
        <Wrapper
          size={isDesktop ? 'md' : 'full'}
          className="grid max-w-[1200px]"
        >
          {slides.map((slide, idx) => {
            const { title, image, placeholderImage, body } = slide
            return (
              <>
                <div key={idx} className="col-[1] row-[1]">
                  <div className="relative flex flex-col gap-4 relative w-full left-0 sm:flex-row !transform-none sm:gap-14">
                    <motion.div
                      className={`basis-1/2 flex flex-col gap-6 !transform-none ${
                        activeSlide === idx ? 'z-10' : 'z-0'
                      }`}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: activeSlide === idx ? 1 : 0 }}
                      transition={{ duration: 0.6 }}
                      drag="x"
                      dragControls={controls}
                      style={{
                        touchAction: 'none',
                      }}
                      onDragEnd={onDragEnd}
                      dragConstraints={{
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                      }}
                    >
                      {image ? (
                        <div className="aspect-[1/1]">
                          <Image
                            className="object-cover h-full"
                            data={{
                              src: image.src,
                              width: 800,
                              height: 800,
                              crop: 'center',
                            }}
                            srcSet={[
                              [{ scale: undefined }, '1x'],
                              [{ scale: 2, quality: 100 }, '2x'],
                            ]}
                          ></Image>
                        </div>
                      ) : (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: placeholderImage,
                          }}
                        />
                      )}
                    </motion.div>
                    {!isDesktop && (
                      <div className="flex gap-1 max-w-[248px]">
                        {slides.map((_, idx) => {
                          return (
                            <div
                              key={idx}
                              className="h-1 relative flex-1 max-w-[40px]"
                            >
                              <button
                                onClick={() => setActiveSlide(idx)}
                                className="block h-1 w-full bg-[#C8C8C8]"
                              />
                              {activeSlide === idx && (
                                <motion.div
                                  transition={{ duration: 0.6 }}
                                  layoutId="contentCarouselNavigation"
                                  className="h-1 bg-black absolute top-0 left-0 w-full z-10 pointer-events-none"
                                />
                              )}
                            </div>
                          )
                        })}
                      </div>
                    )}

                    <motion.div
                      className={`basis-1/2 flex flex-col items-start justify-center !transform-none ${
                        activeSlide === idx ? 'z-10' : 'z-0'
                      }`}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: activeSlide === idx ? 1 : 0 }}
                      transition={{ duration: 0.6 }}
                      drag="x"
                      dragControls={controls}
                      style={{
                        touchAction: 'none',
                      }}
                      onDragEnd={onDragEnd}
                      dragConstraints={{
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                      }}
                    >
                      <div className="flex flex-nowrap gap-4 pb-6 hidden sm:flex">
                        <IconButton
                          type="button"
                          variant="outline"
                          icon={<Icon.ArrowLeft width={24} height={24} />}
                          size="lg"
                          aria-label={t('account.account')}
                          onClick={() =>
                            setActiveSlide((prevSlide) =>
                              prevSlide === 0
                                ? slides.length - 1
                                : prevSlide - 1
                            )
                          }
                          disabled={activeSlide === 0}
                        />
                        <IconButton
                          type="button"
                          variant="outline"
                          icon={<Icon.ArrowRight width={24} height={24} />}
                          size="lg"
                          aria-label={t('account.account')}
                          onClick={() =>
                            setActiveSlide(
                              (prevSlide) => (prevSlide + 1) % slides.length
                            )
                          }
                          disabled={activeSlide === slides.length - 1}
                        />
                      </div>

                      <Text
                        variant="heading-three"
                        as="h3"
                        dangerouslySetInnerHTML={{ __html: title }}
                      />

                      <Text
                        variant="body-md"
                        className="pt-3"
                        as="p"
                        dangerouslySetInnerHTML={{ __html: body }}
                      />
                    </motion.div>
                  </div>
                </div>
              </>
            )
          })}
          {isDesktop && (
            <div className="flex gap-1 max-w-[248px] mb-6 mt-6">
              {slides.map((_, idx) => {
                return (
                  <div key={idx} className="h-1 relative flex-1 max-w-[40px]">
                    <button
                      onClick={() => setActiveSlide(idx)}
                      className="block h-1 w-full bg-[#C8C8C8]"
                    />
                    {activeSlide === idx && (
                      <motion.div
                        transition={{ duration: 0.6 }}
                        layoutId="contentCarouselNavigation"
                        className="h-1 bg-black absolute top-0 left-0 w-full z-10 pointer-events-none"
                      />
                    )}
                  </div>
                )
              })}
            </div>
          )}
        </Wrapper>
      </Wrapper>
    </div>
  )
}

export { ContentCarousel }
