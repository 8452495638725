import classNames from 'classnames'
import { useId, useMemo } from 'react'
import { ErrorCode, useDropzone } from 'react-dropzone'
import { Trans, useTranslation } from 'react-i18next'

import { Text } from '../Text'
import { Icon } from '../Icon'

import { FileUploadProps, Rejection } from './types'
import { formatBytes } from './formatBytes'
import { Rejections } from './Rejections'

export const FileUpload = ({
  color = 'primary',
  maxFiles = 1,
  label,
  ...props
}: FileUploadProps) => {
  const id = useId()

  const { t } = useTranslation()

  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
    fileRejections,
    isDragActive,
    isDragReject,
  } = useDropzone({
    multiple: false,
    maxFiles,
    ...props,
  })

  const acceptedFileTypes = useMemo(
    () =>
      props.accept
        ? Object.values(props.accept)
            .map((x) => x.join(', '))
            .join(', ')
        : undefined,
    [props.accept]
  )

  const acceptedMaxFileSize = useMemo(
    () => (props.maxSize ? formatBytes(props.maxSize, 0) : undefined),
    [props.maxSize]
  )

  const acceptedMinFileSize = useMemo(
    () => (props.minSize ? formatBytes(props.minSize, 0) : undefined),
    [props.minSize]
  )

  const rejections: Rejection[] = useMemo(() => {
    const errorMap: { [key in ErrorCode]: string } = {
      'file-too-large': t('file_upload.error.file_too_big', {
        size: acceptedMaxFileSize,
      }),
      'file-too-small': t('file_upload.error.file_too_small', {
        size: acceptedMinFileSize,
      }),
      'file-invalid-type': t('file_upload.error.file_invalid_type', {
        types: acceptedFileTypes,
      }),
      'too-many-files': t('file_upload.error.too_many_files', {
        count: maxFiles,
      }),
    }

    return fileRejections.map((rejection) => ({
      filename: rejection.file.name,
      errors: rejection.errors.map((x) => errorMap[x.code as ErrorCode]),
    }))
  }, [fileRejections])

  return (
    <div
      data-testid="dropzone"
      className={classNames(
        'file-upload',
        color === 'secondary' && 'file-upload--color-secondary',
        (isDragReject || rejections.length) && 'file-upload--invalid',
        isDragActive && 'file-upload--drag-active'
      )}
      {...getRootProps()}
    >
      <input {...getInputProps()} autoComplete="off" id={id} />
      <div className="flex flex-col gap-4">
        <Icon.Upload className="text-text-default" width={24} />
        {acceptedFiles.length && !rejections.length ? (
          acceptedFiles.map((file, idx) => (
            <Text key={`${file.name}${idx}`}>{file.name}</Text>
          ))
        ) : (
          <div className="text-center">
            {rejections.length ? (
              <Rejections rejections={rejections} />
            ) : (
              <>
                <Text as="label" htmlFor={id}>
                  <Trans components={{ bold: <b className="underline" /> }}>
                    {label ?? t('file_upload.drag_and_drop_upload')}
                  </Trans>
                </Text>
                {acceptedFileTypes && (
                  <Text variant="body-sm">
                    {t('file_upload.accepted_files', {
                      files: acceptedFileTypes,
                    })}
                  </Text>
                )}
                {acceptedMaxFileSize && (
                  <Text variant="body-sm">
                    {t('file_upload.max_size', {
                      size: acceptedMaxFileSize,
                    })}
                  </Text>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
