import classNames from 'classnames'
import {
  ElementType,
  createContext,
  useMemo,
  useContext,
  useId,
  useState,
} from 'react'

import { Box } from '../Box'

import { SelectContextState, SelectProps, SelectSize } from './types'

const defaultElement = 'div'

const defaultSizeVariant: SelectSize = 'md'

const sizeVariants: {
  [key in Exclude<SelectSize, typeof defaultSizeVariant>]: string
} = {
  sm: 'select--size-sm',
}

const SelectContext = createContext<SelectContextState>(
  {} as SelectContextState
)

export const Select = <E extends ElementType = typeof defaultElement>({
  as,
  className,
  size = defaultSizeVariant,
  error = false,
  disabled = false,
  id: providedId,
  filled = false,
  ...props
}: SelectProps<E>) => {
  const element: React.ElementType = as || defaultElement

  const generatedId = useId()
  const [helperTextId, setHelperTextId] = useState<string>()

  const context = useMemo<SelectContextState>(
    () => ({
      disabled,
      id: providedId || generatedId,
      helperTextId,
      setHelperTextId,
      error,
    }),
    [disabled, providedId, helperTextId]
  )

  return (
    <SelectContext.Provider value={context}>
      <Box
        as={element}
        className={classNames(
          'select',
          size !== defaultSizeVariant && sizeVariants[size],
          error && 'select--error',
          disabled && 'select--disabled',
          filled && 'select--filled',
          className
        )}
        {...props}
      />
    </SelectContext.Provider>
  )
}

export const useSelectContext = () => useContext(SelectContext)
