import { Fragment } from 'react'
import classNames from 'classnames'
import { ShopifySectionComponent } from '@/types/ShopifySectionComponent'
import { Button, Image, Text, useMediaQuery, Wrapper } from '@lounge-fe/ui-kit'

import { HotspotProps } from './types'

import { ProductActionSheet } from './ProductActionSheet'
import { ProductPopover } from './ProductPopover'
import { Content } from './Content'

const imageCountClassNames = [
  '',
  'aspect-square md:aspect-[2/1] col-span-2 row-span-3 md:col-span-2 md:row-span-2',
  'col-span-1 row-span-3 aspect-[0.77/1] md:col-span-1 md:row-span-3 md:aspect-[1/1]',
  [
    'col-span-2 row-span-2 aspect-[1.27/1] md:col-span-1 md:row-span-3 md:aspect-[1/1]',
    'col-span-1 row-span-1 aspect-[0.77/1] md:col-span-1 md:row-span-2 md:aspect-[1.73/1]',
    'col-span-1 row-span-1 aspect-[0.77/1] md:col-span-1 md:row-span-1 md:aspect-[2.49/1]',
  ],
]

export const Hotspots: ShopifySectionComponent<HotspotProps> = ({ data }) => {
  const isDesktop = useMediaQuery(({ screens }) => `(min-width: ${screens.md})`)

  const {
    background,
    images,
    caption,
    heading,
    body,
    bodySize,
    buttonLabel,
    buttonLink,
    buttonLink2,
    buttonLabel2,
    headingSize,
    contained = true,
    actionSheetText,
    actionSheetButtonText,
    spacer,
    contentPosition,
  } = data!

  const validImages = images.filter((data) => Boolean(data.image))

  const hasContent = caption || heading || body

  const className = classNames(
    'grid grid-cols-2 gap-2 relative',
    contentPosition === 'left' && 'md:order-2',
    hasContent ? 'md:basis-3/5' : 'md:basis-full'
  )

  return (
    <div className={classNames(spacer)}>
      <Wrapper
        size={contained ? 'md' : 'full'}
        className="flex flex-col md:flex-row md:gap-2"
      >
        <div className={className}>
          {validImages.map((data, idx) => {
            const imageClasses = imageCountClassNames[validImages.length]
            return (
              <Fragment key={idx}>
                <div
                  className={classNames(
                    'relative h-full w-full',
                    Array.isArray(imageClasses)
                      ? imageClasses[idx]
                      : imageClasses
                  )}
                >
                  <Image
                    className="md:absolute w-full h-full inset-0 object-center object-cover"
                    data={{
                      src: data.image.src,
                      width: contained ? 700 : 1152,
                      height: contained ? 350 : 576,
                      crop: 'center',
                    }}
                    alt={data.image.alt}
                    loading="lazy"
                  />
                  {data.hotspots.map((hotspot, idx) =>
                    hotspot.product.url ? (
                      <Fragment key={idx}>
                        {isDesktop ? (
                          <ProductPopover
                            hotspot={{
                              ...hotspot,
                              xPosition: hotspot.xPosition,
                              yPosition: hotspot.yPosition,
                            }}
                            idx={idx}
                          />
                        ) : (
                          <ProductActionSheet
                            hotspot={{
                              ...hotspot,
                              xPosition: hotspot.mobileXPosition,
                              yPosition: hotspot.mobileYPosition,
                            }}
                            idx={idx}
                            actionSheetText={actionSheetText}
                            actionSheetButtonText={actionSheetButtonText}
                          />
                        )}
                      </Fragment>
                    ) : null
                  )}
                </div>
              </Fragment>
            )
          })}
        </div>
        {hasContent ? (
          <Content
            className={background}
            caption={caption}
            heading={heading}
            headingClassname={headingSize}
            body={body}
            bodyClassname={bodySize}
            buttons={[
              { label: buttonLabel, link: buttonLink },
              { label: buttonLabel2, link: buttonLink2 },
            ]}
          />
        ) : null}
      </Wrapper>
    </div>
  )
}

export default Hotspots
