import { ElementType } from 'react'
import classNames from 'classnames'

import { Box, TextProps } from '../../components'

const defaultElement = 'p'

export const Title = <C extends ElementType = typeof defaultElement>({
  className,
  as,
  ...props
}: TextProps<C>) => {
  const element: React.ElementType = as || defaultElement
  return (
    <Box
      as={element}
      className={classNames('tile__title', className)}
      {...props}
    />
  )
}
