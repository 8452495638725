// https://css-tricks.com/converting-color-spaces-in-javascript/#aa-hex-to-hsl
export const hexToHSL = (hex: string) => {
  let red = 0
  let green = 0
  let blue = 0
  if (hex.length === 4) {
    red = Number(`0x${hex[1]}${hex[1]}`)
    green = Number(`0x${hex[2]}${hex[2]}`)
    blue = Number(`0x${hex[3]}${hex[3]}`)
  } else if (hex.length === 7) {
    red = Number(`0x${hex[1]}${hex[2]}`)
    green = Number(`0x${hex[3]}${hex[4]}`)
    blue = Number(`0x${hex[5]}${hex[6]}`)
  }

  // Then to HSL
  red /= 255
  green /= 255
  blue /= 255
  const cmin = Math.min(red, green, blue)
  const cmax = Math.max(red, green, blue)
  const delta = cmax - cmin
  let hue = 0
  let saturation = 0
  let lightness = 0

  if (delta === 0) hue = 0
  else if (cmax === red) hue = ((green - blue) / delta) % 6
  else if (cmax === green) hue = (blue - red) / delta + 2
  else hue = (red - green) / delta + 4

  hue = Math.round(hue * 60)

  if (hue < 0) hue += 360

  lightness = (cmax + cmin) / 2
  saturation = delta === 0 ? 0 : delta / (1 - Math.abs(2 * lightness - 1))
  saturation = Number((saturation * 100).toFixed(1))
  lightness = Number((lightness * 100).toFixed(1))

  return {
    hue,
    saturation,
    lightness,
    color: `hsl(${hue},${saturation}%,${lightness}%)`,
  }
}
