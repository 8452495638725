import { createCompoundComponent } from '@lounge-fe/ui-kit'

import { Navigation as BaseNavigation } from './Navigation'
import { Item } from './Item'
import { SubMenu } from './SubMenu'
import { SubMenuLink } from './SubMenuLink'
import { Navigation as DesktopNavigation } from './Desktop'
import { Navigation as MobileNavigation } from './Mobile'

export const Navigation = createCompoundComponent(BaseNavigation, {
  Item,
  SubMenu,
  SubMenuLink,
  Desktop: DesktopNavigation,
  Mobile: MobileNavigation,
})
