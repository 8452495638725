import React from 'react'

import { SvgProps } from '../../types'
import { Icon } from '../../Icon'

import { PaymentProviderSvgProps } from './types'

export const Amex: React.FC<PaymentProviderSvgProps> = ({
  borderColor = 'currentColor',
  foregroundColor = 'currentColor',
  fill = 'none',
  ...props
}) => {
  return (
    <Icon viewBox="0 0 35.19 22.225" width="40" {...props}>
      <path
        d="M32.147.265h.264a2.514 2.514 0 0 1 2.514 2.513v16.669a2.514 2.514 0 0 1-2.514 2.513H2.778a2.514 2.514 0 0 1-2.513-2.513V2.778A2.514 2.514 0 0 1 2.778.265Z"
        fill={fill}
        stroke={borderColor}
        strokeWidth=".52916"
      />
      <path
        d="m7.25 7.673-3.017 6.606h3.61l.448-1.053h1.023l.448 1.053h3.974v-.804l.354.804h2.056l.354-.82v.82h8.265l1.005-1.026.941 1.026 4.245.008-3.025-3.293 3.025-3.321h-4.18L25.8 8.68l-.912-1.007h-8.991l-.772 1.705-.79-1.705H10.73v.776l-.401-.776H7.25zm.698.938h1.76l2 4.48V8.61h1.928l1.545 3.212 1.424-3.212h1.918v4.74h-1.167l-.01-3.714-1.701 3.714h-1.044L12.89 9.637v3.714h-2.402l-.455-1.062h-2.46L7.12 13.35H5.833l2.115-4.739zm11.643 0h4.747l1.451 1.552 1.499-1.552h1.451l-2.205 2.382 2.205 2.355h-1.517l-1.452-1.57-1.506 1.57h-4.673Zm-10.787.802-.81 1.893h1.62zm11.96.18v.865h2.589v.964h-2.59v.945h2.905l1.35-1.391-1.293-1.384h-2.962Z"
        fill={foregroundColor}
      />
    </Icon>
  )
}
