import classNames from 'classnames'

import { Box } from '../Box'

import { SvgProps } from './types'

export const Icon = ({
  className,
  fill = 'currentColor',
  spin,
  ...props
}: SvgProps) => (
  <Box
    as="svg"
    xmlns="http://www.w3.org/2000/svg"
    width="20px"
    className={classNames('icon', { 'icon--spin': spin }, className)}
    fill={fill}
    {...props}
  />
)
