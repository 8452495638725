import React from 'react'

import { Icon } from '../../Icon'

import { PaymentProviderSvgProps } from './types'

export const Mastercard: React.FC<PaymentProviderSvgProps> = ({
  borderColor = 'currentColor',
  foregroundColor = 'currentColor',
  fill = 'none',
  ...props
}) => {
  return (
    <Icon viewBox="0 0 35.19 22.225" width="40" {...props}>
      <path
        d="M32.147.265h.264a2.514 2.514 0 0 1 2.514 2.513v16.669a2.514 2.514 0 0 1-2.514 2.513H2.778a2.514 2.514 0 0 1-2.513-2.513V2.778A2.514 2.514 0 0 1 2.778.265Z"
        fill={fill}
        stroke={borderColor}
        strokeWidth=".52916"
      />
      <path d="M20.732 5.705h-6.01V16.52h6.01z" fill="currentColor" />
      <path
        d="M15.104 11.112a6.902 6.902 0 0 1 2.614-5.407 6.818 6.818 0 0 0-4.236-1.472 6.87 6.87 0 0 0-6.867 6.88 6.87 6.87 0 0 0 6.867 6.879 6.818 6.818 0 0 0 4.236-1.472 6.873 6.873 0 0 1-2.614-5.408Z"
        fill="currentColor"
      />
      <path
        d="M28.84 11.112a6.87 6.87 0 0 1-6.868 6.88 6.818 6.818 0 0 1-4.235-1.472 6.845 6.845 0 0 0 2.613-5.408 6.902 6.902 0 0 0-2.613-5.407 6.818 6.818 0 0 1 4.235-1.472c3.796 0 6.868 3.096 6.868 6.88Z"
        fill="#c8c8c8"
      />
    </Icon>
  )
}
