import { createCompoundComponent } from '../../utilities'

import { Card as BaseCard } from './Card'
import { Image } from './Image'
import { Content } from './Content'

export const Card = createCompoundComponent(BaseCard, {
  Content,
  Image,
})
