import { createCompoundComponent } from '@lounge-fe/ui-kit'
import { AuthFormLayout as RootAuthFormLayout } from './AuthFormLayout'
import { FormContainer } from './FormContainer'
import { Title } from './Title'
import { Subtitle } from './Subtitle'
import { Media } from './Media'

export const AuthFormLayout = createCompoundComponent(RootAuthFormLayout, {
  FormContainer,
  Media,
  Title,
  Subtitle,
})
