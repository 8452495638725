import { useFormContext } from 'react-hook-form'

import { Button } from '../../Button'

export const Submit = ({ ...props }) => {
  const {
    formState: { isSubmitting, isValid },
  } = useFormContext()
  return (
    <Button
      type="submit"
      isLoading={isSubmitting}
      disabled={isSubmitting || !isValid}
      {...props}
    />
  )
}
