import { Controller } from 'react-hook-form'

import { Checkbox as BaseCheckbox } from '../../Checkbox'

import { CheckboxProps } from './types'

export const Checkbox = ({ name, ...props }: CheckboxProps): JSX.Element => (
  <Controller
    name={name}
    render={({ field }) => (
      <BaseCheckbox
        {...field}
        checked={field.value ?? ''}
        value={undefined}
        {...props}
      />
    )}
  />
)
