import classNames from 'classnames'

import { Box, Link } from '@lounge-fe/ui-kit'

import { SubMenuLinkProps, SubMenuLinkVariant } from './types'

const variantClasses: { [key in SubMenuLinkVariant]: string } = {
  default: 'no-underline font-regular',
  offer: 'no-underline font-medium',
  underline: 'underline font-medium text-action-primary-default',
}

export const SubMenuLink = ({
  children,
  variant = 'default',
  tabIndex,
  ...props
}: SubMenuLinkProps) => (
  <Box as="li" className="py-1">
    <Link
      tabIndex={tabIndex}
      variant="body-sm"
      className={classNames('w-full text-[#282828]', variantClasses[variant])}
      {...props}
    >
      {children}
    </Link>
  </Box>
)
