import { useState, useRef, useMemo } from 'react'
import algoliasearch from 'algoliasearch/lite'
import { SuggestedTermHit, UseSearchOptions } from './types'
import {
  useQuery,
  useInfiniteQuery,
  keepPreviousData,
} from '@tanstack/react-query'
import { captureException } from '@sentry/react'
import { useShopify } from '../../../../hooks/useShopify'
import { useAlgoliaDataExtension } from '@/features/product/hooks/useAlgoliaDataExtension/useAlgoliaDataExtension'
import { ProductHit } from '@/features/product/types'

const useSearch = ({ suggestedSearchTermFallbacks = [] }: UseSearchOptions) => {
  const { integrations } = useShopify()
  const { indexPrefix, appId, apiKey } = integrations.algolia

  const [isSearchOpen, setIsSearchOpen] = useState(false)
  const [query, setQuery] = useState('')

  const searchClient = useRef(algoliasearch(appId, apiKey))
  const productsIndex = useRef(
    searchClient.current.initIndex(`${indexPrefix}_products`)
  )
  const recommendationsIndex = useRef(
    searchClient.current.initIndex(`${indexPrefix}_products-suggestions`)
  )

  const {
    data: results,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: ['search', query],
    queryFn: ({ pageParam }) =>
      productsIndex.current.search<ProductHit>(query, {
        page: pageParam,
        hitsPerPage: 40,
        distinct: true,
        clickAnalytics: true,
        filters:
          '(NOT product_type:"Set Bottoms" AND NOT product_type:"Intimates Set Bottoms" AND NOT product_type:"Intimates Set Skirt" AND NOT product_type:"Swim Set Bottoms" AND NOT product_type:"Apparel Set Bottoms") AND inventory_available:true',
      }),
    enabled: Boolean(query),
    placeholderData: keepPreviousData,
    getNextPageParam: ({ page, nbPages }) =>
      page < nbPages - 1 ? page + 1 : undefined,
    initialPageParam: undefined as number | undefined,
  })

  const { data: suggestedSearchTerms = [] } = useQuery({
    queryKey: ['searchRecommendations'],
    staleTime: 1000 * 60 * 60, // 1 hour
    queryFn: async () => {
      try {
        const { hits } =
          await recommendationsIndex.current.search<SuggestedTermHit>('', {
            hitsPerPage: 5,
          })
        return hits.map((hit) => hit.query)
      } catch (error) {
        captureException(error)
        return suggestedSearchTermFallbacks
      }
    },
    enabled: Boolean(isSearchOpen),
    refetchOnWindowFocus: false,
    placeholderData: suggestedSearchTermFallbacks,
  })

  const onSearchSubmit = (query: string) => {
    const { history, location } = window
    if (query.length > 0) {
      const page = `${location.pathname}?q=${query}`
      history.pushState({ q: query }, '', page)
    } else {
      history.pushState({}, '', location.pathname)
    }
  }

  const onSearchChange = (query: string) => {
    setQuery(query)
  }

  const flattenedPages = useMemo(
    () => results?.pages.flatMap(({ hits }) => hits) ?? [],
    [results?.pages]
  )

  const hits = useAlgoliaDataExtension(flattenedPages, true)

  return {
    onSearchChange,
    onSearchSubmit,
    isSearchOpen,
    setIsSearchOpen,
    query,
    setQuery,
    hits,
    isLastPage: !hasNextPage,
    showMore: fetchNextPage,
    nbHits: results?.pages[0]?.nbHits || 0,
    suggestedSearchTerms,
  }
}

export { useSearch }
