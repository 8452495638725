export type GetDataTranslationsMetaobjectsResponse = {
  metaobjects: {
    nodes: {
      english: { value: string }
      localised: { value: string }
    }[]
  }
}

export const getDataTranslationsMetaobjects = `
  query getDataTranslationsMetaobjects {
    metaobjects(type: "data_translation", first: 250) {
      nodes {
        english: field(key: "english") {
          value
        }
        localised: field(key: "localised") {
          value
        }
      }
    }
  }
`
