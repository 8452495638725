import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Icon, IconButton, Modal, Text } from '@lounge-fe/ui-kit'
import { NewsletterSignupProps } from './types'
import * as z from 'zod'
import { SignupModal } from '@/components/SignupModal/SignupModal'

export const NewsletterSignup = ({
  data: {
    title,
    subtitle,
    modal_title,
    modal_success_title,
    modal_success_message,
    modal_success_promo,
    image,
  },
  children,
  ...props
}: NewsletterSignupProps) => {
  const [email, setEmail] = useState('')
  const [hasSignedUp, setHasSignedUp] = useState(false)

  const { t } = useTranslation()

  return (
    <div {...props}>
      <Text variant="title-four" className="mb-2 font-[500]">
        {title}
      </Text>
      <Text
        variant="body-sm"
        className="text-[#7D7D7D]"
        dangerouslySetInnerHTML={{ __html: subtitle }}
      />
      <SignupModal
        data={{
          title: title,
          subtitle: subtitle,
          modal_title: modal_title,
          modal_success_title: modal_success_title,
          modal_success_body: modal_success_message,
          modal_success_promo: modal_success_promo,
          image: {
            src: image?.src,
          },
        }}
        prefilledEmail={email}
      >
        <Form
          onSubmit={({ email }) => setEmail(email)}
          validationSchema={z.object({ email: z.string().email() })}
          className="mt-10"
        >
          {({ formState }) => (
            <Form.Input
              name="email"
              label={t('account.email_address')}
              onChangeCapture={() => {
                setHasSignedUp(false)
              }}
              endAdornment={
                <Modal.Trigger>
                  <IconButton
                    type="submit"
                    size="sm"
                    className="text-[#7D7D7D]"
                    aria-label={t('action.submit')}
                    disabled={!formState.isValid}
                    icon={<Icon.ChevronRight />}
                  />
                </Modal.Trigger>
              }
            />
          )}
        </Form>
      </SignupModal>
    </div>
  )
}
