import { Text } from '@lounge-fe/ui-kit'
import { BasketLineDiscountAllocationProps } from './types'
import { Money } from '@shopify/hydrogen-react'
import { Fragment } from 'react'
import { useShopify } from '@/hooks/useShopify'

export const DiscountAllocation = ({
  discountAllocation,
}: BasketLineDiscountAllocationProps) => {
  const { title } = discountAllocation.discount_application
  const { currency } = useShopify()

  return (
    <Text variant="body-sm" className="text-[#7D7D7D] text-right">
      {title} (
      <Money
        as={Fragment}
        data={{
          amount: ((discountAllocation.amount / 100) * -1).toString(),
          currencyCode: currency.active,
        }}
      />
      )
    </Text>
  )
}
