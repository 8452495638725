import { Icon, Modal, Text } from '@lounge-fe/ui-kit'
import { useCurrentStore } from '../../hooks/useCurrentStore'
import { TriggerProps } from './types'

export const Trigger = ({
  renderStore = (store) => store.shortName ?? store.name,
}: TriggerProps) => {
  const currentStore = useCurrentStore()
  return (
    <Modal.Trigger>
      <button className="align-self-start flex gap-x-3">
        <currentStore.icon className="w-4 h-4 rounded-circle border" />
        <Text as="span" variant="body-sm" className="font-medium">
          {renderStore(currentStore)}
        </Text>
        <Icon.ChevronDown className="w-[6px] h-[6px]" />
      </button>
    </Modal.Trigger>
  )
}
