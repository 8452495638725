import { Divider, Wrapper } from '@lounge-fe/ui-kit'
import type { ShopifySectionComponent } from '@/types/ShopifySectionComponent'
import { PaymentProviders } from './PaymentProviders'
import { Copyright } from './Copyright'
import { MainMenu } from './MainMenu'
import { FooterData } from './types'
import { PolicyMenu } from './PolicyMenu'
import { NewsletterSignup } from './NewsletterSignup'
import { SocialLinks } from './SocialLinks'
import { CountrySelector } from './CountrySelector/CountrySelector'

const Footer: ShopifySectionComponent<FooterData> = ({
  data: {
    main_menu = [],
    policy_menu = [],
    payment_providers,
    social_urls,
    newsletter,
  } = {},
}) => {
  return (
    <Wrapper size="fluid" className="pt-10 lg:pt-12">
      <div className="flex flex-col gap-x-4 gap-y-6 lg:flex-row lg:justify-between">
        {newsletter && (
          <NewsletterSignup
            className="lg:order-1 lg:basis-[432px]"
            data={newsletter}
          />
        )}
        <MainMenu menus={main_menu} />
      </div>
      <div className="flex flex-col-reverse gap-y-6 lg:flex-row lg:justify-between mt-8 lg:mt-12">
        {payment_providers && (
          <PaymentProviders paymentProviders={payment_providers} />
        )}
        <PolicyMenu menu={policy_menu} className="block lg:hidden" />
        {social_urls && <SocialLinks socialUrls={social_urls} />}
      </div>
      <Divider className="mt-4 !border-[#E7E7E7] hidden lg:block" />
      <div className="flex flex-wrap pt-6 pb-8 flex-col lg:flex-row lg:justify-between lg:items-center gap-x-4 gap-y-2">
        <div className="flex gap-4">
          <CountrySelector />
          <Copyright />
        </div>
        <PolicyMenu className="hidden lg:block" menu={policy_menu} />
      </div>
    </Wrapper>
  )
}

export default Footer
