import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useAuth } from '@lounge-fe/auth'
import { RestError } from '@lounge-fe/network'
import {
  Notification,
  Link,
  Form,
  Text,
  FormSubmitHandler,
} from '@lounge-fe/ui-kit'
import { login as loginSchema } from '@lounge-fe/validation/src/schema/auth'

import { useShopify } from '../../../../hooks/useShopify'
import { AuthFormLayout } from '../AuthFormLayout'
import { LoginFormLayoutProps, LoginFormProps, LoginFormValues } from './types'
import { useExponea } from '@/hooks/useExponea'

export const LoginForm = ({
  returnUrl,
  redirect,
  onSuccess,
}: LoginFormProps): JSX.Element => {
  const { t } = useTranslation()
  const { login } = useAuth()
  const [error, setError] = useState<string>()
  const { routes, shop } = useShopify()
  const { identify } = useExponea()

  const onSubmit: FormSubmitHandler<LoginFormValues> = async ({
    email,
    password,
  }) => {
    try {
      await login({
        email,
        password,
        redirect,
        callbackUrl: returnUrl,
        callbackFn: async () => {
          await identify(email, { domain: shop })
          if (onSuccess) await onSuccess()
        },
      })
    } catch (err) {
      let error = t('account.errors.unexpected')
      if (err instanceof RestError && err.status === 401) {
        error = t('account.errors.invalid_credentials')
      }
      setError(error)
    }
  }

  return (
    <Form
      className="grid gap-6 w-full"
      onSubmit={onSubmit}
      validationSchema={loginSchema}
    >
      <Form.Input label={t('account.email_address')} name="email" />
      <Form.Password label={t('account.password')} name="password" />
      <Link
        href={routes.account_forgot_password_url}
        className="underline text-sm text-center"
      >
        {t('account.login_form.forgot_password_link')}
      </Link>
      <Form.Submit type="submit">{t('account.login')}</Form.Submit>
      {error ? (
        <Notification severity="critical" title={`${t('general.error')}:`}>
          {error}
        </Notification>
      ) : (
        <Text as="span" className="text-center">
          {t('account.login_form.new_to_lounge')}
          &nbsp;
          <Link href={routes.account_register_url}>
            {t('account.login_form.register_link')}
          </Link>
        </Text>
      )}
    </Form>
  )
}

export const LoginFormLayout = ({
  title,
  subtitle,
  image,
  imageAlt,
  returnUrl,
  video,
}: LoginFormLayoutProps): JSX.Element => {
  return (
    <AuthFormLayout>
      <AuthFormLayout.Media image={image} imageAlt={imageAlt} video={video} />
      <AuthFormLayout.FormContainer>
        {title && <AuthFormLayout.Title>{title}</AuthFormLayout.Title>}
        {subtitle && (
          <AuthFormLayout.Subtitle>{subtitle}</AuthFormLayout.Subtitle>
        )}
        <LoginForm returnUrl={returnUrl} />
      </AuthFormLayout.FormContainer>
    </AuthFormLayout>
  )
}
