import { Tile, Link } from '@lounge-fe/ui-kit'

import { SearchTileProps } from './types'

export const SearchTile = ({ title, href, image }: SearchTileProps) => (
  <Link href={href} className="block no-underline">
    <Tile aspectRatio="7/6" contentPlacement="outside" size="md">
      <Tile.Image
        data={{ src: image.src, width: 345, height: 295, crop: 'center' }}
      />
      <Tile.Content>
        <Tile.Title as="h2" className="!text-sm">
          {title}
        </Tile.Title>
      </Tile.Content>
    </Tile>
  </Link>
)
