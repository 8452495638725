import type { Cart, ICartPlugin } from '../../types'
import CartPlugin from '../CartPlugin'

import { UnderwearCareBagPluginOptions } from './types'

export class UnderwearCareBagPlugin extends CartPlugin implements ICartPlugin {
  private productId: number
  private variantId: number
  private productPrice: number

  constructor({
    productId,
    variantId,
    productPrice,
    enabled,
  }: UnderwearCareBagPluginOptions) {
    super(
      Boolean(
        enabled &&
          typeof productId !== 'undefined' &&
          typeof variantId !== 'undefined' &&
          typeof productPrice !== 'undefined'
      )
    )
    this.productId = productId!
    this.variantId = variantId!
    this.productPrice = productPrice!
  }

  setUnderwearCareBag = (enabled: boolean): Promise<Cart> =>
    this.manager.update({
      updates: { [this.variantId]: enabled ? 1 : 0 },
    })

  addUnderwearCareBag = (): Promise<Cart> =>
    this.manager.addItem({
      id: this.variantId,
      quantity: 1,
    })

  removeUnderwearCareBag = (): Promise<Cart> =>
    this.manager.removeItem(this.variantId)

  onClear = (cart: Readonly<Cart>): Cart => {
    cart.meta.underwearCareBag = false
    return cart
  }

  getPrice = (): number => this.productPrice

  onFormatCart = (cart: Cart): Cart => {
    const newCart = { ...cart }
    newCart.meta.underwearCareBag = Boolean(
      newCart.items.find(({ productId }) => productId === this.productId)
    )

    return newCart
  }
}
