import './i18n'
import './web-components'

import React from 'react'
import { createRoot } from 'react-dom/client'

import { App } from './App'

const initaliseApp = () => {
  const domNode = document.getElementById('app-root')

  if (!domNode) {
    return
  }

  const root = createRoot(domNode!)

  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  )

  return root
}

let root = initaliseApp()

document.addEventListener('shopify:section:load', () => {
  root?.unmount()
  root = initaliseApp()
})
