import { Wizard as RootWizard } from './Wizard'
import { Progress } from './Progress'
import { Step } from './Step'
import { Steps } from './Steps'
import { Next } from './Next'
import { Back } from './Back'

export { useWizardContext } from './Wizard'

export const Wizard = Object.assign(RootWizard, {
  Step,
  Steps,
  Progress,
  Next,
  Back,
})
