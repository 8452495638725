import { SvgProps } from '../types'
import { Icon } from '../Icon'

export const Bra: React.FC<SvgProps> = (props) => {
  return (
    <Icon width="64" height="64" viewBox="0 0 64 64" fill="none" {...props}>
      <circle cx="32" cy="32" r="31.5" stroke="currentColor" />
      <path
        d="M32.0712 42.9549C27.2495 44.0656 19.9363 46.6655 16.5021 42.9439C16.2822 41.8143 16.0973 40.5787 16.0286 39.5823C15.995 39.0948 16.1472 38.6142 16.4087 38.2015C22.1344 29.1654 20.787 23.6364 20.3151 16C20.3924 16.8051 21.0303 25.5881 21.8666 28.3167C22.2985 29.7203 28.1779 32.62 29.9378 36.4684C31.8097 40.5618 31.5085 42.1305 32.0712 42.9549Z"
        stroke="currentColor"
        strokeMiterlimit="10"
      />
      <path
        d="M31.9289 43.198C36.7505 44.3069 44.0637 46.9024 47.4979 43.187C47.7176 42.0602 47.9024 40.8276 47.9712 39.8333C48.005 39.3452 47.8524 38.864 47.5903 38.4509C41.8658 29.4308 43.2131 23.9113 43.6849 16.2881C43.6076 17.0919 42.9697 25.8601 42.1334 28.5842C41.7015 29.9855 35.8221 32.8803 34.0622 36.7223C32.1903 40.8089 32.4916 42.3749 31.9289 43.198Z"
        stroke="currentColor"
        strokeMiterlimit="10"
      />
      <path
        d="M47.7159 42.2344C47.4163 45.2806 46.0989 47.6544 43.3629 47.9327C40.8321 48.1916 38.2822 47.1896 35.8211 46.646C32.7828 45.9802 29.8512 46.2536 26.8553 46.9982C24.5693 47.5676 21.9154 48.4972 19.5939 47.6754C17.505 46.9403 16.6596 44.6099 16.427 42.2344C19.0262 45.4655 22.8785 45.0168 26.2629 44.2464C27.9839 43.854 29.7007 43.3313 31.4504 43.1383C33.1795 42.9485 34.9853 43.5403 36.6707 43.9424C38.6214 44.4008 40.6146 44.8897 42.6146 44.7836C43.5633 44.75 44.5001 44.5265 45.3848 44.1226C45.8746 43.8813 47.6461 42.9453 47.7159 42.2344Z"
        stroke="currentColor"
        strokeMiterlimit="10"
      />
    </Icon>
  )
}
