import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Text, Select, QuantityControls } from '@lounge-fe/ui-kit'

import { useRemoveCartItems } from '@/features/cart/hooks/useRemoveCartItems'
import { useUpdateCart } from '@/features/cart/hooks/useUpdateCart'
import { BasketLineQuantityProps } from './types'

const MAX_QUANTITY = 20

export const Quantity = ({
  isDesktop,
  quantity,
  isSet,
  lineItemKeys,
}: BasketLineQuantityProps) => {
  const { t } = useTranslation()
  const removeItems = useRemoveCartItems()
  const update = useUpdateCart()
  const [isUpdating, setIsUpdating] = useState(false)

  const onQuantityUpdate = (value: number) => {
    setIsUpdating(true)
    update.mutate(
      {
        updates: Object.fromEntries(
          lineItemKeys.flatMap((key) =>
            Array.isArray(key)
              ? key.map((k, index) => (index === 0 ? [k, value] : [k, 0]))
              : [[key, value]]
          )
        ),
      },
      {
        onSettled: () => {
          setIsUpdating(false)
        },
      }
    )
  }

  if (isDesktop) {
    return (
      <QuantityControls
        value={quantity}
        renderValue={(value) => (
          <Text variant="body-sm">
            {isSet
              ? t('cart.setWithCount', { count: value })
              : t('cart.quantityWithCount_abbr', {
                  count: value,
                }).toUpperCase()}
          </Text>
        )}
        onChange={onQuantityUpdate}
        isLoading={update.isPending}
        disabled={removeItems.isPending || isUpdating}
        max={MAX_QUANTITY}
      />
    )
  }

  return (
    <div className="flex items-center">
      <Text variant="body-sm">
        {isSet
          ? t('cart.set', { count: quantity })
          : t('cart.quantity_abbr').toUpperCase()}
        :
      </Text>
      <Select>
        <Select.Menu
          value={quantity.toString(10)}
          onChange={(evt) => onQuantityUpdate(Number(evt.currentTarget.value))}
          className="!py-0 !h-6 !border-0"
        >
          {[...Array(MAX_QUANTITY + 1)].map((_, idx) => (
            <Select.Option key={idx} value={idx}>
              {idx}
            </Select.Option>
          ))}
        </Select.Menu>
      </Select>
    </div>
  )
}
