import { createCompoundComponent } from '../../utilities'

import { Popover as BasePopover } from './Popover'
import { Trigger } from './Trigger'
import { Content } from './Content'
import { Backdrop } from './Backdrop'
import { Group } from './Group'

export const Popover = createCompoundComponent(BasePopover, {
  Trigger,
  Content,
  Backdrop,
  Group,
})

export { usePopoverContext } from './Popover'
export { usePopoverStore } from './store'

export type {
  PopoverProps,
  ContentProps as PopoverContentProps,
  TriggerProps as PopoverTriggerProps,
  PopoverBag,
} from './types'
