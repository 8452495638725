import React from 'react'

import { Icon } from '../../Icon'

import { PaymentProviderSvgProps } from './types'

export const Paypal: React.FC<PaymentProviderSvgProps> = ({
  borderColor = 'currentColor',
  foregroundColor = 'currentColor',
  fill = 'none',
  ...props
}) => {
  return (
    <Icon viewBox="0 0 35.19 22.225" width="40" {...props}>
      <path
        d="M32.147.265h.264a2.514 2.514 0 0 1 2.514 2.513v16.669a2.514 2.514 0 0 1-2.514 2.513H2.778a2.514 2.514 0 0 1-2.513-2.513V2.778A2.514 2.514 0 0 1 2.778.265Z"
        fill={fill}
        stroke={borderColor}
        strokeWidth=".52916"
      />
      <path
        d="M16.617 7.472h3.896c2.092 0 2.88 1.055 2.758 2.604-.2 2.559-1.754 3.974-3.814 3.974h-1.04c-.282 0-.472.186-.549.691l-.441 2.935c-.03.19-.13.3-.28.316h-2.449c-.23 0-.312-.176-.251-.555l1.492-9.41c.059-.376.266-.555.678-.555z"
        clipRule="evenodd"
        fill="#c8c8c8"
        fillRule="evenodd"
      />
      <path
        d="M14.18 4.233h3.9c1.099 0 2.402.035 3.273.802.583.512.888 1.326.818 2.203-.24 2.966-2.02 4.628-4.41 4.628h-1.923c-.328 0-.545.217-.637.802l-.537 3.405c-.035.22-.131.351-.302.367h-2.407c-.266 0-.36-.2-.291-.643l1.73-10.917c.07-.438.311-.647.786-.647Z"
        clipRule="evenodd"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        d="m15.257 12.322.681-4.294c.06-.377.267-.557.68-.557h3.895c.645 0 1.167.1 1.575.285-.39 2.64-2.106 4.107-4.35 4.107h-1.92c-.258.001-.447.13-.56.459z"
        clipRule="evenodd"
        fill="currentColor"
        fillRule="evenodd"
      />
    </Icon>
  )
}
