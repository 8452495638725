import {
  Hotspot,
  Image,
  Popover,
  Text,
  Icon,
  Transition,
} from '@lounge-fe/ui-kit'
import { useShopify } from '@/hooks/useShopify'
import { Money } from '@shopify/hydrogen-react'
import { ProductPopoverProps } from './types'
import { Fragment } from 'react'

export const ProductPopover: React.FC<ProductPopoverProps> = ({
  hotspot,
  idx,
}) => {
  const { currency } = useShopify()

  const offset = {
    top: hotspot.yPosition + '%',
    left: hotspot.xPosition + '%',
  }

  return (
    <Popover scrollLock={false}>
      {({ onClose }) => (
        <>
          <Popover.Trigger enableHoverTrigger>
            <Hotspot style={{ ...offset }} />
          </Popover.Trigger>
          <Transition.Appear
            className="absolute"
            style={{ ...offset }}
            onMouseLeave={onClose}
          >
            <Popover.Content
              onClickAway={onClose}
              className="z-20 bg-white rounded-sm p-6 shadow-4 left-0 gap-4 mt-12 flex relative w-[410px]"
            >
              <a href={hotspot.product.url} key={idx} className="w-full">
                <div className="flex gap-x-4">
                  {hotspot.product.images?.[0] && (
                    <Image
                      data={{
                        src: hotspot.product.images[0].src,
                        width: 96,
                        height: 132,
                        crop: 'center',
                      }}
                      className="object-cover aspect-[0.73/1] w-[96px]"
                      alt={hotspot.product.images[0].alt}
                      loading="lazy"
                      key={idx}
                    />
                  )}
                  <div className="flex-1">
                    <Text variant="body-lg">{hotspot.product.title}</Text>
                    <Text variant="body-lg" className="pt-4">
                      {hotspot.product.compare_at_price ? (
                        <Text
                          as="span"
                          variant="body-lg"
                          className="line-through text-[#c8c8c8] pr-2"
                        >
                          <Money
                            as={Fragment}
                            data={{
                              amount: (
                                hotspot.product.compare_at_price / 100
                              ).toString(),
                              currencyCode: currency.active,
                            }}
                          />
                        </Text>
                      ) : null}
                      <Text as="span" variant="body-lg" className="font-medium">
                        <Money
                          as={Fragment}
                          data={{
                            amount: (hotspot.product.price / 100).toString(),
                            currencyCode: currency.active,
                          }}
                        />
                      </Text>
                    </Text>
                  </div>
                  <div className="w-4 h-4 ml-6 -rotate-90">
                    <Icon.ChevronRight className="rotate-90 w-4 h-4"></Icon.ChevronRight>
                  </div>
                </div>
              </a>
            </Popover.Content>
          </Transition.Appear>
        </>
      )}
    </Popover>
  )
}
