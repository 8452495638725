import { base64 } from '../../../../utils/base64'

import { ICartPlugin, Item } from '../../types'
import CartPlugin from '../CartPlugin'

import { SetsPluginOptions } from './types'

const mergeCartItems = (_items: Item[]) => {
  return _items.reduce(
    (prev, curr) => ({
      ...prev,
      ...curr,
      finalLinePrice: prev.finalLinePrice + curr.finalLinePrice,
      originalLinePrice: prev.originalLinePrice + curr.originalLinePrice,
      key: Array.from(
        new Set([
          ...(Array.isArray(prev.key) ? prev.key : [prev.key]),
          ...(Array.isArray(curr.key) ? curr.key : [curr.key]),
        ])
      ),
      quantity: prev.quantity + curr.quantity,
      discountAllocations: [
        ...(prev.discountAllocations ?? []),
        ...(curr.discountAllocations ?? []),
      ],
      collections: Array.from(
        new Set([...(prev.collections ?? []), ...(curr.collections ?? [])])
      ),
    }),
    {
      title: '',
      color: '',
      size: '',
      url: '',
      featuredImage: null,
      hasOnlyDefaultVariant: false,
      id: 0,
      productId: 0,
      finalPrice: 0,
      finalLinePrice: 0,
      originalLinePrice: 0,
      quantity: 0,
      discountAllocations: [],
      key: [],
      isFreeItem: false,
      collections: [],
    } as Item
  )
}

export class SetsPlugin extends CartPlugin implements ICartPlugin {
  constructor({ enabled }: SetsPluginOptions = {}) {
    super(enabled)
  }

  groupCartBySets = (items: Item[]) => {
    const shouldSplitItem = (item: Item) => {
      return Boolean(item.properties?.__shopify_send_gift_card_to_recipient)
    }

    const groupedLines: Record<string | number, Item[]> = items.reduce(
      (prev: Record<string | number, Item[]>, curr) => {
        if (curr.properties?._setID) {
          const key = curr.properties._setID
          return {
            ...prev,
            [key]: [...(prev[key] ?? []), curr],
          }
        } else if (shouldSplitItem(curr)) {
          const key = Array.isArray(curr.key) ? curr.key[0] : curr.key
          return {
            ...prev,
            [key]: [...(prev[key] ?? []), curr],
          }
        } else {
          const key = curr.id
          return {
            ...prev,
            [key]: [...(prev[key] ?? []), curr],
          }
        }
      },
      {}
    )

    return Object.keys(groupedLines).map((key) => {
      let items = groupedLines[key]

      // There can be multiple cart items with for the same variants.
      if (items.some((item) => Boolean(item.properties?._setID))) {
        try {
          const groupVariantIds = base64.decode<number[]>(key)
          items = groupVariantIds
            .map((id) => {
              const matchingCartItems = items.filter((item) => item.id === id)
              return mergeCartItems(matchingCartItems)
            })
            .filter(Boolean)
        } catch (error) {
          items = [mergeCartItems(items)]
        }
      } else {
        items = [mergeCartItems(items)]
      }

      return {
        id: key,
        items,
        isSet: items.length > 1,
        finalLinePrice: items.reduce(
          (prev, item) => prev + item.finalLinePrice,
          0
        ),
        originalLinePrice: items.reduce(
          (prev, item) => prev + item.originalLinePrice,
          0
        ),
        setCount: items.reduce(
          (prev, item) => Math.max(prev, item.quantity),
          0
        ),
      }
    })
  }
}
