import classNames from 'classnames'
import useEmblaCarousel, { EmblaCarouselType } from 'embla-carousel-react'
import { useEffect, forwardRef, useImperativeHandle } from 'react'

import { Carousel } from '../../../components/Carousel'
import { Image } from '../../../components/Image'
import { ImageCarouselProps } from '../types'
import { useProductCardContext } from '../ProductCard'

import { ArrowLeft, ArrowRight } from './Arrow'

export const ImageCarousel = forwardRef<
  EmblaCarouselType | undefined,
  ImageCarouselProps
>(({ children, className, images = [], ...props }, ref) => {
  const { href } = useProductCardContext()
  const [emblaRef, embla] = useEmblaCarousel(
    {
      align: 'center',
      containScroll: 'trimSnaps',
      skipSnaps: true,
      loop: true,
      active: images.length > 1,
    },
    []
  )

  useImperativeHandle(ref, () => embla)

  useEffect(() => embla?.reInit(), [images.length])

  return (
    <a
      href={href}
      className={classNames(
        'product-card__image group',
        className,
        'aspect-[5/7]'
      )}
      draggable={false}
      {...props}
    >
      {images.length > 0 && (
        <Carousel emblaRef={emblaRef} embla={embla}>
          <ArrowLeft />
          <Carousel.Viewport>
            <Carousel.Container className="auto-cols-[100%]">
              {images.map((image, index) => (
                <Carousel.Slide key={index} index={index} lazyload>
                  <Image {...image} />
                </Carousel.Slide>
              ))}
            </Carousel.Container>
          </Carousel.Viewport>
          <ArrowRight />
        </Carousel>
      )}
    </a>
  )
})

ImageCarousel.displayName = 'ImageCarousel'
