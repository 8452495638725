import { CollectionProductsProps } from './types'
import { StandardProductCard } from '@/features/product/components/StandardProductCard'
import { createLiquidExtendedProductHit } from '@/features/product/utils/createExtendedProductHit'
import classNames from 'classnames'

export const CollectionProducts = ({ products }: CollectionProductsProps) => {
  // Determine the number of columns based on the total number of products
  const columns =
    products.length % 2 === 0 ? 'grid-cols-2' : 'grid-cols-2 md:grid-cols-3'

  return (
    <div className={classNames('grid gap-4', columns)}>
      {products.map((product) => (
        <StandardProductCard
          key={product.id}
          product={createLiquidExtendedProductHit(product)}
          useQuickAdd
          showWishlist={false}
        />
      ))}
    </div>
  )
}
