import React from 'react'

import { SvgProps } from '../types'
import { Icon } from '../Icon'

export const Loading: React.FC<SvgProps> = (props) => {
  return (
    <Icon fillRule="evenodd" clipRule="evenodd" viewBox="0 0 24 24" {...props}>
      <path
        d="M0.490163 11.9902C0.214021 11.9902 -0.0109093 12.2147 0.000409281 12.4906C0.262763 18.8859 5.53039 23.9902 11.9902 23.9902C18.6176 23.9902 23.9902 18.6176 23.9902 11.9902C23.9902 5.53039 18.8859 0.262763 12.4906 0.000409281C12.2147 -0.0109093 11.9902 0.214021 11.9902 0.490163C11.9902 0.766306 12.2145 0.988988 12.4904 1.00133C18.3333 1.26284 22.9902 6.08267 22.9902 11.9902C22.9902 18.0653 18.0653 22.9902 11.9902 22.9902C6.08267 22.9902 1.26284 18.3333 1.00133 12.4904C0.988988 12.2145 0.766306 11.9902 0.490163 11.9902Z"
        fill="currentColor"
      />
    </Icon>
  )
}
