import * as Sentry from '@sentry/react'
import * as React from 'react'
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'

import { Auth, AuthProvider } from '@lounge-fe/auth'

import { Header } from './features/header'
import Footer from './features/footer/components/Footer'
import { AnnouncementBar } from './features/header/components/AnnouncementBar'
import {
  EntryPoint,
  ProductCarousel,
  ShopifyContext,
  VideoSection,
  Hotspots,
  CaptionedImage,
  TextAccordion,
  MediaContentSlider,
  PromoBanner,
} from './components'
import { ToastProvider } from '@lounge-fe/ui-kit'
import { AjaxApiCartAdapter, CartManager, CartProvider } from './features/cart'
import { Posts } from './features/blog/Posts'
import { Cards } from './features/careers/components/FeaturedRoles/FeaturedRoles'
import { Tips } from './features/careers/components/TipsHints/TipsHints'
import { RelatedPosts } from './features/blog/RelatedPosts'
import { SetsPlugin } from './features/cart/plugins/SetsPlugin'
import {
  CountryCode,
  LanguageCode,
} from '@shopify/hydrogen-react/storefront-api-types'
import { GiftWrapPlugin, UnderwearCareBagPlugin } from './features/cart/plugins'
import { User } from '@lounge-fe/auth/src/vanilla/types'
import { ModalGroup } from './components/ModalGroup/ModalGroup'
import { SearchInsights } from './components/SearchInsights'
import { useSearchInsights } from './hooks/useSearchInsights'
import { parseGID } from './utils'
import { CardCarousel } from './components/CardCarousel/CardCarousel'
import { StoreRedirectBanner } from './features/localisation/components/StoreRedirectBanner'
import { Countdown } from './components/Countdown/Countdown'
import { ContentCarousel } from './components/ContentCarousel'
import { ImageCarousel } from './components/ImageCarousel'

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: window.Lounge.integrations.sentry_dsn,
    environment: window.Shopify.shop,
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: 0.1,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
    ignoreErrors: ['cdn/wpm'],
    denyUrls: [
      // Facebook
      /graph\.facebook\.com/i,
      /connect\.facebook\.net/i,
      // OneTrust
      /cdn\.cookielaw.org/i,
      // Extensions
      /extensions\//i,
      /^chrome:\/\//i,
      /^chrome-extension:\/\//i,
      /^safari-web-extension:\/\//i,
      /\/cdn\/wpm\/.*\.js/i,
    ],
  })
}

const auth = new Auth({
  baseURL: window.Lounge.integrations.api_base_url,
  shopifyStore: window.Lounge.shop_name,
})

const { gift_wrap } = window.Lounge.settings
const { underwear_care_bag } = window.Lounge.settings

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error) => {
      Sentry.captureException(error)
    },
  }),
})

const cartManager = new CartManager({
  queryClient,
  initialCart: {
    currency: window.Lounge.cart.currency,
    note: window.Lounge.cart.note,
    totalPrice: window.Lounge.cart.total_price,
    itemsSubtotalPrice: window.Lounge.cart.items_subtotal_price,
    totalDiscount: window.Lounge.cart.total_discount,
    items: [], // We don't need this for initial load
    meta: {}, // We don't need this for initial load
    itemCount: window.Lounge.cart.item_count,
  },
  adapter: new AjaxApiCartAdapter(),
  plugins: [
    new SetsPlugin(),
    new GiftWrapPlugin({
      productId: gift_wrap.product?.id,
      productPrice: gift_wrap.product?.price,
      enabled: gift_wrap.enabled,
    }),
    new UnderwearCareBagPlugin({
      productId: underwear_care_bag.product?.id,
      variantId: underwear_care_bag.product?.variant,
      productPrice: underwear_care_bag.product?.price,
      enabled: underwear_care_bag.enabled,
    }),
  ],
})

const countryIsoCode = (window.Shopify.country ?? 'GB') as CountryCode
const languageIsoCode = (window.Shopify.locale?.split('-')?.[0].toUpperCase() ??
  'EN') as LanguageCode

const ProductPage = React.lazy(
  async () => import('./pages/Product/ProductPage')
)

const CartPage = React.lazy(async () => import('./pages/Cart/Cart'))

const CollectionPage = React.lazy(
  async () => import('./pages/Collection/CollectionPage')
)
const Login = React.lazy(() => import('./features/auth/components/Login/Login'))
const Register = React.lazy(
  () => import('./features/auth/components/Register/Register')
)
const ResetPassword = React.lazy(
  () => import('./features/auth/components/ResetPassword/ResetPassword')
)
const Account = React.lazy(
  () => import('./features/account/components/Account/Account')
)
const MediaAccordion = React.lazy(
  () => import('./components/MediaAccordion/MediaAccordion')
)

const Footprint = React.lazy(
  () => import('./pages/Sustainability/Footprint/Footprint')
)

const Projects = React.lazy(
  () => import('./pages/Sustainability/Projects/Projects')
)

const WhatsNext = React.lazy(
  () => import('./pages/Sustainability/WhatsNext/WhatsNext')
)
const Signup = React.lazy(() => import('./features/signup/Signup'))
const FittingRoomMedia = React.lazy(() => import('./pages/FittingRoom/Media'))
const FittingRoomForm = React.lazy(() => import('./pages/FittingRoom/Form'))

const MediaContentVideoModal = React.lazy(
  () => import('./components/MediaContentVideoModal')
)
const ProductQuickAddBlock = React.lazy(
  () => import('./components/ProductQuickAddBlock')
)
const StoryCarousel = React.lazy(() => import('./components/StoryCarousel'))

const CareersVacanciesDeprecated = React.lazy(async () => ({
  default: (
    await import('./features/careers/components/Vacancies/VacanciesDeprecated')
  ).VacanciesDeprecated,
}))

const CareersVacancies = React.lazy(async () => ({
  default: (await import('./features/careers/components/Vacancies/Vacancies'))
    .Vacancies,
}))

const CareersVacancy = React.lazy(async () => ({
  default: (await import('./features/careers/components/Vacancy/Vacancy'))
    .Vacancy,
}))

const MediaCarousel = React.lazy(async () => ({
  default: (await import('./components/MediaCarousel')).MediaCarousel,
}))

export const App = () => {
  const searchInsights = useSearchInsights()

  const identifyUser = React.useCallback((user: User | null) => {
    if (user) {
      Sentry.setUser({ email: user.email })
      searchInsights.setUserToken(parseGID(user.id).childObjectId)
    }
  }, [])

  return (
    <QueryClientProvider client={queryClient}>
      <CartProvider cartManager={cartManager}>
        <ToastProvider>
          <ShopifyContext.Provider
            value={{
              ...window.Shopify,
              routes: window.Lounge.routes,
              environment: window.Lounge.environment,
              settings: window.Lounge.settings,
              integrations: window.Lounge.integrations,
              modals: window.Lounge.modals ?? [],
              exposeSearch: window.Lounge.exposeSearch ?? false,
              featureFlags: window.Lounge.featureFlags ?? {},
            }}
          >
            <AuthProvider
              auth={auth}
              onLogout={() => queryClient.clear()}
              onUser={identifyUser}
            >
              <SearchInsights>
                <EntryPoint id="section-navigation" component={Header} />
                <EntryPoint id="captioned-image" component={CaptionedImage} />
                <EntryPoint id="section-footer" component={Footer} />
                <EntryPoint
                  id="section-announcement-bar"
                  component={AnnouncementBar}
                />
                <ModalGroup />
                {/* Product page */}
                <EntryPoint id="product-page" component={ProductPage} />
                {/* Collection page */}
                <EntryPoint id="collection-page" component={CollectionPage} />
                <EntryPoint id="section-video" component={VideoSection} />
                {/* Cart page */}
                <EntryPoint id="section-cart-page" component={CartPage} />
                {/* Auth */}
                <EntryPoint id="section-login" component={Login} />
                <EntryPoint id="section-signup" component={Signup} />
                <EntryPoint id="section-register" component={Register} />
                <EntryPoint
                  id="section-reset-password"
                  component={ResetPassword}
                />
                <EntryPoint id="text-accordion" component={TextAccordion} />
                <EntryPoint
                  id="media-content-slider"
                  component={MediaContentSlider}
                />
                <EntryPoint
                  id="section-content-carousel"
                  component={ContentCarousel}
                />
                {/* Account */}
                <EntryPoint id="section-account" component={Account} />
                {/* Blog */}
                <EntryPoint id="blog-related-posts" component={RelatedPosts} />
                {/* Sustainability */}
                <EntryPoint id="sustainability-projects" component={Projects} />
                <EntryPoint
                  id="sustainability-footprint"
                  component={Footprint}
                />
                <EntryPoint
                  id="sustainability_whats-next"
                  component={WhatsNext}
                />
                {/* Components */}
                <EntryPoint id="media-accordion" component={MediaAccordion} />
                <EntryPoint
                  id="media-content-video-modal"
                  component={MediaContentVideoModal}
                />
                <EntryPoint
                  id="section-product-carousel"
                  component={ProductCarousel}
                />
                <EntryPoint
                  id="product-quick-add"
                  component={ProductQuickAddBlock}
                />
                <EntryPoint id="hotspot-multi" component={Hotspots} />
                <EntryPoint id="hotspot-single" component={Hotspots} />

                <EntryPoint id="interactive-masonry" component={Hotspots} />
                <EntryPoint id="interactive-single" component={Hotspots} />
                <EntryPoint
                  id="section-story-carousel"
                  component={StoryCarousel}
                />
                <EntryPoint
                  id="section-card-carousel"
                  component={CardCarousel}
                />
                <EntryPoint
                  id="section-media-carousel"
                  component={MediaCarousel}
                />
                <EntryPoint id="countdown" component={Countdown} />
                <EntryPoint
                  id="section-image-carousel"
                  component={ImageCarousel}
                />
                {/* Fitting Room */}
                <EntryPoint
                  id="section-fitting-room-media"
                  component={FittingRoomMedia}
                />
                <EntryPoint
                  id="section-fitting-room-form"
                  component={FittingRoomForm}
                />
                {/* Careers */}
                <EntryPoint
                  id="section-careers-vacancies-deprecated"
                  component={CareersVacanciesDeprecated}
                />
                <EntryPoint
                  id="section-careers-vacancies"
                  component={CareersVacancies}
                />
                <EntryPoint
                  id="section-careers-vacancy"
                  component={CareersVacancy}
                />
                <EntryPoint id="careers-content-cards" component={Cards} />
                <EntryPoint id="promotion-banner" component={PromoBanner} />
                <EntryPoint id="careers-tips-hints" component={Tips} />
                <StoreRedirectBanner />
              </SearchInsights>
            </AuthProvider>
          </ShopifyContext.Provider>
        </ToastProvider>
      </CartProvider>
    </QueryClientProvider>
  )
}
