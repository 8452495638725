import { ElementType } from 'react'
import classNames from 'classnames'

import { Box } from '../../components'

import { TileActionsProps } from './types'

export const Actions = <C extends ElementType>({
  className,
  children,
}: TileActionsProps<C>) => (
  <Box as="div" className={classNames('tile__actions', className)}>
    {children}
  </Box>
)
