import { createWithEqualityFn } from 'zustand/traditional'

interface RecentlyAddedProductsState {
  recentlyAddedProductIds: number[]
  setRecentlyAddedProductIds: (recentlyAddedProducts: number[]) => void
  clearRecentlyAddedProductIds: () => void
}

export const useRecentlyAddedProducts =
  createWithEqualityFn<RecentlyAddedProductsState>(
    (set) => ({
      recentlyAddedProductIds: [],
      setRecentlyAddedProductIds: (recentlyAddedProductIds: number[]) =>
        set({ recentlyAddedProductIds }),
      clearRecentlyAddedProductIds: () => set({ recentlyAddedProductIds: [] }),
    }),
    Object.is
  )
