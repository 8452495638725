import { ElementType } from 'react'
import classNames from 'classnames'

import { Box } from '../../components'

import { AspectRatio, ContentPlacement, Size, TileProps } from './types'

const aspectRatioClass: { [key in AspectRatio]: string } = {
  '1/1': 'tile--aspect-ratio-1/1',
  '10/7': 'tile--aspect-ratio-10/7',
  '3/2': 'tile--aspect-ratio-3/2',
  '7/6': 'tile--aspect-ratio-7/6',
  '9/8': 'tile--aspect-ratio-9/8',
  '5/4': 'tile--aspect-ratio-5/4',
}

const sizeClass: { [key in Size]: string } = {
  md: 'tile--size-md',
  lg: 'tile--size-lg',
  xl: 'tile--size-xl',
}

const contentPlacementClass: { [key in ContentPlacement]: string } = {
  inside: 'tile--content-placement-inside',
  outside: 'tile--content-placement-ouside',
}

const defaultElement = 'div'

export const Tile = <E extends ElementType = typeof defaultElement>({
  as,
  className,
  size = 'md',
  contentPlacement,
  aspectRatio,
  children,
  centered = false,
  overlay = true,
  ...props
}: TileProps<E>): JSX.Element => {
  const element: React.ElementType = as || defaultElement
  return (
    <Box
      as={element}
      className={classNames(
        'tile',
        aspectRatio && aspectRatioClass[aspectRatio],
        sizeClass[size],
        contentPlacementClass[contentPlacement],
        { 'tile--centered': centered },
        { 'tile--overlay': overlay },
        className
      )}
      {...props}
    >
      {children}
    </Box>
  )
}
