import { RestClient } from '@lounge-fe/network'

import {
  Cart as LiquidCart,
  LineItem as LiquidLineItem,
} from '@/types/LiquidObjects'

import {
  Cart,
  Item,
  CartAdapter,
  AddCartItemPayload,
  UpdateCartPayload,
} from '../../types'

import { AjaxApiCart } from './types'

export class AjaxApiCartAdapter implements CartAdapter {
  private client: RestClient

  constructor() {
    this.client = new RestClient({
      baseURL: '/',
      useCSRF: false,
    })
  }

  update = async (cart: UpdateCartPayload): Promise<Cart> => {
    await this.client.post<AjaxApiCart>('cart/update.js', cart)
    return this.get()
  }

  clear = async (): Promise<Cart> => {
    await this.client.post<AjaxApiCart>('cart/clear.js')
    return this.get()
  }

  get = async (): Promise<Cart> => {
    const res = await fetch(
      new URL(`/?section_id=cart_data`, window.location.origin).href
    )
    const htmlString = await res.text()
    const jsonString = htmlString!.match(
      /<script type="application\/json">([\s\S]*?)<\/script>/
    )![1]

    return this.toCart(JSON.parse(jsonString) as LiquidCart)
  }

  addItems = async (items: AddCartItemPayload[]): Promise<Cart> => {
    await this.client.post<AjaxApiCart>('cart/add.js', {
      items,
    })

    return this.get()
  }

  removeItems = async (ids: (number | string)[]): Promise<Cart> => {
    return this.update({
      updates: ids.reduce((prev, curr) => ({ ...prev, [curr]: 0 }), {}),
    })
  }

  private toCart = (cart: LiquidCart): Cart => {
    const itemsSubtotalPrice = cart.items.reduce(
      (acc, item) =>
        (item.compare_at_price > 0
          ? item.compare_at_price
          : item.original_line_price) + acc,
      0
    )

    const totalDiscount = itemsSubtotalPrice - cart.total_price

    return {
      itemsSubtotalPrice,
      totalPrice: cart.total_price,
      totalDiscount,
      itemCount: cart.item_count,
      items: cart.items.map(this.toCartItem),
      note: cart.note,
      currency: cart.currency,
      meta: {},
    }
  }

  private toCartItem = (item: LiquidLineItem): Item => {
    const originalLinePrice =
      item.compare_at_price > item.original_line_price
        ? item.compare_at_price
        : item.original_line_price

    return {
      id: item.id,
      productId: item.product.id,
      quantity: item.quantity,
      title: item.title,
      finalPrice: item.final_price,
      finalLinePrice: item.final_line_price,
      originalLinePrice,
      properties: item.properties,
      key: item.key,
      color: item.product.color,
      size: item.variant?.title,
      featuredImage: item.image
        ? {
            url: item.image.src,
            alt: item.image.alt,
          }
        : null,
      url: item.product.url,
      discountAllocations: item.discount_allocations,
      hasOnlyDefaultVariant: item.product.has_only_default_variant,
      collections: item.product.collections ?? [],
    }
  }
}
