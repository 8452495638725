import classNames from 'classnames'

import { Image as BaseImage, type ImageProps } from '../Image'

const Image = ({
  className,
  src = '',
  alt,
  loading = 'lazy',
}: ImageProps): JSX.Element => {
  return (
    <div>
      {src ? (
        <BaseImage
          className={classNames('aspect-[9/8] object-cover w-full', className)}
          data={{
            src,
            crop: 'center',
            quality: 80,
            width: 460,
            height: 410,
          }}
          alt={alt}
          loading={loading}
        />
      ) : (
        <div className="aspect-[9/8] bg-surface-primary" />
      )}
    </div>
  )
}

export { Image }
