import classNames from 'classnames'

import { Icon } from '../Icon'
import { ToggleGroup } from '../ToggleGroup'

import { SwatchesItemProps } from './types'

export const Item = ({
  className,
  children,
  color,
  checkColor = 'white',
  ...props
}: SwatchesItemProps): JSX.Element => (
  <ToggleGroup.Item
    className={classNames('swatches__item', className)}
    {...props}
  >
    {(bag) => (
      <>
        <span className="swatches__color" style={{ backgroundColor: color }}>
          {bag.selected && (
            <Icon.Check
              className="swatches__check"
              style={{ color: checkColor }}
            />
          )}
        </span>
        <span className="swatches__label">
          {typeof children === 'function' ? children(bag) : children}
        </span>
      </>
    )}
  </ToggleGroup.Item>
)
