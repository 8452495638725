import { useContext, useMemo, createContext } from 'react'

import { ProductCardProps } from './types'

const ProductCardContext = createContext<{ href: string }>({
  href: '',
})

export const ProductCard = ({
  href,
  onClick,
  ...props
}: ProductCardProps): JSX.Element => {
  const context = useMemo(() => ({ href }), [href])
  return (
    <ProductCardContext.Provider value={context}>
      <div onClick={onClick} className="product-card" {...props} />
    </ProductCardContext.Provider>
  )
}

export const useProductCardContext = () => useContext(ProductCardContext)
