import classNames from 'classnames'
import { createElement } from 'react'

import { Text } from '../../components/Text'
import { Icon, type SvgProps } from '../../components'

import { ToastProps, ToastSeverity } from './types'

const severityIconMap: {
  [key in ToastSeverity]: React.ComponentType<SvgProps>
} = {
  success: Icon.Check,
  critical: Icon.Close,
}

export const Toast = ({
  className,
  severity,
  title,
  message,
  ...props
}: ToastProps) => (
  <div
    role="alert"
    aria-live="off"
    className={classNames(
      'bg-background-secondary-default rounded-sm border border-border-default px-4 py-6 max-w-full w-full md:max-w-[452px] w-full flex gap-x-4',
      className
    )}
    {...props}
  >
    <div className="w-6 h-6 bg-base-primary rounded-circle p-2 shrink-0">
      {createElement(severityIconMap[severity], {
        className: 'w-full h-full text-white fill-white aspect-square',
      })}
    </div>
    <Text>
      {title && <b>{title} </b>}
      {message}
    </Text>
  </div>
)
