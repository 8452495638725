import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Icon, Image, Modal } from '@lounge-fe/ui-kit'
import { SignupModalProps } from './types'
import { SignUpForm } from './SignupForm'
import classNames from 'classnames'

export const SignupModal = ({
  data: {
    modal_title,
    modal_success_title,
    modal_success_promo,
    modal_success_body,
    image,
  },
  children,
  prefilledEmail,
  ...props
}: SignupModalProps) => {
  const [hasSignedUp, setHasSignedUp] = useState(false)

  const copy = (text: string) => {
    navigator.clipboard.writeText(text)
  }

  const { t } = useTranslation()

  return (
    <>
      <div {...props}>
        <Modal>
          {(modalBag) => (
            <>
              {typeof children === 'function' ? children(modalBag) : children}
              <Modal.Content size="lg" isMobileFullscreen={!hasSignedUp}>
                <Modal.Header
                  className={classNames(
                    'border-b-0',
                    image?.src && '!p-0 !m-0'
                  )}
                >
                  {image?.src && (
                    <div className="h-[244px] flex-shrink-0 relative">
                      <Image
                        className={classNames(
                          'w-full h-full object-cover absolute',
                          !hasSignedUp ? 'sm:rounded-t-md' : 'rounded-t-md'
                        )}
                        data={{
                          src: image.src,
                          height: 600,
                          width: 1200,
                          crop: 'center',
                        }}
                      />
                    </div>
                  )}
                  <Modal.CloseButton className="sm:m-2 sm:top-2 sm:right-2" />
                </Modal.Header>
                <Modal.Body className={classNames('grid-cols justify-center')}>
                  <div
                    className={classNames(
                      'max-w-[600px] mx-auto w-full grid gap-6 sm:px-8',
                      (modal_success_title || modal_title) && !image?.src
                        ? 'pb-6'
                        : 'py-6'
                    )}
                  >
                    {((hasSignedUp && modal_success_title) ||
                      (!hasSignedUp && modal_title)) && (
                      <div
                        className="text-center"
                        dangerouslySetInnerHTML={{
                          __html: hasSignedUp
                            ? modal_success_title
                            : modal_title,
                        }}
                      />
                    )}
                    {hasSignedUp ? (
                      <div className="grid gap-8 text-center">
                        {modal_success_promo && (
                          <Button
                            variant="ghost"
                            className="mb-4"
                            startIcon={<Icon.Copy />}
                            onClick={() => copy(modal_success_promo)}
                          >
                            {modal_success_promo}
                          </Button>
                        )}

                        {modal_success_body && (
                          <div
                            className="typography"
                            dangerouslySetInnerHTML={{
                              __html: modal_success_body,
                            }}
                          />
                        )}
                        <Button
                          className="mx-auto"
                          variant="solid"
                          onClick={() => {
                            modalBag.onClose()
                          }}
                        >
                          {t('action.close')}
                        </Button>
                      </div>
                    ) : (
                      <SignUpForm
                        email={prefilledEmail}
                        onSuccess={() => setHasSignedUp(true)}
                      />
                    )}
                  </div>
                </Modal.Body>
              </Modal.Content>
            </>
          )}
        </Modal>
      </div>
    </>
  )
}

SignupModal.Trigger = () => {
  const { t } = useTranslation()
  return (
    <Modal.Trigger>
      <Button
        type="submit"
        size="md"
        className="text-white w-full whitespace-nowrap"
        aria-label={t('action.sign_up')}
      >
        {t('action.sign_up')}
      </Button>
    </Modal.Trigger>
  )
}
