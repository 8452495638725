import {
  useMemo,
  useState,
  MouseEventHandler,
  forwardRef,
  useRef,
  useImperativeHandle,
} from 'react'
import { ErrorBoundary, withErrorBoundary } from '@sentry/react'

import { ProductCard } from '@lounge-fe/ui-kit'
import { ImageProps as BaseImageProps } from '@lounge-fe/ui-kit/src/components/Image/types'

import { AddToWishlist } from '../AddToWishlist'
import { QuickAdd } from './QuickAdd'
import { Price } from './Price'
import { StandardProductCardProps, StandardProductCardRef } from './types'
import { useProductBadges } from './hooks/useProductBadges'
import { canonicalColors } from '../../config/canonicalColors'
import { useTranslation } from 'react-i18next'
import { useColors } from '@/hooks/useColors'
import { EmblaCarouselType } from 'embla-carousel-react'

const getSizedImage = (
  image: { src: string } | undefined
): BaseImageProps | undefined =>
  image
    ? {
        ...image,
        src: undefined,
        data: {
          src: image.src,
          width: 345,
          height: 483,
          crop: 'center',
        },
      }
    : undefined

const BaseStandardProductCard = forwardRef<
  StandardProductCardRef,
  StandardProductCardProps
>(
  (
    {
      product,
      onClick: providedOnClick,
      onQuickAdd,
      useImageCarousel = false,
      useSwatches = false,
      useQuickAdd = false,
      showWishlist = true,
    },
    ref
  ) => {
    const { t } = useTranslation()
    const [selectedSwatchId, setSelectedSwatchId] = useState<number>(product.id)
    const { colors } = useColors()

    const selectedSwatch =
      product.swatches.find((swatch) => swatch.id === selectedSwatchId) ??
      product

    const imageCarouselRef = useRef<EmblaCarouselType>()

    useImperativeHandle(ref, () => ({
      imageCarousel: imageCarouselRef.current,
    }))

    const {
      href,
      id,
      handle = '',
      images = [],
      title,
      price,
      price_compare_at,
      color,
      badges = [],
      options = [],
      objectID,
      queryID,
      linkedBottoms = [],
      variants = [],
      tags = [],
      availableForSale,
      isLoadingAdditionalData,
    } = selectedSwatch

    const { onCardBadge, offCardBadge } = useProductBadges({
      badges,
      price,
      price_compare_at,
      tags,
    })

    const onClick: MouseEventHandler<HTMLDivElement> = async (evt) => {
      if (objectID || queryID) {
        window.sessionStorage.setItem(
          `aa_${handle}`,
          JSON.stringify([objectID, queryID])
        )
      }
      await providedOnClick?.(evt)
    }

    const onSwatchSelect = (id: number | null) => {
      if (id) {
        setSelectedSwatchId(id)
      } else {
        setSelectedSwatchId(product.id)
      }
    }

    const primaryImage = getSizedImage(images[0])
    const secondaryImage = getSizedImage(images[1])
    const swatches = [product, ...product.swatches]
    const comingSoon = tags.includes('coming-soon')

    return (
      <ProductCard href={href} data-testid="product-card" onClick={onClick}>
        <ProductCard.ImageWrapper>
          {showWishlist ? <AddToWishlist productId={id} /> : null}
          {onCardBadge ? (
            <ProductCard.Badges>
              <ProductCard.Badge color={onCardBadge.color} size="sm">
                {onCardBadge.text}
              </ProductCard.Badge>
            </ProductCard.Badges>
          ) : null}
          {useImageCarousel ? (
            <ProductCard.ImageCarousel
              images={
                images.map(getSizedImage).filter(Boolean) as BaseImageProps[]
              }
              ref={imageCarouselRef}
            />
          ) : (
            <ProductCard.Image
              primarySrc={primaryImage?.data}
              primaryImageLoading={primaryImage?.loading}
              alt={primaryImage?.alt ?? ''}
              secondarySrc={secondaryImage?.data}
            />
          )}
          {comingSoon ? (
            <ProductCard.Notice variant="light">
              {t('product.coming_soon')}
            </ProductCard.Notice>
          ) : (
            <>
              {availableForSale ? (
                /**
                 * TODO: remove linkedBottoms.length < 2 check when we have time to implement a solution
                 * since we've changed the linked_bottoms logic to be AND instead of OR
                 * and we don't have time to impment these changes into the QuickAdd component
                 * for now we'll just show the QuickAdd component if there are less than 2 linkedBottoms
                 */
                <>
                  {useQuickAdd && linkedBottoms.length < 2 && (
                    <QuickAdd
                      key={selectedSwatch.id}
                      handle={handle}
                      options={options}
                      inStock={Boolean(availableForSale && variants.length)}
                      image={images[0]}
                      title={title}
                      price={price}
                      compareAtPrice={price_compare_at}
                      color={color}
                      variants={variants}
                      linkedBottoms={linkedBottoms}
                      onQuickAdd={onQuickAdd}
                      isLoading={isLoadingAdditionalData}
                    />
                  )}
                </>
              ) : (
                <ProductCard.Notice variant="dark">
                  {t('product.out_of_stock')}
                </ProductCard.Notice>
              )}
            </>
          )}
        </ProductCard.ImageWrapper>
        <ProductCard.Details
          swatches={useSwatches ? swatches : []}
          onSwatchSelect={onSwatchSelect}
          selectedSwatchId={selectedSwatch.id}
          availableColors={colors}
          canonicalColors={canonicalColors}
          title={title}
          price={<Price price={price} />}
          color={color}
          compareAtPrice={
            price_compare_at ? <Price price={price_compare_at} /> : null
          }
          badge={offCardBadge}
          isLoadingAdditionalData={product.isLoadingAdditionalData}
          useSwatches={useSwatches}
        />
      </ProductCard>
    )
  }
)

export const StandardProductCard = forwardRef<
  StandardProductCardRef,
  StandardProductCardProps
>((props, ref) => (
  <ErrorBoundary>
    <BaseStandardProductCard {...props} ref={ref} />
  </ErrorBoundary>
))
