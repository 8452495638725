import { createCompoundComponent } from '../../utilities'
import { Trigger } from '../Popover/Trigger'
import { Group } from '../Popover/Group'

import { Drawer as BaseDrawer } from './Drawer'
import { Body } from './Body'
import { Content } from './Content'
import { Header } from './Header'

export const Drawer = createCompoundComponent(BaseDrawer, {
  Content,
  Header,
  Body,
  Trigger,
  Group,
})
