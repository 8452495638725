import { AfterPay } from './AfterPay'
import { Amex } from './Amex'
import { ApplePay } from './ApplePay'
import { ClearPay } from './ClearPay'
import { Klarna } from './Klarna'
import { Mastercard } from './Mastercard'
import { Paypal } from './Paypal'
import { Visa } from './Visa'

export const PaymentProviders = {
  AfterPay,
  Amex,
  ApplePay,
  ClearPay,
  Klarna,
  Mastercard,
  Paypal,
  Visa,
}
