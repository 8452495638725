import classNames from 'classnames'
import type { ElementType } from 'react'

import { Box } from './Box'
import type { FlexProps } from './types'

const defaultElement = 'div'

export const Flex = <C extends ElementType = typeof defaultElement>({
  className,
  children,
  as,
  ...props
}: FlexProps<C>): JSX.Element => {
  const element: React.ElementType = as || defaultElement
  return (
    <Box as={element} className={classNames('flex', className)} {...props}>
      {children}
    </Box>
  )
}
