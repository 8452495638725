import { Text } from '@lounge-fe/ui-kit'
import { Money } from '@shopify/hydrogen-react'
import { useShopify } from '../../../../../hooks/useShopify'
import { BasketLinePriceProps } from './types'
import { useTranslation } from 'react-i18next'

export const Price = ({
  originalLinePrice,
  finalLinePrice,
}: BasketLinePriceProps) => {
  const { currency } = useShopify()
  const { t } = useTranslation()

  return (
    <div className="flex gap-x-1">
      {finalLinePrice === 0 ? (
        <Text variant="body-sm" className="text-base-primary font-medium">
          {t('product.free')}
        </Text>
      ) : (
        <Money
          as={Text}
          variant="body-sm"
          className="text-base-primary font-medium"
          data={{
            currencyCode: currency.active,
            amount: (finalLinePrice / 100).toString(),
          }}
        />
      )}
      {originalLinePrice && originalLinePrice !== finalLinePrice ? (
        <Money
          as={Text}
          variant="body-sm"
          className="text-base-primary font-medium !text-[#C8C8C8] line-through"
          data={{
            currencyCode: currency.active,
            amount: (originalLinePrice / 100).toString(),
          }}
        />
      ) : null}
    </div>
  )
}
