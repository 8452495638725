import React from 'react'

import { SvgProps } from '../types'
import { Icon } from '../Icon'

export const Search: React.FC<SvgProps> = (props) => {
  return (
    <Icon viewBox="0 0 20 20" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5 8.75C16.5 13.0302 13.0302 16.5 8.75 16.5C4.46979 16.5 1 13.0302 1 8.75C1 4.46979 4.46979 1 8.75 1C13.0302 1 16.5 4.46979 16.5 8.75ZM14.5814 15.2737C13.0336 16.6582 10.9901 17.5 8.75 17.5C3.91751 17.5 0 13.5825 0 8.75C0 3.91751 3.91751 0 8.75 0C13.5825 0 17.5 3.91751 17.5 8.75C17.5 10.9901 16.6582 13.0336 15.2737 14.5814C15.3019 14.5999 15.3287 14.6216 15.3536 14.6464L19.5202 18.8131C19.7155 19.0084 19.7155 19.325 19.5202 19.5202C19.325 19.7155 19.0084 19.7155 18.8131 19.5202L14.6464 15.3536C14.6216 15.3287 14.5999 15.3019 14.5814 15.2737Z"
        fill="currentColor"
      />
    </Icon>
  )
}
