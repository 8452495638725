import zod from '../../zod'

const email = zod.string().email()

export const signup = zod.object({
  email,
  phone: zod.string().min(1),
  acceptsMarketing: zod.boolean().nullable(),
})

export type SignupValues = zod.infer<typeof signup>
