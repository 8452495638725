import { createCompoundComponent } from '../../utilities/createCompoundComponent'

import { Content } from './Content'
import { PromoCard as BasePromoCard } from './PromoCard'
import { Image } from './Image'

export const PromoCard = createCompoundComponent(BasePromoCard, {
  Content,
  Image,
})
