import { ShopifySectionComponent } from '@/types/ShopifySectionComponent'
import { Card, Badge, Text, Skeleton, Wrapper } from '@lounge-fe/ui-kit'

import { CardsData } from './types'
import { useJobs } from '@/features/careers/hooks/useJobs'

export const Cards: ShopifySectionComponent<CardsData> = ({ data: schema }) => {
  const { cards, card_background } = schema!

  const gridStyles = 'grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-6'

  const { data, isLoading } = useJobs({
    targetPageSize: 4,
    refetchOnWindowFocus: false,
    filterDepartments: [],
    articles: cards.map(({ article_greenhouse_id, article_url }) => {
      return {
        url: article_url,
        id: article_greenhouse_id,
      }
    }),
  })

  if (isLoading || !data || !data.jobs) {
    return (
      <Wrapper>
        <Skeleton className={gridStyles}>
          <Skeleton.Block className="h-[268px] w-[344px] lg:w-full rounded-sm" />
          <Skeleton.Block className="h-[268px] w-[344px] lg:w-full rounded-sm" />
          <Skeleton.Block className="h-[268px] w-[344px] lg:w-full rounded-sm" />
          <Skeleton.Block className="h-[268px] w-[344px] lg:w-full rounded-sm" />
        </Skeleton>
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <div className={gridStyles}>
        {data.jobs &&
          data.jobs.map((job, idx) => (
            <Card
              key={idx}
              as="a"
              href={job.url}
              className={`justify-center min-h-[176px] lg:min-h-[268px] items-center px-[56px] text-center ${card_background}`}
            >
              <Text variant="title-five" className="mb-6">
                {job.title}
              </Text>
              {job.departments.map((department, index) => (
                <Badge className="px-6" key={index} size="lg" color="white">
                  {department.name}
                </Badge>
              ))}
            </Card>
          ))}
      </div>
    </Wrapper>
  )
}
