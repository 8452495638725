import { flattenConnection } from '@shopify/hydrogen-react'
import get from 'lodash/get'

import { ExtendedProductHit, ProductHit } from '../../types'
import { parseGID } from '@/utils/parseGID'
import { Metafield } from '@shopify/hydrogen-react/storefront-api-types'
import {
  GetProductDataResponseNode,
  ProductQueryData,
} from '../../queries/getProductData'

/**
 * This function constructs an extended product object given the specific properties.
 * It mainly uses the input properties, but falls back to default values for certain properties.
 * This function is used when mapping search results from Algolia and combining with the storefront API.
 *
 * @param {ProductHit} hit - Hit returned from algolia.
 * @param {ResponseNode} node - Node returned from the storefront API.
 * @returns {ExtendedProductHit} - An extendeds product object.
 */
export const createStorefrontExtendedProductHit = (
  hit: ProductHit,
  node?: GetProductDataResponseNode
): ExtendedProductHit => {
  const getMetaValue = <T>(key: string, fallback: T | undefined) =>
    get(hit.meta?.lounge, key) ?? fallback

  const images = node
    ? flattenConnection(node.images).map((image) => ({
        src: image.url,
        alt: image.altText ?? hit.title,
      }))
    : [{ src: hit.product_image, alt: hit.title }]

  const compareAtPrice = getMetaValue(
    'price_compare_at.amount',
    hit.compare_at_price
  )

  return {
    badges: hit.meta?.lounge?.badges ?? [],
    color: getMetaValue('color', undefined),
    canonical_color: getMetaValue('canonical_color', undefined),
    handle: hit.handle,
    href: `/products/${hit.handle}`,
    id: hit.id,
    images: images,
    isFavourited: false,
    linkedBottoms:
      node?.linkedBottoms?.references.nodes.map((node) => formatNode(node)) ??
      [],
    objectID: hit.objectID,
    options: node?.options ?? [],
    position: hit.position,
    price: Number(getMetaValue('price.amount', hit.price)) * 100,
    price_compare_at: compareAtPrice != null ? compareAtPrice * 100 : undefined,
    queryID: hit.queryID,
    tags: hit.tags ?? [],
    title: getMetaValue('title', hit.title),
    swatches:
      node?.swatches?.references.nodes.map((node) => formatNode(node)) ?? [],
    isLoadingAdditionalData: !Boolean(node),
    availableForSale: node?.availableForSale ?? true,
  }
}

const formatNode = (
  node: ProductQueryData
): Omit<ExtendedProductHit, 'swatches'> => {
  const id = parseGID(node.id).childObjectId

  const getPriceValue = (
    metafieldKey: keyof ProductQueryData,
    fallback: string
  ) => {
    let value = fallback
    const metafieldValue = (node[metafieldKey] as Metafield)?.value
    if (metafieldValue) {
      value = JSON.parse(metafieldValue).amount
    }

    return Number(value)
  }

  const price = getPriceValue(
    'priceMetafield',
    node.priceRange.maxVariantPrice.amount
  )

  const compareAtPrice = getPriceValue(
    'compareAtPriceMetafield',
    node.compareAtPriceRange.maxVariantPrice.amount
  )

  return {
    id: Number(id),
    handle: node.handle,
    images: flattenConnection(node.images).map((image) => ({
      src: image.url,
      alt: image.altText ?? node.title,
    })),
    title: node.titleMetafield?.value ?? node.title,
    color: node.color?.value,
    canonical_color: node.canonicalColor?.value,
    isFavourited: false,
    href: `/products/${node.handle}`,
    objectID: id,
    options: node.options,
    price: price * 100,
    price_compare_at:
      typeof compareAtPrice !== 'undefined' ? compareAtPrice * 100 : undefined,
    tags: node.tags ?? [],
    badges: node.badges?.value ? JSON.parse(node.badges.value) : [],
    isLoadingAdditionalData: false,
    availableForSale: node.availableForSale,
    linkedBottoms:
      node?.linkedBottoms?.references.nodes.map((node) => formatNode(node)) ??
      [],
  }
}
