import { Controller } from 'react-hook-form'

import { RadioGroup as BaseRadioGroup } from '../../RadioGroup'

import { RadioGroupProps } from './types'

export const RadioGroup = ({
  name,
  label,
  options,
  'aria-label': ariaLabel,
  placeholder,
  ...props
}: RadioGroupProps): JSX.Element => (
  <Controller
    name={name}
    render={({ field }) => (
      <BaseRadioGroup {...field} {...props}>
        {options.map((option) => (
          <BaseRadioGroup.Item key={option.value} {...option} />
        ))}
      </BaseRadioGroup>
    )}
  />
)
