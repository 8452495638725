import { createCompoundComponent } from '../../utilities'

import { Progress as BaseProgress } from './Progress'
import { ProgressBar as Bar } from './ProgressBar'

const Progress = createCompoundComponent(BaseProgress, {
  Bar,
})

export { Progress }
