import classNames from 'classnames'
import { IconButton } from '../../IconButton'
import { IconButtonProps } from '../../IconButton/types'

export const Arrow = ({
  className,
  onClick,
  icon,
  ...props
}: IconButtonProps) => (
  <IconButton
    icon={icon}
    variant="ghost"
    className={classNames(
      className,
      '!bg-white shadow-3 absolute z-10 top-1/2'
    )}
    onClick={onClick}
    size="sm"
    {...props}
  />
)
