import { useId, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Icon } from '../../Icon'
import { IconButton } from '../../IconButton'
import { Input } from '../Input'
import { InputProps } from '../Input/types'

export const Password = ({
  id: providedId,
  ...props
}: InputProps): JSX.Element => {
  const generatedId = useId()
  const id = providedId ?? generatedId

  const { t } = useTranslation()
  const [hidden, setHidden] = useState(true)
  return (
    <Input
      id={id}
      type={hidden ? 'password' : 'text'}
      {...props}
      endAdornment={
        <IconButton
          icon={
            hidden ? (
              <Icon.Eye
                className="w-4 fill-[#7D7D7D]"
                data-testid="icon--eye"
              />
            ) : (
              <Icon.EyeSlash
                className="w-4 fill-[#7D7D7D]"
                data-testid="icon--eye-slash"
              />
            )
          }
          type="button"
          onClick={() => setHidden((prev) => !prev)}
          aria-label={hidden ? t('action.show') : t('action.hide')}
          aria-controls={id}
        />
      }
    />
  )
}
