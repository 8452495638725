import { ElementType } from 'react'
import classNames from 'classnames'

import { Box } from '../../components/Box'
import { Image as BaseImage } from '../../components/Image'

import { ImageProps, ImageSrcData } from './types'
import { useProductCardContext } from './ProductCard'

export const Image = ({
  children,
  className,
  primarySrc,
  primaryImageLoading,
  secondarySrc,
  alt,
  ...props
}: ImageProps) => {
  const { href } = useProductCardContext()

  const getImage = (data: ImageSrcData) =>
    typeof data === 'string' ? { src: data } : { data }

  return (
    <a
      href={href}
      className={classNames(
        'product-card__image',
        className,
        (!primarySrc || !secondarySrc) && 'aspect-[5/7]'
      )}
      {...props}
    >
      {primarySrc && (
        <BaseImage
          {...getImage(primarySrc)}
          alt={alt}
          loading={primaryImageLoading}
        />
      )}
      {secondarySrc && (
        <BaseImage {...getImage(secondarySrc)} alt={`${alt} alternate`} />
      )}
    </a>
  )
}
