import React, { Context } from 'react'
import { ShopifyContextState } from './types'

export const ShopifyContext: Context<ShopifyContextState> = React.createContext(
  {
    ...window.Shopify,
    routes: window.Lounge.routes,
    environment: window.Lounge.environment,
    settings: window.Lounge.settings,
    integrations: window.Lounge.integrations,
    modals: window.Lounge.modals ?? [],
    exposeSearch: window.Lounge.exposeSearch ?? false,
    featureFlags: window.Lounge.featureFlags ?? {},
  }
)
