import type { ElementType } from 'react'
import classNames from 'classnames'

import { getExternalLinkProps } from '../../utilities'
import { Text } from '../Text'

import type { LinkProps } from './types'

const defaultElement = 'a'

export const Link = <C extends ElementType = typeof defaultElement>({
  external,
  as,
  className,
  ...props
}: LinkProps<C>): JSX.Element => {
  const internalProps = external ? getExternalLinkProps() : {}
  const element: React.ElementType = as || defaultElement

  return (
    <Text
      as={element}
      className={classNames('font-medium', className)}
      {...internalProps}
      {...props}
    />
  )
}
