import { createCompoundComponent } from '../../utilities'

import { Fade } from './Fade'
import { Transition as BaseTransition } from './Transition'
import { Slide } from './Slide'
import { Appear } from './Appear'

export const Transition = createCompoundComponent(BaseTransition, {
  Fade,
  Slide,
  Appear,
})
