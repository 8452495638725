import { Box } from '@lounge-fe/ui-kit'

import { Overlay } from './Overlay'
import { SearchProps } from './types'

export const Search = ({ isOpen = false, ...props }: SearchProps) => (
  <Box className="relative">
    <Overlay {...props} />
  </Box>
)
