import React, { useId } from 'react'

import { SvgProps } from '../types'
import { Icon } from '../Icon'

export const ShoppingBag: React.FC<SvgProps> = (props) => {
  const id = useId()
  return (
    <Icon width="38" height="48" viewBox="0 0 38 48" {...props}>
      <path
        d="M33 10.0003H28.3333C28.3333 4.84366 24.1566 0.666992 19 0.666992C13.8433 0.666992 9.66663 4.84366 9.66663 10.0003H4.99996C2.4333 10.0003 0.333298 12.1003 0.333298 14.667V42.667C0.333298 45.2337 2.4333 47.3337 4.99996 47.3337H33C35.5666 47.3337 37.6666 45.2337 37.6666 42.667V14.667C37.6666 12.1003 35.5666 10.0003 33 10.0003ZM14.3333 19.3337C14.3333 20.617 13.2833 21.667 12 21.667C10.7166 21.667 9.66663 20.617 9.66663 19.3337V14.667H14.3333V19.3337ZM19 5.33366C21.5666 5.33366 23.6666 7.43366 23.6666 10.0003H14.3333C14.3333 7.43366 16.4333 5.33366 19 5.33366ZM28.3333 19.3337C28.3333 20.617 27.2833 21.667 26 21.667C24.7166 21.667 23.6666 20.617 23.6666 19.3337V14.667H28.3333V19.3337Z"
        fill="currentColor"
      />
    </Icon>
  )
}
