import { Icon, SvgProps } from '@lounge-fe/ui-kit'
import { ComponentType } from 'react'

export type Store = {
  isoCodes: string[]
  shortName?: string
  name: string
  language: string
  domain: string
  shop: string
  icon: ComponentType<SvgProps>
}

export const STORES: Store[] = [
  {
    isoCodes: ['ROW'],
    name: 'Rest of World',
    shortName: 'ROW',
    shop: 'uslounge',
    language: 'English',
    domain: 'https://us.lounge.com',
    icon: Icon.Flags.ROW,
  },
  {
    isoCodes: ['AU', 'NZ'],
    name: 'Australia & NZ',
    shortName: 'AUS',
    shop: 'auslounge',
    language: 'English',
    domain: 'https://aus.lounge.com',
    icon: Icon.Flags.AUS,
  },
  {
    isoCodes: ['CA'],
    name: 'Canada',
    shortName: 'CA',
    shop: 'calounge',
    language: 'English',
    domain: 'https://ca.lounge.com',
    icon: Icon.Flags.CA,
  },
  {
    isoCodes: ['DK'],
    name: 'Danmark',
    shortName: 'DK',
    shop: 'dklounge',
    language: 'Dansk',
    domain: 'https://dk.lounge.com',
    icon: Icon.Flags.DK,
  },
  {
    isoCodes: ['AT', 'DE'],
    name: 'Deutschland & Österreich',
    shortName: 'DE',
    shop: 'germanylounge',
    language: 'Deutsch',
    domain: 'https://de.lounge.com',
    icon: Icon.Flags.DE,
  },
  {
    isoCodes: [],
    name: 'Europe',
    shortName: 'EU',
    shop: 'eulounge',
    language: 'English',
    domain: 'https://eu.lounge.com',
    icon: Icon.Flags.EU,
  },
  {
    isoCodes: ['FR'],
    name: 'France',
    shortName: 'FR',
    shop: 'frlounge',
    language: 'Français',
    domain: 'https://fr.lounge.com',
    icon: Icon.Flags.FR,
  },
  {
    isoCodes: ['NL'],
    name: 'Nederland',
    shortName: 'NL',
    shop: 'nllounge',
    language: 'Nederlands',
    domain: 'https://nl.lounge.com',
    icon: Icon.Flags.NL,
  },
  {
    isoCodes: ['CH'],
    name: 'Schweiz',
    shortName: 'CH',
    shop: 'chlounge',
    language: 'Deutsch',
    domain: 'https://ch.lounge.com',
    icon: Icon.Flags.CH,
  },
  {
    isoCodes: ['SE'],
    name: 'Sverige',
    shortName: 'SE',
    shop: 'selounge',
    language: 'Svenska',
    domain: 'https://se.lounge.com',
    icon: Icon.Flags.SV,
  },
  {
    isoCodes: ['GB'],
    name: 'United Kingdom',
    shortName: 'UK',
    shop: 'loungeunderwear',
    language: 'English',
    domain: 'https://lounge.com',
    icon: Icon.Flags.GB,
  },
  {
    isoCodes: ['US'],
    name: 'United States',
    shortName: 'US',
    shop: 'uslounge',
    language: 'English',
    domain: 'https://us.lounge.com',
    icon: Icon.Flags.US,
  },
]

export const DEFAULT_STORE: Store = STORES.find(
  ({ shortName }) => shortName === 'ROW'
)!
