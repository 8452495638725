import { Flex, Text, Button } from '@lounge-fe/ui-kit'
import { useTranslation } from 'react-i18next'

import { SearchLoadMoreProps } from './types'

export const LoadMore = ({
  onClick,
  currentHits = 0,
  totalHits = 0,
}: SearchLoadMoreProps) => {
  const { t } = useTranslation()
  return (
    <Flex className="relative flex-col items-center justify-center gap-2 mb-8">
      <Button onClick={onClick}>{t('action.load_more')}</Button>
      <Text>
        {t('general.viewing_x_y_of_total_type', {
          x: 1,
          y: currentHits,
          total: totalHits,
          type: t('product.products'),
        })}
      </Text>
    </Flex>
  )
}
