import { createCompoundComponent } from '../../utilities'
import { Image } from '../../components/Image'

import { ArticleCard as BaseArticleCard } from './ArticleCard'
import { Details } from './Details'

export const ArticleCard = createCompoundComponent(BaseArticleCard, {
  Image,
  Details,
})
