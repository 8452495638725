import { addImageSizeParametersToUrl, Image } from '@lounge-fe/ui-kit'
import classNames from 'classnames'
import { AuthFormLayoutMediaProps } from './types'
import { captureException } from '@sentry/react'

export const Media = ({
  className,
  image,
  imageAlt = '',
  video,
  ...props
}: AuthFormLayoutMediaProps) => {
  if (!image && !video) {
    return null
  }

  return (
    <div
      className={classNames(
        'h-full overflow-hidden hidden md:block',
        className
      )}
      {...props}
    >
      {video ? (
        <video
          autoPlay={true}
          muted
          loop
          className="w-full h-full object-cover"
          poster={video.preview_image.src}
          title={video.alt}
        >
          {video.sources
            .sort((a, b) => b.height - a.height)
            .map(({ url, mime_type, height }) => (
              <source src={url} type={mime_type} height={height} />
            ))}
        </video>
      ) : (
        <Image
          src={addImageSizeParametersToUrl({
            src: image!,
            width: 1080,
            onError: captureException,
          })}
          alt={imageAlt}
          className="w-full h-full object-cover"
        />
      )}
    </div>
  )
}
