import classNames from 'classnames'
import { FC } from 'react'

import { Text } from '../Text'

import { useRadioGroupContext } from './RadioGroup'
import { RadioGroupItemProps } from './types'

export const Item: FC<RadioGroupItemProps> = ({
  value,
  label,
  disabled,
  checked,
  className,
  ...props
}) => {
  const { name, onChange, value: selectedValue } = useRadioGroupContext()

  const itemId = `${name}-${value}`

  const readOnly =
    typeof checked !== 'undefined' ||
    (typeof selectedValue !== 'undefined' && !onChange)

  return (
    <div
      className={classNames(
        'radio-group__item',
        disabled && 'radio-group__item--disabled',
        className
      )}
      {...props}
    >
      <input
        type="radio"
        id={itemId}
        name={name}
        disabled={disabled}
        readOnly={readOnly}
        checked={
          checked ?? typeof selectedValue !== 'undefined'
            ? value === selectedValue
            : undefined
        }
        onChange={onChange ? () => onChange(value) : undefined}
      />
      <Text htmlFor={itemId} as="label">
        {label}
      </Text>
    </div>
  )
}
