import { Box, Text, Wrapper } from '@lounge-fe/ui-kit'
import { Trans } from 'react-i18next'

import { SearchStatsProps } from './types'

export const Stats = ({ count = 0, query = '' }: SearchStatsProps) => (
  <Wrapper>
    <Box className="relative mb-8">
      <Text>
        <Trans
          i18nKey="general.x_products_found_for_query_y"
          values={{ count, query }}
          components={{ bold: <b /> }}
        />
      </Text>
    </Box>
  </Wrapper>
)
