import { MutableRefObject, useCallback, useEffect } from 'react'

export const useClickAwayListener = (
  ref: MutableRefObject<HTMLElement | null>,
  callback: (event: MouseEvent) => void
) => {
  const handleClick = useCallback(
    (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        return callback(event)
      }
    },
    [callback]
  )

  useEffect(() => {
    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [handleClick])
}
