import classNames from 'classnames'
import { FC } from 'react'

import { CloseIconButton } from '../CloseIconButton'
import { IconButtonProps } from '../IconButton/types'
import { usePopoverContext } from '../Popover/Popover'

export const CloseButton: FC<Partial<IconButtonProps>> = ({
  className,
  ...props
}) => {
  const { onClose } = usePopoverContext()
  return (
    <CloseIconButton
      className={classNames(
        'top-6 right-6 sm:right-0 !bg-[#F0F0F0] absolute self-end',
        className
      )}
      onClick={onClose}
      aria-label="Close"
      {...props}
    />
  )
}
