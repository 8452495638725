export const getProductsVariantsData = `
query getProductsVariants($ids: [ID!]!) {
  nodes(ids:$ids) {
    ... on Product {
      id
      variants(first: 250) {
        nodes {
          id
          availableForSale
          title
          selectedOptions {
            name
            value
          }
        }
      }
    }
  }
}
`
