import React from 'react'

import { SvgProps } from '../types'
import { Icon } from '../Icon'

export const Filters: React.FC<SvgProps> = (props) => {
  return (
    <Icon
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx="5.9987"
        cy="4.66667"
        r="2.16667"
        stroke="currentColor"
        fill="transparent"
      />
      <rect
        y="4.3335"
        width="2.66667"
        height="1"
        rx="0.5"
        fill="currentColor"
      />
      <rect
        x="9.33203"
        y="4.3335"
        width="6.66667"
        height="1"
        rx="0.5"
        fill="currentColor"
      />
      <circle
        cx="10.0013"
        cy="11.3333"
        r="2.16667"
        transform="rotate(180 10.0013 11.3333)"
        stroke="currentColor"
        fill="transparent"
      />
      <rect
        x="16"
        y="11.6667"
        width="2.66667"
        height="1"
        rx="0.5"
        transform="rotate(180 16 11.6667)"
        fill="currentColor"
      />
      <rect
        x="6.66797"
        y="11.6667"
        width="6.66667"
        height="1"
        rx="0.5"
        transform="rotate(180 6.66797 11.6667)"
        fill="currentColor"
      />
    </Icon>
  )
}
