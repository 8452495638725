import classNames from 'classnames'
import {
  useEffect,
  useCallback,
  ElementType,
  AnimationEventHandler,
  useReducer,
  memo,
} from 'react'

import { useDisclosureContext } from '../../hooks'
import { Box } from '../Box'

import { TransitionProps } from './types'

const defaultElement = 'div'

type Status = 'entering' | 'entered' | 'exiting' | 'exited'

interface ReducerState {
  status: Status
}

type ReducerAction =
  | { type: 'SHOW' }
  | { type: 'HIDE' }
  | { type: 'ANIMATION_END' }

const reducer = (state: ReducerState, action: ReducerAction): ReducerState => {
  switch (action.type) {
    case 'SHOW':
      return {
        ...state,
        status: state.status !== 'entered' ? 'entering' : state.status,
      }
    case 'HIDE':
      return {
        ...state,
        status: state.status !== 'exited' ? 'exiting' : state.status,
      }
    case 'ANIMATION_END': {
      let status: Status = state.status
      if (state.status === 'entering') {
        status = 'entered'
      } else if (state.status === 'exiting') {
        status = 'exited'
      }
      return {
        ...state,
        status,
      }
    }
    default:
      return state
  }
}

/**
 * The Transition component allows you to add enter & exit animations to conditionally rendered elements.
 * CSS classes can be added using the `enter` and `exit` props to add styles during different stages of the transition.
 */
export const Transition = <E extends ElementType = typeof defaultElement>({
  as,
  show: providedShow,
  enter,
  exit,
  className,
  children,
  keepMounted = false,
  initial = false,
  onAnimationEnd: providedOnAnimationEnd,
  ...props
}: TransitionProps<E>): JSX.Element | null => {
  const { isOpen } = useDisclosureContext()
  const element: React.ElementType = as || defaultElement

  const show = typeof providedShow !== 'undefined' ? providedShow : isOpen

  const [state, dispatch] = useReducer(reducer, {
    // eslint-disable-next-line no-nested-ternary
    status: show ? (initial ? 'entering' : 'entered') : 'exited',
  })

  const onAnimationEnd: AnimationEventHandler = useCallback(
    (evt) => {
      dispatch({ type: 'ANIMATION_END' })
      providedOnAnimationEnd?.(evt)
    },
    [show, keepMounted]
  )

  useEffect(() => {
    dispatch({ type: show ? 'SHOW' : 'HIDE' })
  }, [show])

  if (!keepMounted && state.status === 'exited') {
    return null
  }

  return (
    <Box
      as={element}
      className={classNames(
        enter && { [enter]: state.status === 'entering' },
        exit && { [exit]: state.status === 'exiting' },
        state.status === 'exited' && 'hidden',
        className
      )}
      data-testid="transition"
      onAnimationEnd={onAnimationEnd}
      {...props}
    >
      {children}
    </Box>
  )
}
