import { forwardRef, useEffect, useRef } from 'react'
import ReactFocusLock from 'react-focus-lock'

import { useKeyPress, useLockedBody } from '../../hooks'

import { ContentProps } from './types'
import { usePopoverContext } from './Popover'

export const Content = forwardRef<HTMLElement, ContentProps>(
  ({ children, className, onClickAway, ...props }, ref) => {
    const context = usePopoverContext()
    const innerRef = useRef<HTMLDivElement>()
    const {
      isOpen,
      onClose,
      contentId,
      triggerId,
      scrollLock,
      focusLock,
      popoverId,
    } = context
    const [, setLockedBody] = useLockedBody()
    const isEscapePressed = useKeyPress('Escape')

    useEffect(() => {
      if (scrollLock) {
        setLockedBody(isOpen)
      }
    }, [isOpen, scrollLock])

    useEffect(() => {
      if (isEscapePressed) {
        onClose()
      }
    }, [isEscapePressed])

    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (
          event.target &&
          innerRef?.current &&
          !innerRef?.current.contains(event.target as Node)
        ) {
          onClickAway?.(event)
        }
      }

      if (isOpen) {
        document.addEventListener('click', handleClickOutside)
      }

      return () => {
        document.removeEventListener('click', handleClickOutside)
      }
    }, [isOpen, popoverId, onClickAway])

    return (
      <ReactFocusLock
        className={className}
        as="div"
        disabled={!focusLock}
        group={contentId}
        lockProps={{
          disabled: !focusLock,
          group: contentId,
          role: 'dialog',
          id: contentId,
          'aria-labelledby': triggerId,
          'aria-hidden': !isOpen,
          'aria-modal': focusLock,
          'data-ui-state': isOpen ? 'open' : '',
          'data-popover-id': popoverId,
          ref: innerRef,
          ...props,
        }}
        ref={ref}
      >
        {typeof children === 'function' ? children(context) : children}
      </ReactFocusLock>
    )
  }
)

Content.displayName = 'PopoverContent'
