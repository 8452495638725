import classNames from 'classnames'
import { FC, useMemo } from 'react'

import { useCarouselContext } from './Carousel'
import { CarouselSlideProps } from './types'

export const Slide: FC<CarouselSlideProps> = ({
  children,
  className,
  index,
  lazyload = false,
  lazyloadThreshold = 1,
  ...props
}) => {
  const { slidesInView, selectedSlide, slides, transition } =
    useCarouselContext()

  const hidden = useMemo(
    () => !slidesInView.includes(index),
    [slidesInView, index]
  )

  const inView = useMemo(() => {
    if (!lazyload || slides.length <= lazyloadThreshold * 2 + 1) return true

    const firstInViewIndex = slidesInView[0]
    const lastInViewIndex = slidesInView[slidesInView.length - 1]

    const minIndex =
      (firstInViewIndex - lazyloadThreshold + slides.length) % slides.length
    const maxIndex = (lastInViewIndex + lazyloadThreshold) % slides.length

    return (
      index === selectedSlide ||
      (minIndex <= maxIndex
        ? index >= minIndex && index <= maxIndex
        : index >= minIndex || index <= maxIndex)
    )
  }, [
    lazyload,
    slides.length,
    lazyloadThreshold,
    slidesInView,
    selectedSlide,
    index,
  ])

  const isSelected = selectedSlide === index
  const slide = inView || !lazyload ? children : null

  return (
    <li
      className={classNames(
        'w-full relative',
        className,
        transition === 'fade' &&
          `transition-opacity duration-300 ${
            isSelected ? 'opacity-100 z-10' : 'opacity-0 z-0'
          }`
      )}
      aria-hidden={hidden}
      aria-current={isSelected}
      aria-label={`slide ${index + 1} out of ${slides.length}`}
      {...props}
    >
      {slide}
    </li>
  )
}
