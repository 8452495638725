import classNames from 'classnames'
import { ElementType } from 'react'

import { Transition } from './Transition'
import { AppearProps } from './types'

const defaultElement = 'div'

/**
 * The Appear transition component is a helper to show and hide components instantly using the display property.
 */
export const Appear = <E extends ElementType = typeof defaultElement>({
  as,
  enter,
  exit,
  show,
  className,
  ...props
}: AppearProps<E>): JSX.Element => {
  const element: React.ElementType = defaultElement
  return (
    <Transition
      as={element}
      className={classNames('transition', className)}
      enter={classNames('block', enter)}
      exit={classNames('hidden', exit)}
      show={show}
      {...props}
    />
  )
}
