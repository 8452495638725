export const formatBytes = (bytes: number, decimals = 2) => {
  if (!Number(bytes)) return '0 Bytes'

  const kb = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = [
    'Bytes',
    'KiB',
    'MiB',
    'GiB',
    'TiB',
    'PiB',
    'EiB',
    'ZiB',
    'YiB',
  ]

  const i = Math.floor(Math.log(bytes) / Math.log(kb))

  return `${parseFloat((bytes / kb ** i).toFixed(dm))} ${sizes[i]}`
}
