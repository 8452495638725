import { useMutation } from '@tanstack/react-query'
import { useCartContext } from '../components'
import { useRecentlyAddedProducts } from './useRecentlyAddedProducts'

export const useAddCartItem = ({ useRecentlyAdded = true } = {}) => {
  const { cartManager, recentlyAddedTimer } = useCartContext()

  const setRecentlyAddedProductIds = useRecentlyAddedProducts(
    (state) => state.setRecentlyAddedProductIds
  )

  return useMutation({
    mutationFn: cartManager.addItem,
    onMutate: () => {
      if (useRecentlyAdded) {
        setRecentlyAddedProductIds([])
      }
    },
    onSuccess: (_, { id }) => {
      if (useRecentlyAdded) {
        setRecentlyAddedProductIds([id])
        recentlyAddedTimer.start()
      }
    },
  })
}
