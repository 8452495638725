import { Controller } from 'react-hook-form'

import { CheckboxGroup as BaseCheckboxGroup } from '../../CheckboxGroup'

import type { CheckboxGroupProps } from './types'

export const CheckboxGroup = ({
  name,
  options,
  label,
  ...props
}: CheckboxGroupProps): JSX.Element => {
  return (
    <Controller
      name={name}
      render={({ field, fieldState: { error } }) => (
        <BaseCheckboxGroup
          label={label}
          invalid={Boolean(error)}
          {...field}
          {...props}
        >
          {options.map(({ label, value, disabled, className }) => (
            <BaseCheckboxGroup.Item
              key={value}
              value={value}
              disabled={disabled}
              className={className}
              label={label}
            >
              <span>{label}</span>
            </BaseCheckboxGroup.Item>
          ))}
        </BaseCheckboxGroup>
      )}
    />
  )
}
