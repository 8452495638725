import React from 'react'

import { SvgProps } from '../types'
import { Icon } from '../Icon'

export const FacebookAlt: React.FC<SvgProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <g clipPath="url(#a)">
        <path
          fill="currentColor"
          d="M12 0C5.373 0 0 5.373 0 12c0 5.944 4.326 10.867 9.999 11.82v-9.316H7.104V11.15h2.895V8.68c0-2.868 1.752-4.431 4.31-4.431 1.226 0 2.28.091 2.585.131v2.998l-1.775.001c-1.391 0-1.66.661-1.66 1.632v2.14h3.32l-.432 3.352H13.46V23.9C19.397 23.177 24 18.13 24 11.997 24 5.373 18.627 0 12 0Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </Icon>
  )
}
