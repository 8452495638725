import { Cart } from '../types'

export const getEmptyCart = (): Cart => ({
  totalPrice: 0,
  itemCount: 0,
  itemsSubtotalPrice: 0,
  totalDiscount: 0,
  items: [],
  note: null,
  meta: {},
  currency: window.Shopify.currency.active,
})
