import React from 'react'

import { SvgProps } from '../types'
import { Icon } from '../Icon'

export const Copy: React.FC<SvgProps> = (props) => {
  return (
    <Icon width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M14.5 5V14H5.5V5H14.5ZM14.5 4H5.5C5.23478 4 4.98043 4.10536 4.79289 4.29289C4.60536 4.48043 4.5 4.73478 4.5 5V14C4.5 14.2652 4.60536 14.5196 4.79289 14.7071C4.98043 14.8946 5.23478 15 5.5 15H14.5C14.7652 15 15.0196 14.8946 15.2071 14.7071C15.3946 14.5196 15.5 14.2652 15.5 14V5C15.5 4.73478 15.3946 4.48043 15.2071 4.29289C15.0196 4.10536 14.7652 4 14.5 4Z"
        fill="currentColor"
      />
      <path
        d="M2.5 9H1.5V2C1.5 1.73478 1.60536 1.48043 1.79289 1.29289C1.98043 1.10536 2.23478 1 2.5 1H9.5V2H2.5V9Z"
        fill="currentColor"
      />
    </Icon>
  )
}
