import { ElementType } from 'react'
import classNames from 'classnames'

import { Box } from '../../components/Box'

import { BadgesProps } from './types'

const defaultElement = 'div'

export const Badges = <C extends ElementType = typeof defaultElement>({
  as,
  className,
  ...props
}: BadgesProps<C>) => {
  const element: React.ElementType = as || defaultElement
  return (
    <Box
      as={element}
      className={classNames('product-card__badges', className)}
      {...props}
    />
  )
}
