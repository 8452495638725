import { Text, TextProps } from '@lounge-fe/ui-kit'
import classNames from 'classnames'

export const Subtitle = ({
  as = 'h2',
  children,
  className,
  ...props
}: TextProps<'h2'>) => (
  <Text
    as={as}
    variant="body-lg"
    className={classNames('text-center mb-6', className)}
    dangerouslySetInnerHTML={{ __html: children?.toString() ?? '' }}
    {...props}
  />
)
