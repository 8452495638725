import { Icon, Text } from '@lounge-fe/ui-kit'
import { BasketLineDetailsProps } from './types'
import { Price } from './Price'
import { DiscountAllocation } from './DiscountAllocation'

export const Details = ({
  url,
  isDesktop,
  originalLinePrice,
  finalLinePrice,
  title,
  color,
  size,
  isSet,
  discountAllocations,
  hasOnlyDefaultVariant,
  properties,
}: BasketLineDetailsProps) => {
  return (
    <div className="flex flex-col w-full gap-y-1">
      <a href={url}>
        <Text
          variant={isDesktop ? 'body-md' : 'body-sm'}
          className="text-base-primary font-medium"
        >
          {title}
        </Text>
      </a>
      <div className="grid grid-cols-1 grid-flow-dense [&>*]:col-start-1 gap-1">
        {!isSet && (
          <Price
            originalLinePrice={originalLinePrice}
            finalLinePrice={finalLinePrice}
          />
        )}
        {discountAllocations
          .filter(({ amount }) => amount > 0)
          .map((discountAllocation, index) => (
            <div className={isDesktop ? '!col-start-2' : ''}>
              <DiscountAllocation
                key={index}
                discountAllocation={discountAllocation}
              />
            </div>
          ))}
        <Text variant="body-sm" className="text-[#7D7D7D]">
          {color}
        </Text>
        {!hasOnlyDefaultVariant && (
          <Text variant="body-sm" className="text-[#7D7D7D]">
            {size}
          </Text>
        )}
        {Boolean(properties?.__shopify_send_gift_card_to_recipient) && (
          <>
            <Text
              variant="body-sm"
              className="text-[#7D7D7D] flex gap-x-2 items-center"
            >
              <Icon.Email />
              <span>{properties?.['Recipient email']}</span>
            </Text>
            {properties?.['Send on'] && (
              <Text
                variant="body-sm"
                className="text-[#7D7D7D] flex gap-x-2 items-center"
              >
                <Icon.Calendar />
                <span>
                  {new Date(properties?.['Send on']).toLocaleDateString()}
                </span>
              </Text>
            )}
          </>
        )}
      </div>
    </div>
  )
}
