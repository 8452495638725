import classNames from 'classnames'
import { ElementType } from 'react'

import { Transition } from './Transition'
import { SlideDirection, SlideProps } from './types'

const directionEnterClasses: { [key in SlideDirection]: string } = {
  top: 'animate-slide-in-top',
  right: 'animate-slide-in-right',
  bottom: 'animate-slide-in-bottom',
  left: 'animate-slide-in-left',
}

const directionExitClasses: { [key in SlideDirection]: string } = {
  top: 'animate-slide-out-top',
  right: 'animate-slide-out-right',
  bottom: 'animate-slide-out-bottom',
  left: 'animate-slide-out-left',
}

const defaultElement = 'div'

/**
 * The Slide transition component is a helper to show and hide components using a slide animation from a given direction.
 */
export const Slide = <E extends ElementType = typeof defaultElement>({
  as,
  enter,
  exit,
  direction = 'top',
  show,
  ...props
}: SlideProps<E>): JSX.Element | null => {
  const element: React.ElementType = as || defaultElement
  return (
    <Transition
      as={element}
      enter={classNames(directionEnterClasses[direction], enter)}
      exit={classNames(directionExitClasses[direction], exit)}
      show={show}
      {...props}
    />
  )
}
