export const addDays = (numberOfDays: number, date = new Date()) => {
  const result = new Date(date)
  result.setDate(result.getDate() + numberOfDays)
  return result
}

export const datetimeToISODate = (datetime: Date) => {
  return datetime.toISOString().split('T')[0]
}

export const minusYears = (numberOfYears: number, date = new Date()) => {
  const result = new Date(date)
  result.setFullYear(result.getFullYear() - numberOfYears)
  return result
}
