import { type TFunction } from 'i18next'

type CanonicalColors = {
  label: string
  value: string
  color: string
  checkColor?: string
}[]

export const canonicalColors = {
  black: '#000',
  white: '#FFF',
  cream: '#EAE6E3',
  grey: '#C8C8C8',
  brown: '#88594E',
  orange: '#F5BB6A',
  purple: '#C285E0',
  pink: '#EC6190',
  red: '#B4321A',
  blue: '#2F74B7',
  yellow: '#F0E49C',
  green: '#205005',
}
