type CategoryKeys = 'C0002' | 'C0003' | 'C0004' | 'C0005'
type Categories = 'performance' | 'functional' | 'targeting' | 'socialMedia'

type CategoryKeysMap = {
  [key in CategoryKeys]: Categories
}

type CategoriesMap = {
  [key in Categories]: boolean
}

export const useOneTrust = () => {
  const OpenSettings = () => {
    OneTrust?.ToggleInfoDisplay()
  }

  const Close = () => {
    OneTrust?.Close()
  }

  const AllowAll = () => {
    OneTrust?.AllowAll()
  }

  const RejectAll = () => {
    OneTrust?.RejectAll()
  }

  const IsAlertBoxClosed = () => {
    OneTrust?.IsAlertBoxClosed()
  }

  const IsAlertBoxClosedAndValid = () => {
    OneTrust?.IsAlertBoxClosedAndValid()
  }

  const getActiveGroups = () => {
    const activeGroups =
      typeof OnetrustActiveGroups !== 'undefined'
        ? OnetrustActiveGroups?.split(',')
        : []

    const categories: CategoryKeysMap = {
      C0002: 'performance',
      C0003: 'functional',
      C0004: 'targeting',
      C0005: 'socialMedia',
    }

    return activeGroups.reduce(
      (acc: CategoriesMap, group) => {
        const category = categories[group as CategoryKeys]
        if (category) {
          acc[category] = true
        }
        return acc
      },
      {
        performance: false,
        functional: false,
        targeting: false,
        socialMedia: false,
      } as CategoriesMap
    )
  }

  const hasConsent = (category: Categories) => {
    const activeGroups = getActiveGroups()
    return activeGroups[category]
  }

  return {
    OpenSettings,
    Close,
    AllowAll,
    RejectAll,
    IsAlertBoxClosed,
    IsAlertBoxClosedAndValid,
    getActiveGroups,
    hasConsent,
  }
}
