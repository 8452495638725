import classNames from 'classnames'
import { FC, HTMLAttributes } from 'react'

export const Body: FC<HTMLAttributes<HTMLDivElement>> = ({
  className,
  ...props
}) => {
  return (
    <div
      onPointerDownCapture={(evt) => evt.stopPropagation()}
      className={classNames(
        'px-6 py-4 max-h-[336px] overflow-y-auto',
        className
      )}
      {...props}
    />
  )
}
