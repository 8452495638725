import { useMutation } from '@tanstack/react-query'
import { useCartContext } from '../components'
import { useRecentlyAddedProducts } from './useRecentlyAddedProducts'

export const useAddCartItems = () => {
  const setRecentlyAddedProductIds = useRecentlyAddedProducts(
    ({ setRecentlyAddedProductIds }) => setRecentlyAddedProductIds
  )

  const { cartManager, recentlyAddedTimer } = useCartContext()

  return useMutation({
    mutationFn: cartManager.addItems,
    onMutate: () => {
      setRecentlyAddedProductIds([])
    },
    onSuccess: (_, variables) => {
      setRecentlyAddedProductIds(variables.map((item) => item.id))
      recentlyAddedTimer.start()
    },
  })
}
