import { useTranslation } from 'react-i18next'
import {
  CloseIconButton,
  IconButton,
  Icon,
  Heading,
  Popover,
  Flex,
  Box,
  Transition,
  Portal,
} from '@lounge-fe/ui-kit'
import { AccountMenuContent } from '../AccountMenuContent'

export const AccountMenu = () => {
  const { t } = useTranslation()
  return (
    <Popover>
      {({ onClose }) => (
        <>
          <Popover.Trigger>
            <IconButton
              type="button"
              icon={<Icon.Account />}
              className="!w-9 !h-9 [&_svg]:w-6 [&_svg]:h-6"
              aria-label={t('account.account')}
            />
          </Popover.Trigger>
          <Portal>
            <Transition.Slide
              direction="bottom"
              className="fixed z-50 p-0 inset-0 sm:max-w-[498px] bg-white"
            >
              <Popover.Content>
                <Box className="relative">
                  <Flex className="items-center bg-[#F7F8F5] h-[132px]">
                    <Flex className="justify-center flex-1">
                      <Heading variant="heading-six">
                        {t('account.my_account')}
                      </Heading>
                    </Flex>
                    <Box className="absolute top-6 right-4">
                      <CloseIconButton
                        aria-label={t('action.close')}
                        onClick={onClose}
                      />
                    </Box>
                  </Flex>
                  <Box className="px-6">
                    <AccountMenuContent />
                  </Box>
                </Box>
              </Popover.Content>
            </Transition.Slide>
          </Portal>
        </>
      )}
    </Popover>
  )
}
