import { useCallback, useEffect, useId, useLayoutEffect, useMemo } from 'react'
import { create } from 'zustand'

import { LockedBodyStore, UseLockedBodyResult } from './types'
import { getScrollbarWidth } from './getScrollbarWidth'

const scrollbarWidth = getScrollbarWidth()

const useLockedBodyStore = create<LockedBodyStore>((set, get) => ({
  originalOverflow: null,
  lockedIds: [],
  addLockedId: (id: string) => {
    set({
      lockedIds: [...get().lockedIds, id],
    })
    document.body.classList.add('overflow-hidden')
    document.body.style.paddingRight = `${scrollbarWidth}px`
  },
  removeLockedId: (id: string) => {
    const { lockedIds } = get()
    const newLockedIds = lockedIds.filter((_id) => id !== _id)
    if (!newLockedIds.length) {
      set({ lockedIds: newLockedIds })
      document.body.classList.remove('overflow-hidden')
      document.body.style.paddingRight = `${0}px`
    }
    set({ lockedIds: newLockedIds })
  },
}))

export const useLockedBody = (initialLocked = false): UseLockedBodyResult => {
  const id = useId()

  const { lockedIds, addLockedId, removeLockedId } = useLockedBodyStore()

  const anyLocked = useMemo(() => Boolean(lockedIds.length), [lockedIds])
  const selfLocked = useMemo(() => lockedIds.includes(id), [lockedIds, id])

  const setLocked = useCallback(
    (locked: boolean) => (locked ? addLockedId(id) : removeLockedId(id)),
    [id]
  )

  useEffect(() => {
    setLocked(initialLocked)
  }, [initialLocked])

  useLayoutEffect(() => {
    if (!selfLocked) {
      return
    }

    addLockedId(id)
    return () => {
      removeLockedId(id)
      document.body.style.removeProperty('overflow')
    }
  }, [selfLocked])

  return [{ self: selfLocked, any: anyLocked }, setLocked]
}
