import React from 'react'

import { Icon } from '../../Icon'

import { PaymentProviderSvgProps } from './types'

export const ApplePay: React.FC<PaymentProviderSvgProps> = ({
  borderColor = 'currentColor',
  foregroundColor = 'currentColor',
  fill = 'none',
  ...props
}) => {
  return (
    <Icon width="40" viewBox="0 0 35.19 22.225" {...props}>
      <path
        d="M32.147.265h.264a2.514 2.514 0 0 1 2.514 2.513v16.669a2.514 2.514 0 0 1-2.514 2.513H2.778a2.514 2.514 0 0 1-2.513-2.513V2.778A2.514 2.514 0 0 1 2.778.265Z"
        fill={fill}
        stroke={borderColor}
        strokeWidth=".52916"
      />
      <path
        d="M9.298 7.287c.31-.4.533-.933.466-1.466-.466.022-1.022.31-1.355.689-.289.333-.555.889-.489 1.4.533.066 1.044-.223 1.378-.623zm.466.756c-.755-.045-1.4.422-1.755.422s-.91-.4-1.51-.4A2.228 2.228 0 0 0 4.61 9.221c-.8 1.4-.223 3.466.577 4.6.378.555.844 1.177 1.444 1.155.578-.022.8-.378 1.488-.378.689 0 .889.378 1.51.356.623-.023 1.023-.556 1.4-1.134.444-.644.622-1.266.622-1.288-.022-.023-1.222-.467-1.222-1.845-.022-1.155.934-1.71.978-1.733-.511-.8-1.355-.889-1.644-.911M16.295 6.465c1.644 0 2.777 1.134 2.777 2.756 0 1.644-1.155 2.777-2.821 2.777h-1.8v2.867h-1.31v-8.4Zm-1.843 4.444h1.488c1.133 0 1.777-.622 1.777-1.666 0-1.067-.644-1.667-1.777-1.667h-1.51v3.333zm4.953 2.245c0-1.067.822-1.733 2.288-1.822l1.688-.09v-.466c0-.689-.466-1.089-1.221-1.089-.734 0-1.178.356-1.289.89h-1.2c.067-1.112 1.023-1.934 2.533-1.934 1.488 0 2.444.778 2.444 2.022v4.222h-1.2v-1h-.022c-.356.689-1.133 1.111-1.933 1.111-1.244 0-2.088-.755-2.088-1.844zm3.976-.556v-.489l-1.51.09c-.755.044-1.177.377-1.177.91 0 .534.444.89 1.11.89.889 0 1.577-.6 1.577-1.4zm2.377 4.555v-1.022c.09.023.311.023.4.023.578 0 .889-.245 1.089-.867 0-.022.11-.378.11-.378l-2.22-6.133h1.354l1.555 5h.022l1.555-5h1.333l-2.288 6.466c-.533 1.49-1.133 1.956-2.399 1.956-.089-.022-.4-.022-.51-.045z"
        fill={foregroundColor}
      />
    </Icon>
  )
}
