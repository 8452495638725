import { STORES } from '../stores'
import { useCurrentStore } from './useCurrentStore'
import { useUserStoreCookie } from './useUserStoreCookie'

export const useSetStore = () => {
  const currentStore = useCurrentStore()
  const [, setUserCookie] = useUserStoreCookie()

  return (store: string) => {
    setUserCookie(store)
    if (store !== currentStore.shop) {
      const nextStore = STORES.find(({ shop }) => shop === store)
      if (nextStore) {
        const params = new URLSearchParams(window.location.search)
        params.set('action', 'clear-locale')
        window.location.replace(
          [
            nextStore.domain,
            window.location.pathname,
            params.size > 0 ? `?${params.toString()}` : '',
          ].join('')
        )
      }
    }
  }
}
