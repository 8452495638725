import { RestClient } from '@lounge-fe/network'
import { GetLocationResponse } from './types'

export const GEOLOCATE_BASE_URL =
  'https://llgu0m4wud.execute-api.eu-west-2.amazonaws.com/production'

export class GeolocateService {
  private client: RestClient

  constructor() {
    this.client = new RestClient({
      baseURL: GEOLOCATE_BASE_URL,
      useCSRF: false,
    })
  }

  async getLocation() {
    return this.client.get<GetLocationResponse>('/', null, {
      credentials: 'omit',
      mode: 'cors',
    })
  }
}
