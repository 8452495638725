import React from 'react'

import { SvgProps } from '../types'
import { Icon } from '../Icon'

export const Close: React.FC<SvgProps> = (props) => {
  return (
    <Icon width="14" height="14" viewBox="0 0 14 14" fill="none" {...props}>
      <rect
        width="16.9705"
        height="1.13137"
        rx="0.565684"
        transform="matrix(0.707106 -0.707108 0.707106 0.707108 0.600586 12.6001)"
        fill="currentColor"
      />
      <rect
        width="16.9705"
        height="1.13137"
        rx="0.565684"
        transform="matrix(-0.707106 -0.707107 0.707106 -0.707108 12.6006 13.4001)"
        fill="currentColor"
      />
    </Icon>
  )
}
