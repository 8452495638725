import React from 'react'

import { SvgProps } from '../types'
import { Icon } from '../Icon'

export const CheckCircle: React.FC<SvgProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM16.3938 7.15165C16.9329 6.61612 17.8071 6.61612 18.3463 7.15165C18.8854 7.68718 18.8854 8.55545 18.3463 9.09098L10.5363 16.8483C9.9971 17.3838 9.12294 17.3838 8.58377 16.8483L8.56401 16.8283L5.65486 13.9388C5.1157 13.4032 5.1157 12.535 5.65486 11.9994C6.19403 11.4639 7.0682 11.4639 7.60736 11.9994L9.56022 13.9391L16.3938 7.15165Z"
        fill="currentColor"
      />
    </Icon>
  )
}
