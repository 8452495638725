import { ElementType } from 'react'
import classNames from 'classnames'

import { TextProps } from '../../components/Text'
import { Box } from '../../components/Box'

const defaultElement = 'p'

export const Subtitle = <C extends ElementType = typeof defaultElement>({
  as,
  className,
  ...props
}: TextProps<C>) => {
  const element: React.ElementType = as || defaultElement
  return (
    <Box
      as={element}
      className={classNames('tile__subtitle', className)}
      {...props}
    />
  )
}
