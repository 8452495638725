import { useShopify } from '../useShopify'
import { SignupValues } from './types'

export const useExponea = () => {
  const { shop } = useShopify()

  const identify = (email: string, properties = {}, immediate = true) =>
    new Promise<void>((resolve, reject) => {
      exponea?.identify(
        { email_id: email.toLowerCase().trim() },
        properties,
        resolve,
        reject,
        immediate
      )
    })

  const track = (eventName: string, options: Object) =>
    new Promise<void>((resolve, reject) => {
      exponea?.track(eventName, options, resolve, reject)
    })

  const consent = (category: 'email' | 'sms', accept = true) => {
    const brCategory =
      category === 'email' ? 'Marketing Communications' : 'SMS Consent'
    return track('consent', {
      category: brCategory,
      action: accept ? 'accept' : 'reject',
      timestamp: new Date().toISOString(),
      valid_until: 'unlimited',
    })
  }

  const update = (customerAttributes: Object) => {
    exponea?.update(customerAttributes)
  }

  const events = {
    signup: (values: SignupValues) => {
      const email = values.email.toLowerCase().trim()
      const birthday = values.birthday
        ? new Date(values.birthday).toISOString()
        : undefined

      const customer = {
        first_name: values.firstName,
        last_name: values.lastName,
        birthday,
        gender: values.gender,
      }

      identify(email, { ...customer, domain: shop })

      track('signup', {
        ...customer,
        email,
        tags: values.tags,
        consent: values.acceptsMarketing ? 'on' : 'off',
      })

      if (values.acceptsMarketing) {
        consent('email', true)
        consent('sms', true)
      }
    },
  }

  return {
    identify,
    track,
    consent,
    update,
    events,
  }
}
