import React from 'react'

import { SvgProps } from '../types'
import { Icon } from '../Icon'

export const ChevronDown: React.FC<SvgProps> = (props) => {
  return (
    <Icon fillRule="evenodd" clipRule="evenodd" viewBox="0 0 8 8" {...props}>
      <path
        d="M7.70601 2.04498C7.7645 2.10494 7.7645 2.20217 7.70601 2.26213L4.10509 5.95383C4.0466 6.0138 3.95176 6.0138 3.89327 5.95383C3.83478 5.89387 3.83478 5.79664 3.89327 5.73667L7.49419 2.04498C7.55268 1.98501 7.64752 1.98501 7.70601 2.04498Z"
        fill="currentColor"
      />
      <path
        d="M4.10648 5.95503C4.04799 6.01499 3.95316 6.01499 3.89467 5.95503L0.293746 2.26333C0.235254 2.20336 0.235254 2.10613 0.293746 2.04617C0.352238 1.9862 0.447073 1.9862 0.505565 2.04617L4.10648 5.73787C4.16498 5.79783 4.16498 5.89506 4.10648 5.95503Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.49419 2.04498C7.55268 1.98501 7.64752 1.98501 7.70601 2.04498C7.7645 2.10494 7.7645 2.20217 7.70601 2.26213L4.12172 5.93678C4.11718 5.94316 4.1121 5.94927 4.10648 5.95503C4.10651 5.955 4.10646 5.95505 4.10648 5.95503C4.05103 6.01179 3.96289 6.0148 3.90407 5.96389C3.90081 5.96113 3.89774 5.95811 3.89467 5.95503C3.89424 5.95459 3.8937 5.95427 3.89327 5.95383L3.88838 5.94858L0.293746 2.26333C0.235254 2.20336 0.235254 2.10613 0.293746 2.04617C0.352238 1.9862 0.447073 1.9862 0.505565 2.04617L3.9993 5.62797L7.49419 2.04498ZM3.9993 5.26994L7.31523 1.87041C7.47183 1.70986 7.72837 1.70986 7.88497 1.87041C8.03818 2.02748 8.03818 2.27963 7.88497 2.4367L4.31111 6.10066C4.30309 6.11061 4.29454 6.12027 4.28545 6.12959C4.1373 6.28147 3.89971 6.28967 3.74191 6.15418C3.73241 6.1461 3.7232 6.13751 3.71431 6.12839C3.71158 6.1256 3.70891 6.12278 3.70628 6.11993L0.114783 2.43789C-0.0384243 2.28082 -0.0384244 2.02868 0.114783 1.87161C0.271385 1.71106 0.527927 1.71106 0.684529 1.87161L3.9993 5.26994Z"
        fill="currentColor"
      />
    </Icon>
  )
}
