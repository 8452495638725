import { createCompoundComponent } from '@lounge-fe/ui-kit'

import { Search as BaseSearch } from './Search'
import { Grid } from './Grid'
import { LoadMore } from './LoadMore'
import { Menu } from './Menu'
import { Overlay } from './Overlay'
import { Stats } from './Stats'
import { MenuItem } from './MenuItem'
import { Recommendations } from './Recommendations'

export const Search = createCompoundComponent(BaseSearch, {
  Grid,
  Overlay,
  Stats,
  LoadMore,
  Menu,
  MenuItem,
  Recommendations,
})
