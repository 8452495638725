import { SvgProps } from '../types'
import { Icon } from '../Icon'

export const Box: React.FC<SvgProps> = (props) => {
  return (
    <Icon width="64" height="64" viewBox="0 0 64 64" fill="none" {...props}>
      <circle cx="32" cy="32" r="31.5" stroke="currentColor" />
      <g clipPath="url(#clip0_1403_1909)">
        <path
          d="M32.372 18.2258C32.1332 18.1301 31.8668 18.1301 31.628 18.2258L19.692 22.9998L24.5 24.9218L36.808 19.9998L32.372 18.2258ZM39.5 21.0778L27.192 25.9998L32 27.9218L44.308 22.9998L39.5 21.0778ZM46 24.4778L33 29.6778V45.5218L46 40.3218V24.4798V24.4778ZM31 45.5238V29.6758L18 24.4778V40.3238L31 45.5238ZM30.886 16.3678C31.6011 16.0818 32.3989 16.0818 33.114 16.3678L47.372 22.0718C47.5574 22.1461 47.7163 22.2742 47.8282 22.4396C47.9401 22.605 48 22.8001 48 22.9998V40.3238C47.9997 40.7235 47.8798 41.1139 47.6555 41.4447C47.4313 41.7755 47.1131 42.0316 46.742 42.1798L32.372 47.9278C32.1332 48.0235 31.8668 48.0235 31.628 47.9278L17.26 42.1798C16.8885 42.0319 16.5699 41.776 16.3453 41.4451C16.1207 41.1143 16.0004 40.7237 16 40.3238V22.9998C16 22.8001 16.0599 22.605 16.1718 22.4396C16.2837 22.2742 16.4426 22.1461 16.628 22.0718L30.886 16.3678Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1403_1909">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(16 16)"
          />
        </clipPath>
      </defs>
    </Icon>
  )
}
