import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useService } from '../../../hooks/useService'
import { GeolocateService } from '../services/GeolocateService'
import { DEFAULT_STORE, STORES } from '../stores'
import { GetLocationResponse } from '../services/GeolocateService/types'
import { useUserStoreCookie } from './useUserStoreCookie'

const EU_SHOP_NAME = 'eulounge'
const ROW_SHORT_NAME = 'ROW'

const getMatchingStore = (response: GetLocationResponse) => {
  const exactMatch = STORES.find(({ isoCodes }) =>
    isoCodes.includes(response.country.isoCode)
  )

  if (exactMatch) {
    return exactMatch
  }

  const euStore = STORES.find(({ shop }) => shop === EU_SHOP_NAME)
  if (response.continent.code === 'EU' && euStore) {
    return euStore
  }

  return (
    STORES.find(({ shortName }) => shortName === ROW_SHORT_NAME) ??
    DEFAULT_STORE
  )
}

export const useRecommendedStore = () => {
  const geolocateService = useService(new GeolocateService())
  const [userStoreCookie] = useUserStoreCookie()

  const { data: geolocation } = useQuery({
    queryKey: ['countryCode'],
    queryFn: () => geolocateService.getLocation(),
    enabled: !userStoreCookie,
  })

  return useMemo(
    () => (geolocation ? getMatchingStore(geolocation.data) : undefined),
    [geolocation]
  )
}
