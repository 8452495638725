import { Children, cloneElement, isValidElement } from 'react'
import { useTranslation } from 'react-i18next'

import { SkeletonProps } from './types'

export const Skeleton = ({
  children,
  ...props
}: SkeletonProps): JSX.Element => {
  const { t } = useTranslation()
  return (
    <div {...props}>
      {Children.map(children, (child) =>
        isValidElement(child)
          ? cloneElement(child, {
              'aria-hidden': true,
            })
          : child
      )}
      <span className="sr-only">{t('general.loading')}</span>
    </div>
  )
}
