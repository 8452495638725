import React from 'react'

import { SvgProps } from '../types'
import { Icon } from '../Icon'

export const Truck: React.FC<SvgProps> = (props) => {
  return (
    <Icon width="16" height="14" viewBox="0 0 16 14" fill="none" {...props}>
      <path
        d="M12.416 4.7225C12.323 4.5835 12.167 4.5 12 4.5H11.5C11.2235 4.5 11 4.7235 11 5V8C11 8.2765 11.2235 8.5 11.5 8.5H13.5C13.7765 8.5 14 8.2765 14 8V7.25C14 7.1515 13.9705 7.0545 13.916 6.9725L12.416 4.7225ZM13.5 8H11.5V5H12L13.5 7.25V8Z"
        fill="#111111"
      />
      <path
        d="M15.748 6.668L13.748 3.668C13.469 3.2495 13.002 3 12.5 3H10.5V2C10.5 1.173 9.827 0.5 9 0.5H1.5C0.673 0.5 0 1.173 0 2V7.5C0 8.327 0.673 9 1.5 9V10.5C1.5 11.327 2.173 12 3 12H3.571C3.7945 12.8605 4.571 13.5 5.5005 13.5C6.429 13.5 7.2055 12.8605 7.429 12H10.07C10.2935 12.8605 11.07 13.5 11.9995 13.5C12.928 13.5 13.7045 12.8605 13.928 12H14.5C15.327 12 16 11.327 16 10.5V7.5C16 7.203 15.913 6.915 15.748 6.668ZM1.5 8C1.224 8 1 7.7765 1 7.5V2C1 1.7235 1.224 1.5 1.5 1.5H9C9.2765 1.5 9.5 1.7235 9.5 2V3V4V7.5C9.5 7.7765 9.2765 8 9 8H1.5ZM5.5005 12.5C4.948 12.5 4.5005 12.052 4.5005 11.5C4.5005 10.948 4.948 10.5 5.5005 10.5C6.0525 10.5 6.5005 10.948 6.5005 11.5C6.5005 12.052 6.052 12.5 5.5005 12.5ZM12 12.5C11.4475 12.5 11 12.052 11 11.5C11 10.948 11.4475 10.5 12 10.5C12.552 10.5 13 10.948 13 11.5C13 12.052 12.552 12.5 12 12.5ZM15 10.5C15 10.7765 14.7765 11 14.5 11H13.9285C13.705 10.1395 12.9285 9.5 12 9.5C11.0705 9.5 10.294 10.1395 10.0705 11H7.4295C7.206 10.1395 6.4295 9.5 5.501 9.5C4.5715 9.5 3.795 10.1395 3.5715 11H3C2.724 11 2.5 10.7765 2.5 10.5V9H9C9.827 9 10.5 8.327 10.5 7.5V4H12.5C12.667 4 12.823 4.0835 12.916 4.2225L14.916 7.2225C14.9705 7.3045 15 7.4015 15 7.5V10.5Z"
        fill="#111111"
      />
    </Icon>
  )
}
