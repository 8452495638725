import { useMemo } from 'react'

import { addImageSizeParametersToUrl } from '../../utilities'

import { SourceProps } from './types'

export const Source = ({
  data,
  srcSet: providedSrcSet = data
    ? [
        [{ scale: undefined }, '1x'],
        [{ scale: 2 }, '2x'],
        [{ scale: 3 }, '3x'],
      ]
    : undefined,
  loader = addImageSizeParametersToUrl,
  ...props
}: SourceProps) => {
  const srcSet: string | undefined = useMemo(() => {
    if (data && typeof providedSrcSet === 'object') {
      return providedSrcSet
        .map(([options, size]) => `${loader({ ...data, ...options })} ${size}`)
        .join(', ')
    }
    return providedSrcSet as string | undefined
  }, [data, providedSrcSet])

  return <source srcSet={srcSet} {...props} />
}
