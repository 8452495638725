import { SvgProps } from '../types'
import { Icon } from '../Icon'

export const Minus: React.FC<SvgProps> = (props) => (
  <Icon viewBox="0 0 16 16" height={18} fill="none" {...props}>
    <path
      d="M16 7.9999C16 8.29446 15.7761 8.53324 15.5 8.53324L0.500001 8.53322C0.223859 8.53322 3.25878e-07 8.29444 0 7.99989V7.99989C-3.25878e-07 7.70533 0.223856 7.46655 0.499998 7.46655L15.5 7.46657C15.7761 7.46657 16 7.70535 16 7.9999V7.9999Z"
      fill="black"
    />
  </Icon>
)
