import classNames from 'classnames'
import { FC } from 'react'

import { useCarouselContext } from './Carousel'
import { CarouselViewportProps } from './types'

export const Viewport: FC<CarouselViewportProps> = ({
  className,
  showOverflow,
  ...props
}) => {
  const { emblaRef, viewportId, draggable, dragging } = useCarouselContext()

  return (
    <div
      className={classNames(
        !showOverflow && 'overflow-hidden',
        dragging && !draggable && 'cursor-grab',
        dragging && draggable && '!cursor-grabbing [&_*]:!cursor-grabbing',
        className
      )}
      ref={emblaRef}
      role="region"
      aria-label="carousel"
      id={viewportId}
      {...props}
    />
  )
}

Viewport.displayName = 'CarouselViewport'
