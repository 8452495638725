import classNames from 'classnames'
import { type HTMLAttributes } from 'react'

const Content: React.FC<HTMLAttributes<HTMLDivElement>> = ({
  children,
  className,
  ...props
}) => {
  return (
    <div
      className={classNames('flex flex-col gap-4 p-6', className)}
      {...props}
    >
      {children}
    </div>
  )
}

export { Content }
