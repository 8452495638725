import { SelectStoreModal as RootSelectStoreModal } from './SelectStoreModal'
import { Trigger } from './Trigger'
import { Content } from './Content'
import { LinkTrigger } from './LinkTrigger'

export const SelectStoreModal = Object.assign(RootSelectStoreModal, {
  Trigger,
  Content,
  LinkTrigger,
})
