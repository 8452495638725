import React from 'react'

import { SvgProps } from '../types'
import { Icon } from '../Icon'

export const ArrowDown: React.FC<SvgProps> = (props) => {
  return (
    <Icon viewBox="0 0 33 33" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5932 3.54416C15.5915 3.26802 15.8141 3.04285 16.0902 3.04123C16.3664 3.03961 16.5915 3.26215 16.5931 3.53828L16.7221 25.5007L21.5919 20.3396C21.7814 20.1388 22.0978 20.1296 22.2987 20.3191C22.4995 20.5086 22.5087 20.8251 22.3192 21.0259L16.6644 27.0189C16.5281 27.1634 16.326 27.2087 16.1485 27.1521C15.9717 27.2108 15.7691 27.1679 15.631 27.025L9.90629 21.0988C9.71443 20.9002 9.7199 20.5837 9.91851 20.3918C10.1171 20.2 10.4337 20.2054 10.6255 20.404L15.7232 25.681L15.5932 3.54416Z"
        fill="currentColor"
      />
    </Icon>
  )
}
