const ESCAPE_REGEX = /[/\-\\^$*+?.()|[\]{}]/g

const escapeRegex = (string: string) => {
  return string.replace(ESCAPE_REGEX, '\\$&')
}

export const replaceSchemaVariables = (
  replacements: { [key: string]: string },
  text: string
) => {
  return Object.entries(replacements).reduce((acc, [name, value]) => {
    const regex = new RegExp(`\\$\{\\s*${escapeRegex(name)}\\s*\}`, 'g')
    return acc.replace(regex, value)
  }, text)
}
