import type { ElementType } from 'react'
import classNames from 'classnames'

import { CaptionImageProps } from './types'

export const CaptionImage = <E extends ElementType = 'a'>({
  children,
  ...props
}: CaptionImageProps<E>): JSX.Element => (
  <div className={classNames('captioned-image__caption')} {...props}>
    {children}
  </div>
)
