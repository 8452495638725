import { Children, useLayoutEffect } from 'react'

import { useWizardContext } from './Wizard'
import { WizardStepsProps } from './types'

export const Steps = ({
  children,
  ...props
}: WizardStepsProps): JSX.Element => {
  const { setSteps, activeStep } = useWizardContext()

  useLayoutEffect(() => {
    setSteps(
      Children.map(children, (child) => ({
        title: child.props.title,
        IconComponent: child.props.IconComponent,
      }))
    )
  }, [])

  return (
    <div {...props}>
      {Children.map(children, (child, idx) =>
        idx === activeStep ? child : null
      )}
    </div>
  )
}
