import classNames from 'classnames'
import { motion } from 'framer-motion'
import { Fragment, useId } from 'react'

import { Icon, Text } from '../../components'

import { WizardProgressProps } from './types'
import { useWizardContext } from './Wizard'

const Desktop = ({
  className,
  ...props
}: Omit<WizardProgressProps, 'renderMobileActiveStep'>): JSX.Element => {
  const { steps, activeStep, goto, totalSteps } = useWizardContext()

  return (
    <ol
      className={classNames('justify-between gap-x-4 items-center', className)}
      role="progressbar"
      aria-valuemin={1}
      aria-valuemax={totalSteps}
      aria-valuenow={activeStep + 1}
      {...props}
    >
      {steps.map((step, idx) => {
        const isActive = idx === activeStep
        const isBefore = idx < activeStep
        const isAfter = idx > activeStep
        const isLast = idx === totalSteps - 1

        return (
          <Fragment key={idx}>
            <li>
              <button
                className="flex items-center"
                onClick={() => isBefore && goto(idx)}
                disabled={isAfter || isActive}
                aria-current={isActive && 'step'}
              >
                <Text
                  as="span"
                  className={classNames(
                    'w-8 h-8 rounded-circle flex justify-center items-center mr-4 border font-medium border-border-hovered',
                    (isActive || isBefore) &&
                      'bg-action-primary-default text-white border-transparent'
                  )}
                >
                  {isBefore ? <Icon.Check className="w-4" /> : idx + 1}
                </Text>
                <Text as="span" className="font-medium">
                  {step.title}
                </Text>
              </button>
            </li>

            {!isLast && (
              <li className="flex-1">
                <hr className="w-full border-border-hovered" />
              </li>
            )}
          </Fragment>
        )
      })}
    </ol>
  )
}

const Mobile = ({
  className,
  renderMobileActiveStep,
  ...props
}: WizardProgressProps): JSX.Element | null => {
  const a11yId = useId()

  const labelId = `${a11yId}-label`
  const descriptionId = `${a11yId}-desc`

  const { steps, activeStep, totalSteps } = useWizardContext()
  const step = steps[activeStep]

  if (!step) {
    return null
  }

  return (
    <div className={classNames('flex-col gap-y-4', className)} {...props}>
      <div className="flex items-center gap-x-2">
        <div className="w-10 h-10 rounded-circle border border-background-primary-hovered flex justify-center items-center">
          {step.IconComponent && <step.IconComponent className="w-4" />}
        </div>
        <div className="flex flex-col">
          <Text className="text-[#7D7D7D]" id={labelId}>
            {renderMobileActiveStep(activeStep + 1, totalSteps)}
          </Text>
          <Text variant="body-lg" className="font-medium" id={descriptionId}>
            {step.title}
          </Text>
        </div>
      </div>
      <div className="w-full bg-background-primary-default h-1 rounded-circle">
        <motion.div
          role="progressbar"
          aria-labelledby={labelId}
          aria-describedby={descriptionId}
          aria-valuemin={1}
          aria-valuemax={totalSteps}
          aria-valuenow={activeStep + 1}
          layout
          className="h-full bg-background-tertiary-pressed"
          style={{ width: `${((activeStep + 1) * 100) / totalSteps}%` }}
        />
      </div>
    </div>
  )
}

export const Progress = ({
  className,
  renderMobileActiveStep,
  ...props
}: WizardProgressProps): JSX.Element => (
  <>
    <Desktop
      data-testid="desktop-wizard-progress"
      className={classNames('hidden md:flex', className)}
      {...props}
    />
    <Mobile
      data-testid="mobile-wizard-progress"
      className={classNames('flex md:hidden', className)}
      renderMobileActiveStep={renderMobileActiveStep}
      {...props}
    />
  </>
)
