import { ShopifySectionComponent } from '@/types/ShopifySectionComponent'
import { Heading, Icon, Text, Accordion } from '@lounge-fe/ui-kit'
import classNames from 'classnames'

import { AccordionData } from './types'

const iconContainerClasses =
  'bg-base-primary text-white w-8 h-8 md:w-10 md:h-10 rounded-circle flex justify-center items-center'

export const TextAccordion: ShopifySectionComponent<AccordionData> = ({
  data,
}) => {
  const { items, spacer, wrapperSize, title, text, footer } = data! ?? {}

  return (
    <>
      <div
        className={classNames('wrapper text-accordion ', wrapperSize, spacer)}
      >
        {title && (
          <Heading variant="title-four" className="mb-6">
            {title}
          </Heading>
        )}
        {text && (
          <Text
            className="mb-6"
            variant="body-md"
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          ></Text>
        )}
        <Accordion
          iconPosition="end"
          openIcon={
            <div className={iconContainerClasses}>
              <Icon.ChevronUp className="w-3 h-3 rotate-0" />
            </div>
          }
          closedIcon={
            <div className={iconContainerClasses}>
              <Icon.ChevronUp className="w-3 h-3 rotate-180" />
            </div>
          }
          className="gap-6"
        >
          {items.map((item, idx) => {
            const { text, title } = item

            return (
              <div
                className="!border border-border-default rounded-[30px] lg:rounded-[40px] py-3 px-4 lg:py-3 lg:px-6"
                key={idx}
              >
                <Accordion.Item
                  key={idx}
                  className="!p-0"
                  title={
                    <Heading variant="title-six" className="text-left">
                      {title}
                    </Heading>
                  }
                >
                  <div dangerouslySetInnerHTML={{ __html: text }} />
                </Accordion.Item>
              </div>
            )
          })}
        </Accordion>
        {footer && (
          <Text
            className="pt-14 mb-6 text-center"
            variant="body-md"
            dangerouslySetInnerHTML={{
              __html: footer,
            }}
          ></Text>
        )}
      </div>
    </>
  )
}

export default TextAccordion
