import { createCompoundComponent } from '../../utilities'

import { CaptionImage as BaseCaptionImage } from './CaptionImage'
import { Caption } from './Caption'
import { Container } from './Container'

export const CaptionImage = createCompoundComponent(BaseCaptionImage, {
  Caption,
  Container,
})
