import React, { useId } from 'react'

import { SvgProps } from '../types'
import { Icon } from '../Icon'

export const TikTok: React.FC<SvgProps> = (props) => {
  const id = useId()
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <g fill="currentColor" clipPath={`url(#${id})`}>
        <path d="M9.2719 9.4517v-.9352a6.9002 6.9002 0 0 0-.9812-.0844c-3.1819-.0067-5.999 2.0552-6.9544 5.0903-.9555 3.035.1723 6.3387 2.7842 8.156-1.9565-2.0938-2.4997-5.1421-1.387-7.7829 1.1125-2.6408 3.6733-4.3813 6.5384-4.4438Z" />
        <path d="M9.4482 20.0458c1.779-.0024 3.2414-1.404 3.3193-3.1813V1.0042h2.8976A5.4262 5.4262 0 0 1 15.5808 0h-3.9632v15.845c-.0659 1.7863-1.5317 3.2013-3.3192 3.2042a3.3719 3.3719 0 0 1-1.5332-.3833 3.3266 3.3266 0 0 0 2.683 1.3799ZM21.0771 6.3855V5.504a5.4118 5.4118 0 0 1-2.9973-.9046 5.4963 5.4963 0 0 0 2.9973 1.7861Z" />
        <path d="M18.0798 4.5995a5.4582 5.4582 0 0 1-1.3569-3.603h-1.0578a5.489 5.489 0 0 0 2.4147 3.603Zm-9.7891 7.796c-1.5267.0078-2.8521 1.0537-3.2147 2.5367-.3626 1.483.3307 3.0224 1.6816 3.7338a3.3193 3.3193 0 0 1 2.6906-5.2663c.3327.0041.6631.0558.9812.1533V9.5208a6.9605 6.9605 0 0 0-.9812-.0766h-.1763v3.0662a3.412 3.412 0 0 0-.9812-.1149Z" />
        <path d="M21.077 6.3857V9.452a9.406 9.406 0 0 1-5.4963-1.786v8.0565c-.0084 4.0202-3.2698 7.2748-7.29 7.2748a7.2135 7.2135 0 0 1-4.1702-1.3185c2.0362 2.1899 5.205 2.9108 7.9882 1.8172 2.7831-1.0937 4.6135-3.7789 4.6142-6.7692V8.6931a9.421 9.421 0 0 0 5.504 1.7631V6.5084a5.6196 5.6196 0 0 1-1.1499-.1227Z" />
        <path d="M15.5808 15.7224V7.6657a9.4055 9.4055 0 0 0 5.5039 1.7631V6.3626a5.4961 5.4961 0 0 1-3.0049-1.7631A5.4875 5.4875 0 0 1 15.6651.9966h-2.8976v15.868a3.3269 3.3269 0 0 1-2.3626 3.0487 3.3269 3.3269 0 0 1-3.655-1.232c-1.3509-.7114-2.0441-2.2508-1.6816-3.7338.3626-1.483 1.688-2.5289 3.2147-2.5367a3.412 3.412 0 0 1 .9812.1533V9.4978c-2.8811.0489-5.4612 1.7953-6.577 4.452-1.1158 2.6567-.5566 5.7217 1.4257 7.8131a7.2135 7.2135 0 0 0 4.1778 1.2342c4.0202 0 7.2816-3.2545 7.2901-7.2747Z" />
      </g>
      <defs>
        <clipPath id={id}>
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </Icon>
  )
}
