import { SizeGuideSectionProps } from './types'

export const Section = ({
  title,
  description,
  children,
}: SizeGuideSectionProps) => {
  return (
    <section className="w-full mb-10">
      <div className="flex flex-col mb-6 gap-y-6">
        {title}
        {description}
      </div>
      <div className="overflow-x-auto [&_table]:whitespace-nowrap relative">
        {children}
      </div>
    </section>
  )
}
