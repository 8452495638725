import classNames from 'classnames'
import { createContext, forwardRef, useContext, useMemo } from 'react'

import { CheckboxGroupContextState, CheckboxGroupProps } from './types'

const CheckboxGroupContext = createContext<CheckboxGroupContextState>(
  {} as CheckboxGroupContextState
)

export const CheckboxGroup = forwardRef<
  HTMLFieldSetElement,
  CheckboxGroupProps
>(
  (
    {
      name,
      label,
      children,
      value = [],
      onChange,
      required,
      invalid,
      ...props
    },
    ref
  ) => {
    const handleChange = (itemValue: unknown) => {
      if (value.includes(itemValue)) {
        onChange?.(value.filter((value) => value !== itemValue))
      } else {
        onChange?.([...value, itemValue])
      }
    }

    const context = useMemo(
      () => ({
        name,
        value,
        onChange: handleChange,
      }),
      [name, value, onChange]
    )

    return (
      <CheckboxGroupContext.Provider value={context}>
        <fieldset
          className={classNames(
            'checkbox-group',
            invalid && 'checkbox-group--invalid'
          )}
          ref={ref}
          {...props}
        >
          {label && <legend>{required ? `${label}*` : label}</legend>}
          {children}
        </fieldset>
      </CheckboxGroupContext.Provider>
    )
  }
)

CheckboxGroup.displayName = 'CheckboxGroup'

export const useCheckboxGroupContext = () => useContext(CheckboxGroupContext)
