import * as zod from 'zod'

export enum GenderValue {
  Male = 'Male',
  Female = 'Female',
  PreferNotToSay = 'Prefer not to say',
}

export type DetailsValues = zod.infer<typeof details>
export const details = zod.object({
  firstName: zod.string().min(1),
  lastName: zod.string().min(1),
  email: zod.string().email(),
  phone: zod.string().nullable(),
  birthday: zod.string().nullable(),
  gender: zod
    .string()
    .refine((arg) => Object.values(GenderValue).includes(arg as GenderValue))
    .nullable(),
})

export type AddressValues = zod.infer<typeof address>
export const address = zod.object({
  address1: zod.string().min(1),
  address2: zod.string().nullable(),
  city: zod.string().min(1),
  country: zod.string().min(1),
  firstName: zod.string().min(1),
  lastName: zod.string().min(1),
  zip: zod.string().min(1),
})
