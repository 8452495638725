import classNames from 'classnames'

import { Grid } from '../../components/Box'

import { PromoCardProps } from './types'

export const PromoCard = ({
  orientation,
  href,
  children,
  ...props
}: PromoCardProps): JSX.Element => (
  <Grid
    as="a"
    href={href}
    className={classNames('promo-card', {
      'promo-card--orientation--vertical': orientation === 'vertical',
      'promo-card--orientation--horizontal': orientation === 'horizontal',
    })}
    {...props}
  >
    {children}
  </Grid>
)
