import React from 'react'

import { Icon } from '../../Icon'

import { PaymentProviderSvgProps } from './types'

export const Visa: React.FC<PaymentProviderSvgProps> = ({
  borderColor = 'currentColor',
  foregroundColor = 'currentColor',
  fill = 'none',
  ...props
}) => {
  return (
    <Icon viewBox="0 0 35.19 22.225" width="40" {...props}>
      <path
        d="M32.147.265h.264a2.514 2.514 0 0 1 2.514 2.513v16.669a2.514 2.514 0 0 1-2.514 2.513H2.778a2.514 2.514 0 0 1-2.513-2.513V2.778A2.514 2.514 0 0 1 2.778.265Z"
        fill={fill}
        stroke={borderColor}
        strokeWidth=".52916"
      />
      <path
        d="m14.669 7.016-1.358 7.946h2.172l1.36-7.946zM11.49 7.024l-2.128 5.42-.227-.819c-.42-.986-1.61-2.401-3.009-3.294l1.945 6.627 2.299-.004 3.42-7.931z"
        fill={foregroundColor}
      />
      <path
        d="M8.31 7.593c-.126-.484-.492-.628-.946-.645H3.997l-.028.158c2.62.634 4.355 2.161 5.074 3.998zM21.244 8.54a4.02 4.02 0 0 1 1.626.303l.196.092.294-1.722a5.62 5.62 0 0 0-1.946-.334c-2.146 0-3.66 1.078-3.671 2.623-.014 1.14 1.078 1.778 1.902 2.159.847.39 1.13.637 1.127.986-.007.531-.675.775-1.3.775-.87 0-1.331-.12-2.045-.416l-.28-.127-.305 1.779c.508.222 1.446.413 2.42.423 2.283 0 3.767-1.065 3.783-2.715.01-.903-.57-1.592-1.825-2.158-.76-.368-1.225-.613-1.22-.985 0-.33.394-.684 1.244-.684zM28.934 7.024h-1.678c-.521 0-.909.142-1.138.66l-3.225 7.282h2.281s.372-.979.456-1.193l2.782.003c.065.277.265 1.19.265 1.19h2.015Zm-2.679 5.122c.18-.456.866-2.22.866-2.22-.012.021.177-.46.29-.76l.146.686.503 2.294z"
        fill={foregroundColor}
      />
    </Icon>
  )
}
