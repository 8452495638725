import React from 'react'

import { FileUpload } from './FileUpload'
import { Form as RootForm } from './Form'
import { Checkbox } from './Checkbox'
import { ChoiceChips } from './ChoiceChips'
import { Input } from './Input'
import { Password } from './Password'
import { RadioGroup } from './RadioGroup'
import { Select } from './Select'
import { Submit } from './Submit'
import { PhoneInput } from './PhoneInput'
import { CheckboxGroup } from './CheckboxGroup'

// Used Object.assign here instead of createCompoundComponent to preserve
// generic type information
export const Form = Object.assign(RootForm, {
  Checkbox,
  ChoiceChips,
  FileUpload,
  Input,
  PhoneInput,
  Password,
  Select,
  Submit,
  RadioGroup,
  CheckboxGroup,
})

export type { FormProps, FormSubmitHandler } from './types'
