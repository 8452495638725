import { FieldValues } from 'react-hook-form'

import { Form } from '../../components'

import { useWizardContext } from './Wizard'
import { WizardStepProps } from './types'

export const Step = <TValues extends FieldValues>({
  children,
  ...props
}: WizardStepProps<TValues>) => {
  const { next, values } = useWizardContext()
  return (
    <Form<TValues> onSubmit={next} defaultValues={values} {...props}>
      {children}
    </Form>
  )
}
