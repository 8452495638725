import classNames from 'classnames'
import { forwardRef } from 'react'

import { ToggleGroup, ToggleGroupProps } from '../ToggleGroup'

export const ChoiceChips = forwardRef<HTMLDivElement, ToggleGroupProps>(
  ({ className, ...props }, ref) => (
    <ToggleGroup
      className={classNames('choice-chips', className)}
      ref={ref}
      {...props}
    />
  )
)

ChoiceChips.displayName = 'ChoiceChips'
