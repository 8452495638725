import { storefrontClient } from '@/lib'
import { ClientResponse } from '@shopify/storefront-api-client'
import { useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  getDataTranslationsMetaobjects,
  GetDataTranslationsMetaobjectsResponse,
} from './query'

const LOCALSTORAGE_KEY = 'data_translations'
const normalise = (key: string) =>
  key.toLowerCase().trim().replaceAll(/ /g, '_')

export const useDataTranslation = () => {
  const { t } = useTranslation()

  const [initialData] = useState(() => {
    try {
      const storedData = window.localStorage.getItem(LOCALSTORAGE_KEY)
      if (storedData) {
        return JSON.parse(storedData)
      }
      return undefined
    } catch {
      return undefined
    }
  })

  const { data, isLoading } = useQuery<
    ClientResponse<GetDataTranslationsMetaobjectsResponse>
  >({
    queryKey: ['data_translations'],
    queryFn: () => {
      return storefrontClient.request<GetDataTranslationsMetaobjectsResponse>(
        getDataTranslationsMetaobjects
      )
    },
    initialData,
  })

  useEffect(() => {
    if (data?.data?.metaobjects) {
      window.localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(data))
    }
  }, [data])

  return {
    isLoading,
    t: (key: string) => {
      const normalisedKey = normalise(key)
      const { nodes = [] } = data?.data?.metaobjects || {}
      const metaobject = nodes.find(
        ({ english }) => normalise(english.value) === normalisedKey
      )

      return metaobject?.localised.value ?? t(key, { defaultValue: key })
    },
  }
}
