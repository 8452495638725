import React from 'react'

import { SvgProps } from '../../types'
import { Icon } from '../../Icon'

export const CA: React.FC<SvgProps> = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="512"
      height="512"
      viewBox="0 0 512 512"
      {...props}
    >
      <path
        fill="#d80027"
        d="M0 0v512h144l112-64 112 64h144V0H368L256 64 144 0Z"
      />
      <path fill="#eee" d="M144 0h224v512H144Z" />
      <path
        fill="#d80027"
        d="m301 289 44-22-22-11v-22l-45 22 23-44h-23l-22-34-22 33h-23l23 45-45-22v22l-22 11 45 22-12 23h45v33h22v-33h45z"
      />
    </Icon>
  )
}
