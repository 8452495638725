import { useTranslation } from 'react-i18next'

import { IconButton as BaseIconButton } from '../../components/IconButton'
import { Icon } from '../../components/Icon'
import { IconButtonProps } from '../../components/IconButton/types'

export const IconButton = (props: Partial<IconButtonProps>) => {
  const { t } = useTranslation()
  return (
    <BaseIconButton
      icon={<Icon.Upload />}
      aria-label={t('action.share')}
      {...props}
    />
  )
}
