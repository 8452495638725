import { Controller } from 'react-hook-form'

import { FileUpload as BaseFileUpload } from '../../FileUpload'

import { FileUploadProps } from './types'

export const FileUpload = ({
  name,
  ...props
}: FileUploadProps): JSX.Element => (
  <Controller
    name={name}
    render={({ field, fieldState }) => (
      <BaseFileUpload
        onDropAccepted={(files) => field.onChange(files)}
        onDropRejected={() => field.onChange(null)}
        {...props}
      />
    )}
  />
)
