import classNames from 'classnames'
import { useId } from 'react'
import { useTranslation } from 'react-i18next'

import { Icon } from '../Icon'
import { IconButton } from '../IconButton'
import { Text } from '../Text'

import { QuantityControlsProps } from './types'

export const QuantityControls = ({
  className,
  renderValue = (value) => <Text>{value}</Text>,
  value,
  onChange,
  isLoading,
  max = Number.MAX_SAFE_INTEGER,
  min = -Number.MAX_SAFE_INTEGER,
  ...props
}: QuantityControlsProps) => {
  const inputId = useId()
  const { t } = useTranslation()
  return (
    <div className={classNames('flex gap-x-3 h-6', className)}>
      <input
        type="hidden"
        readOnly
        id={inputId}
        onChange={(evt) => onChange?.(evt.currentTarget.valueAsNumber)}
        {...props}
      />
      <IconButton
        className="w-6 h-6 border border-border-default rounded-circle"
        aria-controls={inputId}
        aria-label={t('action.remove')}
        onClick={() => onChange?.(value - 1)}
        disabled={isLoading || value <= min}
        icon={<Icon.Minus />}
      />
      <div className="border border-border-default rounded-circle min-w-[78px] flex justify-center items-center relative">
        {renderValue(value)}
        {isLoading && (
          <div className="absolute inset-0 bg-white opacity-90 flex justify-center items-center overflow-hidden rounded-circle">
            <Icon.Loading className="animate-spin w-3 h-3" />
          </div>
        )}
      </div>
      <IconButton
        className="w-6 h-6 border border-border-default rounded-circle"
        aria-controls={inputId}
        aria-label={t('action.add')}
        onClick={() => onChange?.(value + 1)}
        icon={<Icon.Plus variant="light" />}
        disabled={isLoading || value >= max}
      />
    </div>
  )
}
