import { Children, useEffect } from 'react'
import useEmblaCarousel from 'embla-carousel-react'

import { Box, Text, Carousel, usePopoverContext } from '@lounge-fe/ui-kit'

import { TileCarouselProps } from './types'

export const TileCarousel = ({ title, children }: TileCarouselProps) => {
  const [emblaRef, embla] = useEmblaCarousel({
    align: 'start',
    containScroll: 'trimSnaps',
    skipSnaps: true,
    draggable: true,
  })

  const { isOpen } = usePopoverContext()
  useEffect(() => {
    if (isOpen) {
      requestAnimationFrame(() => {
        embla?.reInit()
      })
    }
  }, [embla, isOpen])

  return (
    <Box className="py-8 bg-[#F7F8F5]">
      <Text variant="title-six" className="mx-6 mb-4">
        {title}
      </Text>
      <Carousel emblaRef={emblaRef} embla={embla}>
        <Carousel.Viewport className="px-6">
          <Carousel.Container className="gap-4 auto-cols-[calc(66.6%_+_8px)]">
            {Children.map(children, (child, idx) => (
              <Carousel.Slide key={idx} index={idx}>
                {child}
              </Carousel.Slide>
            ))}
          </Carousel.Container>
        </Carousel.Viewport>
      </Carousel>
    </Box>
  )
}
