import React from 'react'

import { SvgProps } from '../../types'
import { Icon } from '../../Icon'

export const DK: React.FC<SvgProps> = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="512"
      height="512"
      viewBox="0 0 512 512"
      {...props}
    >
      <path
        fill="#d80027"
        d="M0 0h133.6l32.7 20.3 34-20.3H512v222.6L491.4 256l20.6 33.4V512H200.3l-31.7-20.4-35 20.4H0V289.4l29.4-33L0 222.7z"
      />
      <path
        fill="#eee"
        d="M133.6 0v222.6H0v66.8h133.6V512h66.7V289.4H512v-66.8H200.3V0h-66.7z"
      />
    </Icon>
  )
}
