import classNames from 'classnames'
import { motion } from 'framer-motion'
import { FC } from 'react'

import { useCarouselContext } from '../Carousel'

import { CarouselProgressProps } from './types'

export const Progress: FC<CarouselProgressProps> = ({
  className,
  ...props
}) => {
  const { embla, slides, selectedSlide, viewportId } = useCarouselContext()
  const progress = Math.max(0, Math.min(1, embla?.scrollProgress() ?? 0))

  return (
    <fieldset
      className={classNames(
        'relative h-1 border-b border-border-default cursor-pointer flex',
        className
      )}
      aria-controls={viewportId}
      {...props}
    >
      <motion.div
        layout="position"
        role="presentation"
        style={{
          width: `${100 / slides.length}%`,
          left: `${(selectedSlide * 100) / slides.length}%` || 0,
        }}
        className="progress-indicator absolute h-1 bg-base-primary"
      />
      {Array(slides.length)
        .fill(0)
        .map((_, idx) => (
          <button
            key={idx}
            className="flex-1"
            aria-current={selectedSlide === idx}
            aria-label={`Go to slide ${idx + 1}`}
            onClick={() => embla?.scrollTo(idx)}
          />
        ))}
    </fieldset>
  )
}
