import React from 'react'

import { SvgProps } from '../types'
import { Icon } from '../Icon'

export const Information: React.FC<SvgProps> = ({
  fill = 'currentColor',
  ...props
}) => {
  return (
    <Icon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="12" cy="12" r="12" fill={fill} />
      <path
        d="M11.85 9.80835C11.2977 9.80835 10.85 10.2429 10.85 10.779V17.0294C10.85 17.5655 11.2977 18 11.85 18C12.4023 18 12.85 17.5654 12.85 17.0294V10.779C12.85 10.2429 12.4023 9.80835 11.85 9.80835Z"
        fill="white"
      />
      <path
        d="M11.85 8.62067C12.5956 8.62067 13.2 8.03401 13.2 7.31033C13.2 6.58666 12.5956 6 11.85 6C11.1044 6 10.5 6.58666 10.5 7.31033C10.5 8.03401 11.1044 8.62067 11.85 8.62067Z"
        fill="white"
      />
    </Icon>
  )
}
