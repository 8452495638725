import { PropsWithChildren, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

import { SELECTORS } from '../../constants'

import { PortalProps } from './types'

export const Portal = <T extends HTMLElement = HTMLDivElement>({
  containerRef,
  children,
}: PropsWithChildren<PortalProps<T>>) => {
  const [container, setContainer] = useState<Element | null>(() => {
    if (typeof document === 'undefined') {
      return null
    }

    return (
      containerRef?.current ??
      document.getElementById(SELECTORS.PORTAL_ROOT) ??
      document.body
    )
  })

  useEffect(() => {
    setContainer(
      containerRef?.current ??
        document.getElementById(SELECTORS.PORTAL_ROOT) ??
        document.body
    )
  }, [])

  if (!container) {
    return null
  }

  return createPortal(children, container)
}
