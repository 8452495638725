import { cloneElement, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Popover } from '../../components/Popover'
import { Text } from '../../components/Text'
import { Transition } from '../../components/Transition'
import { Icon } from '../../components/Icon'

import { ShareSource } from './ShareSource'
import { ShareProps } from './types'

export const Share = ({
  title,
  text,
  url,
  children,
  copyTextTimeout = 2000,
}: ShareProps) => {
  const { t } = useTranslation()
  const hasNavigatorShare = Boolean(navigator.share)
  const hasNavigatorClipboard = Boolean(navigator.clipboard)

  const [copied, setCopied] = useState(false)
  const timeout = useRef<number | null>(null)

  const copyToClipboard = () => {
    navigator.clipboard.writeText(url)
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, copyTextTimeout)
  }

  useEffect(() => {
    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current)
      }
    }
  }, [])

  if (hasNavigatorShare) {
    const onClick = () => {
      if (navigator.share) {
        navigator.share({
          title,
          text,
          url,
        })
      }
    }

    return cloneElement(children, { onClick })
  } else {
    return (
      <Popover>
        {({ onClose }) => (
          <div className="relative">
            <Popover.Trigger>{cloneElement(children)}</Popover.Trigger>
            <Transition.Fade className="top-full absolute right-0 mt-3">
              <Popover.Content
                onClickAway={(evt) => {
                  onClose()
                  evt.preventDefault()
                  evt.stopPropagation()
                }}
                className="overflow-hidden mb-2 rounded-sm bg-white shadow-1 p-4 min-w-[150px]"
              >
                <Text variant="title-six" className="mb-2">
                  {t('action.share')}
                </Text>
                {hasNavigatorClipboard && (
                  <ShareSource
                    text={
                      copied ? `${t('general.copied')}!` : t('action.copy_link')
                    }
                    icon={<Icon.Link height={16} width={16} />}
                    onClick={copyToClipboard}
                    iconClassName="bg-background-primary-default"
                  />
                )}
                <ShareSource
                  text="Twitter"
                  icon={
                    <Icon.Twitter
                      height={16}
                      width={16}
                      className="text-white"
                    />
                  }
                  href={`https://twitter.com/intent/tweet?text=${title}&url=${url}`}
                  iconClassName="bg-twitter"
                />
                <ShareSource
                  text="Facebook"
                  icon={
                    <Icon.Facebook
                      height={16}
                      width={16}
                      className="text-white"
                    />
                  }
                  href={`http://www.facebook.com/share.php?u=${url}`}
                  iconClassName="bg-facebook"
                />
                <ShareSource
                  text="Pinterest"
                  icon={
                    <Icon.Pinterest
                      className="text-white"
                      height={16}
                      width={16}
                    />
                  }
                  href={`http://pinterest.com/pin/create/button/?url=${url}&description=${title}`}
                  iconClassName="bg-pintrest"
                />
                <ShareSource
                  text="Email"
                  icon={<Icon.Email height={16} width={16} />}
                  href={`mailto:?subject=${title}&body=${url}`}
                  iconClassName="bg-background-primary-default"
                />
              </Popover.Content>
            </Transition.Fade>
          </div>
        )}
      </Popover>
    )
  }
}
