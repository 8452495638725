import { ChoiceChips, Icon } from '../../../components'

import { ChoiceChipFieldItemProps, ChoiceChipsFieldProps } from './types'
import { useQuickAddSubmitStatus } from './useQuickAddSubmitStatus'

const ChoiceChipFieldItem = ({
  value,
  variantId,
  disabled,
  onValueSelect,
}: ChoiceChipFieldItemProps) => {
  const [status, setStatus] = useQuickAddSubmitStatus()

  const handleClick = async () => {
    setStatus('loading')
    try {
      const { success } = await onValueSelect(variantId)

      if (success) {
        setStatus('success')
        return
      }

      setStatus('idle')
    } catch (error) {
      setStatus('idle')
    }
  }

  return (
    <ChoiceChips.Item
      className="flex items-center justify-center"
      value={variantId!.toString()}
      disabled={disabled || status === 'loading'}
      onClick={handleClick}
      enableDisabledStyles={status !== 'loading'}
    >
      {status === 'idle' && value}
      {status === 'loading' && (
        <Icon.Loading
          width={18}
          height={18}
          fill="black"
          className="animate-spin"
        />
      )}
      {status === 'success' && (
        <Icon.CheckCircle width={18} height={18} className="max-w-full" />
      )}
    </ChoiceChips.Item>
  )
}

export const ChoiceChipsField = ({
  onValueSelect,
  values,
}: ChoiceChipsFieldProps) => {
  return (
    <ChoiceChips
      className="grid grid-cols-[repeat(auto-fit,minmax(min(100%/2,max(64px,100%/5)),1fr))]"
      preventEmpty
      value={undefined}
      onChange={() => {}}
    >
      {values.map(({ value, variantId, disabled }) => (
        <ChoiceChipFieldItem
          key={value}
          value={value}
          variantId={variantId!}
          disabled={disabled ?? false}
          onValueSelect={onValueSelect}
        />
      ))}
    </ChoiceChips>
  )
}
