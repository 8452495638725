import { SvgProps } from '../types'
import { Icon } from '../Icon'

export interface plusIconProps extends SvgProps {
  variant?: 'light' | 'regular'
}

export const Plus: React.FC<plusIconProps> = ({
  variant = 'regular',
  ...props
}) => (
  <Icon viewBox="0 0 18 18" height={18} fill="none" {...props}>
    {variant === 'light' && (
      <path
        d="M9 17V9M9 9V1M9 9H17M9 9H1"
        stroke="black"
        strokeWidth="1.33333"
        strokeLinecap="round"
      />
    )}
    {variant === 'regular' && (
      <path
        d="M1 9H9M9 9H17M9 9V1M9 9V17"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5"
      />
    )}
  </Icon>
)
