import { FC, useMemo } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import { Image, Text, useMediaQuery } from '@lounge-fe/ui-kit'

import { BasketLineProps } from '../types'
import { Price } from './Price'
import { Quantity } from './Quantity'
import { RemoveButton } from './RemoveButton'
import { Details } from './Details'
import { DiscountAllocation } from './DiscountAllocation'

export const BasketLine: FC<BasketLineProps> = ({
  id,
  title: lineTitle,
  items,
  isSet,
  finalLinePrice = 0,
  originalLinePrice = 0,
  className,
  setCount = 1,
}) => {
  const { t } = useTranslation()
  const isDesktop = useMediaQuery(({ screens }) => `(min-width: ${screens.sm})`)
  const lineItemKeys = useMemo(() => items.map(({ key }) => key), [items])
  const url = items[0]?.url

  return (
    <div
      className={classNames('flex flex-col w-full', className)}
      data-testid={`line-${id}`}
    >
      {lineTitle && (
        <Text variant="body-md" className="mb-3">
          {lineTitle}
        </Text>
      )}
      <div className="flex flex-col gap-y-4">
        {items.map(
          (
            {
              id: productId,
              title,
              color,
              size,
              featuredImage,
              discountAllocations,
              hasOnlyDefaultVariant,
              properties,
            },
            index
          ) => {
            const isLast = index === items.length - 1
            return (
              <div
                key={productId}
                className="grid grid-cols-[auto_1fr] gap-3"
                data-testid={`line-item-${productId}`}
              >
                <a href={url}>
                  {featuredImage ? (
                    <Image
                      data={{
                        src: featuredImage.url,
                        width: 118,
                        height: 118,
                        crop: 'center',
                      }}
                      alt={featuredImage.alt}
                      className="object-cover w-[118px] h-[118px] aspect-square"
                    />
                  ) : (
                    <div className="bg-background-primary-default object-cover w-[118px] h-[118px] aspect-square"></div>
                  )}
                </a>
                <div className="flex flex-col items-start justify-between flex-1">
                  <Details
                    url={url}
                    isDesktop={isDesktop}
                    originalLinePrice={originalLinePrice}
                    finalLinePrice={finalLinePrice}
                    title={title}
                    color={color}
                    size={size}
                    isSet={isSet}
                    discountAllocations={discountAllocations}
                    hasOnlyDefaultVariant={hasOnlyDefaultVariant}
                    properties={properties}
                  />
                  {isLast && (
                    <div className="flex flex-wrap justify-between items-center w-full gap-2 mt-3">
                      <Quantity
                        isDesktop={isDesktop}
                        isSet={isSet}
                        lineItemKeys={lineItemKeys}
                        quantity={setCount}
                      />
                      <RemoveButton lineItemKeys={lineItemKeys} />
                    </div>
                  )}
                </div>
                {isSet && isLast && (
                  <Text
                    variant="body-sm"
                    className={classNames(isDesktop && 'col-start-2')}
                  >
                    <span className="text-[#7D7D7D]">
                      {t('cart.setWithCount', { count: setCount })}:&nbsp;
                    </span>
                    <Price
                      originalLinePrice={originalLinePrice}
                      finalLinePrice={finalLinePrice}
                    />
                  </Text>
                )}
              </div>
            )
          }
        )}
      </div>
    </div>
  )
}
