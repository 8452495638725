import classNames from 'classnames'

import { HTMLAttributes, PropsWithChildren, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Icon } from '../../Icon'
import { useCarouselContext } from '../Carousel'
import { Arrow } from './Arrow'
import { IconButtonProps } from '../../IconButton/types'

export const ArrowNext: React.FC<
  Omit<IconButtonProps, 'icon' | 'aria-label'>
> = ({ className, ...props }) => {
  const { t } = useTranslation()
  const { canScrollNext, embla } = useCarouselContext()
  const onClick = useCallback(() => embla?.scrollNext(), [embla])

  if (!canScrollNext) {
    return null
  }

  return (
    <Arrow
      icon={<Icon.ChevronRight className="w-[6px] !text-base-primary" />}
      className={classNames(className, 'right-0 translate-x-1/2')}
      onClick={onClick}
      aria-label={t('action.next')}
      {...props}
    />
  )
}
