export const base64 = {
  encode: (value: unknown) => {
    const str = typeof value === 'string' ? value : JSON.stringify(value)
    return window.btoa(str)
  },
  decode: <T>(str: string): T => {
    const decoded = window.atob(str)
    try {
      return JSON.parse(decoded)
    } catch {
      return decoded as T
    }
  },
}
