import { RestClient } from '@lounge-fe/network'

export class Service {
  protected client: RestClient

  constructor() {
    this.client = new RestClient({
      baseURL: window.Lounge.integrations.api_base_url,
      headers: {
        'x-shopify-store': window.Lounge.shop_name,
      },
    })
  }
}
