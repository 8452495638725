import classNames from 'classnames'

import { Transition } from '../Transition'

import { BackdropProps } from './types'

export const Backdrop = ({
  isOpen = false,
  className,
  ...props
}: BackdropProps) => (
  <Transition.Fade
    className={classNames('backdrop', className)}
    data-testid="backdrop"
    show={isOpen}
    {...props}
  />
)
