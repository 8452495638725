import useEmblaCarousel from 'embla-carousel-react'
import Autoplay from 'embla-carousel-autoplay'
import classNames from 'classnames'

import { ShopifySectionComponent } from '@/types/ShopifySectionComponent'
import { Carousel, Text, Wrapper } from '@lounge-fe/ui-kit'
import { AnnouncementBarProps } from './types'
import { Countdown } from './Countdown'

export const AnnouncementBar: ShopifySectionComponent<AnnouncementBarProps> = ({
  data,
}) => {
  const { backgroundColor: backgroundColor, announcements } = data!
  const [emblaRef, embla] = useEmblaCarousel(
    { loop: true, active: announcements.length > 1 },
    [Autoplay()]
  )

  return (
    <div className={classNames('announcement-bar w-full', backgroundColor)}>
      <Wrapper className="px-0">
        <Carousel emblaRef={emblaRef} embla={embla} transition="fade">
          <Carousel.Viewport>
            <Carousel.Container className="gap-x-8 2xl:gap-x-14 auto-cols-[100%] mx-auto">
              {announcements.map((announcement, idx) => {
                const { type, text, endTime, postCountdownText } = announcement
                return (
                  <Carousel.Slide
                    key={idx}
                    index={idx}
                    className={'p-3 flex justify-center items-center'}
                  >
                    {type === 'countdown' && (
                      <Countdown
                        text={text}
                        targetDate={endTime}
                        postCountdownText={postCountdownText}
                      />
                    )}
                    {type === 'announcement' && (
                      <Text
                        variant="body-sm"
                        className="text-center"
                        dangerouslySetInnerHTML={{
                          __html: text,
                        }}
                      ></Text>
                    )}
                  </Carousel.Slide>
                )
              })}
            </Carousel.Container>
          </Carousel.Viewport>
        </Carousel>
      </Wrapper>
    </div>
  )
}
