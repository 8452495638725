import { Text } from '@lounge-fe/ui-kit'
import { useTranslation } from 'react-i18next'

export const Copyright = () => {
  const { t } = useTranslation()
  return (
    <Text variant="body-sm" className="text-[#7D7D7D] text-[10px]">
      {t('general.copyright', { year: new Date().getFullYear() })}
    </Text>
  )
}
