import classNames from 'classnames'

import { ProgressBarSizes, ProgressProps } from './types'

const sizes: {
  [K in ProgressBarSizes]: string
} = {
  sm: 'h-1',
  md: 'h-2',
  lg: 'h-4',
  xl: 'h-6',
}

const Progress: React.FC<ProgressProps> = ({
  children,
  className,
  size = 'md',
  ...props
}) => {
  return (
    <div
      {...props}
      className={classNames(
        'relative w-full rounded-md overflow-hidden bg-surface-primary',
        sizes[size],
        className
      )}
    >
      {children}
    </div>
  )
}

export { Progress }
