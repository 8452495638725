import { useCallback } from 'react'

import { Popover } from '../Popover'

import { useLightboxContext } from './Lightbox'
import { LightboxTriggerProps } from './types'

export const Trigger = <E extends HTMLElement>({
  index,
  children,
}: LightboxTriggerProps<E>) => {
  const { setSelectedSlideIndex } = useLightboxContext()

  const onClick = useCallback(() => setSelectedSlideIndex(index), [index])

  return (
    <Popover.Trigger onClick={onClick} id={undefined}>
      {children}
    </Popover.Trigger>
  )
}
