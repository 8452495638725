export interface UseSearchInsightsStore {
  queue: (() => unknown)[]
  hasInit: boolean
  init: () => void
  addToQueue: (fn: () => unknown) => void
}

export interface InitOptions {
  userHasOptedOut?: boolean
  anonymousUserToken?: boolean
  useCookie?: boolean
  cookieDuration?: number
  region?: 'de' | 'us'
  userToken?: string
  partial?: boolean
  host?: string
}

export enum ClickedProductAfterSearchEvent {
  Collections = 'Product Clicked (Collections)',
  Search = 'Product Clicked (Search)',
}

export interface ClickedProductAfterSearchOptions {
  objectID: string | string[]
  eventName: ClickedProductAfterSearchEvent
  position?: number
  queryID?: string
}

export enum ClickedFiltersEvent {
  Collections = 'Filters Clicked (Collections)',
}

export interface ClickedFiltersOptions {
  filters: string[]
  eventName: ClickedFiltersEvent
}

export enum ConvertedObjectIDsAfterSearchEvent {
  Collections = 'Product Added to Cart (Collections - Quick Add)',
  Search = 'Product Added to Cart (Search - Quick Add)',
  ProductPage = 'Product Added to Cart (Product Page)',
}

export interface ConvertedObjectIDsAfterSearchOptions {
  objectID: string | string[]
  eventName: ConvertedObjectIDsAfterSearchEvent
  queryID: string
}

export enum ViewedProductEvent {
  ProductPage = 'Product Viewed',
}

export interface ViewedProductOptions {
  objectID: string | string[]
  eventName?: ViewedProductEvent
}
