import { Select as BaseSelect } from './Select'
import { Label } from './Label'
import { Menu } from './Menu'
import { Option } from './Option'
import { HelperText } from './HelperText'

export const Select = Object.assign(BaseSelect, {
  Label,
  Option,
  Menu,
  Error,
  HelperText,
})
