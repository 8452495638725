import { useCountdown } from '@/hooks/useCountdown'
import { Text } from '@lounge-fe/ui-kit'
import { replaceSchemaVariables } from '@/utils'

type CountdownTimerProps = {
  text: string
  targetDate?: string
  postCountdownText?: string
}

const Countdown = ({
  text,
  targetDate,
  postCountdownText,
}: CountdownTimerProps) => {
  const { hasEnded, remainingTime } = useCountdown(targetDate)

  const {
    day: days,
    hour: hours,
    minute: minutes,
    second: seconds,
  } = remainingTime

  const formatTime = (time: number) => `${time}`.padStart(2, '0')

  const daysString = days > 0 ? `${formatTime(days)}:` : ''
  const hoursString = formatTime(hours)
  const minutesString = formatTime(minutes)
  const secondsString = formatTime(seconds)

  if (hasEnded) {
    return <Text variant="body-sm">{postCountdownText}</Text>
  } else {
    return (
      <Text
        variant="body-sm"
        dangerouslySetInnerHTML={{
          __html: replaceSchemaVariables(
            {
              remaining_time: `${daysString}${hoursString}:${minutesString}:${secondsString}`,
            },
            text
          ),
        }}
      />
    )
  }
}

export { Countdown }
