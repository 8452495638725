import { Children, cloneElement, isValidElement } from 'react'

import type { TableRowProps } from './types'

export const Row = ({ children, isHeader, ...props }: TableRowProps) => (
  <tr {...props}>
    {Children.map(children, (child) =>
      isValidElement(child) ? cloneElement(child, { isHeader }) : child
    )}
  </tr>
)
