import { FC } from 'react'

import { Backdrop as BaseBackdrop, BackdropProps } from '../Backdrop'

import { usePopoverGroupContext } from './Group'
import { usePopoverContext } from './Popover'

export const Backdrop: FC<Partial<BackdropProps>> = (props) => {
  const popoverGroupContext = usePopoverGroupContext()
  const popoverContext = usePopoverContext()

  const { isInGroup } = popoverGroupContext

  const isOpen = popoverContext.isOpen

  const onClose = () => {
    if (isInGroup) {
      popoverGroupContext.setPopoverIds([])
    } else {
      popoverContext.onClose()
    }
  }

  return (
    <BaseBackdrop
      isOpen={isOpen}
      onClick={onClose}
      onMouseEnter={
        !isInGroup && popoverContext.openReason === 'hover'
          ? onClose
          : undefined
      }
      data-ui-open={isOpen}
      data-popover-backdrop-type={isInGroup ? 'group' : 'popover'}
      data-popover-group-id={isInGroup ? popoverGroupContext.id : undefined}
      data-popover-id={!isInGroup ? popoverContext.popoverId : undefined}
      {...props}
    />
  )
}
