import * as Sentry from '@sentry/react'
import React from 'react'
import {
  createContext,
  createElement,
  useContext,
  useMemo,
  useState,
  Suspense,
} from 'react'
import { createPortal } from 'react-dom'
import {
  EntryPointContextState,
  EntryPointerInstance,
  EntryPointProps,
} from './types'

export const EntryPointContext = createContext<EntryPointContextState>({
  data: null,
})

export const EntryPoint = Sentry.withErrorBoundary(
  ({
    id,
    component,
    hasJsonData = true,
  }: EntryPointProps): JSX.Element | null => {
    const [containers] = useState(() => {
      const entryPointSelectorNodes = document.querySelectorAll(
        `[data-entry-point="${id}"]`
      )
      return entryPointSelectorNodes.length > 0
        ? entryPointSelectorNodes
        : document.querySelectorAll(`#${id}`)
    })

    const dataList: [Element, object][] = useMemo(() => {
      let data: [Element, object][] = []
      containers.forEach((container) => {
        if (!hasJsonData) {
          data.push([container, {}])
        } else {
          let parent = container
          let jsonNode: HTMLScriptElement | null = null
          // Traverse up tree until we find a json script tag
          while (!jsonNode && parent.parentElement) {
            parent = parent.parentElement
            jsonNode = parent.querySelector<HTMLScriptElement>(
              `script[type="application/json"][data-json="${id}"]`
            )
          }

          data.push([container, jsonNode ? JSON.parse(jsonNode.innerHTML) : {}])
        }
      })

      return data
    }, [containers])

    return (
      <>
        {dataList.map(([container, data], index) => (
          <EntryPointInstance
            key={index}
            component={component}
            container={container}
            data={data}
          />
        ))}
      </>
    )
  },
  {}
)

const EntryPointInstance = React.memo(
  ({ data, container, component }: EntryPointerInstance) => (
    <EntryPointContext.Provider value={{ data }}>
      <Suspense>
        {createPortal(createElement(component, { data }), container)}
      </Suspense>
    </EntryPointContext.Provider>
  )
)

export function useEntryPointContext<T>(): EntryPointContextState<T> {
  return useContext(EntryPointContext) as EntryPointContextState<T>
}
