import React from 'react'

import { SvgProps } from '../types'
import { Icon } from '../Icon'

export const Returns: React.FC<SvgProps> = (props) => {
  return (
    <Icon width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M9.61349 14.6209C9.48156 14.654 9.34705 14.6835 9.21353 14.7082C8.86451 14.7736 8.63377 15.1117 8.69833 15.4636C8.73021 15.6368 8.82782 15.7809 8.96033 15.8751C9.09691 15.9719 9.27073 16.0156 9.44785 15.9824C9.60685 15.9527 9.76709 15.9178 9.92433 15.8782C10.269 15.7918 10.479 15.4401 10.393 15.0931C10.3072 14.7458 9.95835 14.5344 9.61349 14.6209Z"
        fill="black"
      />
      <path
        d="M14.3742 5.94029C14.4192 6.07707 14.5052 6.18873 14.6139 6.26588C14.775 6.38018 14.9858 6.41863 15.1869 6.35163C15.5242 6.23882 15.7069 5.87249 15.5954 5.53293C15.5445 5.37813 15.4881 5.22285 15.428 5.07166C15.2961 4.73941 14.922 4.57766 14.592 4.71055C14.2623 4.84338 14.1017 5.22036 14.2337 5.55271C14.2842 5.67979 14.3315 5.81023 14.3742 5.94029Z"
        fill="black"
      />
      <path
        d="M11.7017 13.6934C11.5882 13.7689 11.4711 13.8419 11.3531 13.9103C11.0454 14.0891 10.94 14.4855 11.1174 14.7954C11.1656 14.8796 11.2298 14.9486 11.3037 15.0012C11.5021 15.1418 11.7716 15.1629 11.9959 15.0327C12.1362 14.9512 12.2756 14.8645 12.4108 14.7745C12.707 14.5774 12.7886 14.1754 12.5927 13.8768C12.3969 13.5781 11.9981 13.4961 11.7017 13.6934Z"
        fill="black"
      />
      <path
        d="M15.989 7.74749C15.975 7.38988 15.676 7.11154 15.3209 7.1255C14.9662 7.1396 14.6897 7.44091 14.7036 7.79838C14.709 7.93506 14.7103 8.07378 14.7072 8.21031C14.7022 8.43457 14.8111 8.63447 14.9803 8.75462C15.0811 8.82613 15.2035 8.8694 15.3361 8.87245C15.6911 8.88038 15.9853 8.59674 15.9932 8.23896C15.9967 8.07582 15.9954 7.91053 15.989 7.74749Z"
        fill="black"
      />
      <path
        d="M14.2706 11.9892C13.9859 11.7741 13.5832 11.8325 13.37 12.1187C13.2883 12.2283 13.2023 12.3366 13.1143 12.4408C12.8842 12.7132 12.9167 13.1222 13.187 13.3541C13.2024 13.3673 13.218 13.3794 13.2342 13.3907C13.5029 13.5816 13.8764 13.5377 14.0937 13.2809C14.1989 13.1565 14.3014 13.0272 14.3989 12.8963C14.6122 12.61 14.5545 12.204 14.2706 11.9892Z"
        fill="black"
      />
      <path
        d="M15.2171 9.64703C14.8782 9.53998 14.5172 9.73004 14.411 10.0715C14.3703 10.2019 14.3253 10.333 14.2767 10.4613C14.1699 10.7439 14.2722 11.0548 14.5067 11.2214C14.5498 11.2519 14.5972 11.2777 14.6487 11.2973C14.9805 11.4248 15.3521 11.2571 15.4786 10.9227C15.5364 10.7701 15.59 10.6141 15.6384 10.4591C15.7445 10.1176 15.556 9.75408 15.2171 9.64703Z"
        fill="black"
      />
      <path
        d="M6.80977 14.7143C6.23472 14.6103 5.68309 14.4332 5.16001 14.1862C5.15382 14.1829 5.14828 14.1792 5.14178 14.1762C5.01851 14.1178 4.89546 14.0552 4.77618 13.9898C4.77577 13.9893 4.77501 13.989 4.77436 13.9887C4.55552 13.8673 4.34193 13.733 4.13444 13.5858C1.10882 11.4389 0.381337 7.21258 2.51281 4.16481C2.97629 3.50232 3.53717 2.94963 4.16247 2.51158C4.17018 2.50617 4.17788 2.5008 4.18551 2.49536C6.38897 0.965988 9.38093 0.862918 11.7206 2.43449L11.2181 3.16588C11.0784 3.36946 11.1644 3.51781 11.4089 3.49564L13.5918 3.29878C13.8366 3.27661 13.9831 3.06323 13.9172 2.82501L13.3311 0.697592C13.2654 0.459095 13.0974 0.430547 12.9576 0.634087L12.4539 1.36721C10.7369 0.20615 8.67745 -0.23658 6.63337 0.120473C6.42749 0.156365 6.22451 0.200295 6.0243 0.251432C6.02276 0.251709 6.02152 0.251882 6.02028 0.252159C6.01254 0.254065 6.0047 0.256594 5.99717 0.258707C4.23453 0.714672 2.69664 1.75025 1.59926 3.2278C1.59001 3.23885 1.58048 3.24966 1.57175 3.26168C1.53526 3.31119 1.49904 3.36184 1.46358 3.41249C1.4056 3.4955 1.34843 3.58059 1.29375 3.66568C1.28691 3.67593 1.28168 3.68636 1.27569 3.69672C0.370125 5.11027 -0.0666667 6.74449 0.00824128 8.40822C0.00841325 8.41369 0.00810371 8.4192 0.00824128 8.42481C0.0154982 8.58733 0.0282924 8.7521 0.0456609 8.91431C0.0465895 8.92477 0.0488938 8.93468 0.0506479 8.94515C0.068601 9.10826 0.0911285 9.27175 0.119365 9.4352C0.406341 11.1029 1.18734 12.6037 2.35763 13.7714C2.36035 13.7741 2.36317 13.7771 2.36592 13.7799C2.36688 13.781 2.36795 13.7816 2.36888 13.7826C2.6833 14.095 3.0253 14.3839 3.39355 14.6452C4.35724 15.3292 5.43034 15.7814 6.58278 15.9897C6.93245 16.053 7.26648 15.8185 7.32921 15.4664C7.39191 15.1141 7.15934 14.7774 6.80977 14.7143Z"
        fill="black"
      />
      <path
        d="M7.60655 2.82031C7.31892 2.82031 7.08594 3.05521 7.08594 3.34453V8.56718L11.8277 11.0364C11.904 11.0762 11.9857 11.0949 12.0662 11.0949C12.2545 11.0949 12.4364 10.9916 12.5289 10.8114C12.6608 10.5541 12.561 10.238 12.3056 10.1051L8.12657 7.92881V3.34453C8.12653 3.05521 7.89383 2.82031 7.60655 2.82031Z"
        fill="black"
      />
    </Icon>
  )
}
