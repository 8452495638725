import React from 'react'

import { SvgProps } from '../types'
import { Icon } from '../Icon'

export const ParcelReturn: React.FC<SvgProps> = (props) => {
  return (
    <Icon width="16" height="16" fill="none" {...props}>
      <mask
        id="a"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="16"
      >
        <path d="M0 0h16v16H0V0Z" fill="#fff" />
      </mask>
      <g mask="url(#a)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.667 9.869V6.357l2.933.977v3.513L4.667 9.87ZM8 5.018l2.45.79L8 6.625 5.735 5.87l-.185-.062L8 5.018Zm3.333 4.85-2.933.98V7.333l2.933-.977v3.512Zm-3.67 1.843.337.112.387-.13 3.746-1.247V5.51l-.402-.13L8 4.178 4.269 5.38l-.402.13v4.935L7.6 11.69l.063.021ZM8 0a7.954 7.954 0 0 0-5.6 2.308V.4h-.8v3.2h3.2v-.8H3.034A7.167 7.167 0 0 1 8 .8c3.97 0 7.2 3.23 7.2 7.2h.8a8 8 0 0 0-8-8Zm3.2 13.2h1.766A7.163 7.163 0 0 1 8 15.2C4.03 15.2.8 11.97.8 8H0a8 8 0 0 0 8 8 7.95 7.95 0 0 0 5.6-2.308V15.6h.8v-3.2h-3.2v.8Z"
          fill="#000"
        />
      </g>
    </Icon>
  )
}
