import { createCompoundComponent } from '../../utilities'

import { Tile as BaseTile } from './Tile'
import { Content } from './Content'
import { Actions } from './Actions'
import { Image } from './Image'
import { Title } from './Title'
import { Subtitle } from './Subtitle'

export type { TileProps } from './types'

export const Tile = createCompoundComponent(BaseTile, {
  Content,
  Actions,
  Image,
  Title,
  Subtitle,
})
