import zod from '../../zod'

const email = zod.string().min(1).email()
const firstName = zod.string().min(1)
const lastName = zod.string().min(1)

export const promoSignup = zod.object({
  email,
  firstName,
  lastName,
  acceptsMarketing: zod.boolean().nullable(),
})

export type PromoSignupValues = zod.infer<typeof promoSignup>
