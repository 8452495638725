import React from 'react'

import { SvgProps } from '../types'
import { Icon } from '../Icon'

export const House: React.FC<SvgProps> = (props) => {
  return (
    <Icon width="30" height="25" viewBox="0 0 30 25" fill="none" {...props}>
      <path
        d="M29 23H27V11.4437C27 11.1669 26.9426 10.8931 26.8314 10.6397C26.7201 10.3862 26.5575 10.1586 26.3538 9.97124L16.3538 0.536245C16.3489 0.53198 16.3443 0.527386 16.34 0.522495C15.9718 0.187662 15.492 0.00212097 14.9944 0.00212097C14.4967 0.00212097 14.0169 0.187662 13.6488 0.522495L13.635 0.536245L3.64625 9.97124C3.4425 10.1586 3.27985 10.3862 3.16861 10.6397C3.05737 10.8931 2.99996 11.1669 3 11.4437V23H1C0.734784 23 0.48043 23.1054 0.292893 23.2929C0.105357 23.4804 0 23.7348 0 24C0 24.2652 0.105357 24.5196 0.292893 24.7071C0.48043 24.8946 0.734784 25 1 25H29C29.2652 25 29.5196 24.8946 29.7071 24.7071C29.8946 24.5196 30 24.2652 30 24C30 23.7348 29.8946 23.4804 29.7071 23.2929C29.5196 23.1054 29.2652 23 29 23ZM5 11.4437L5.01375 11.4312L15 1.99999L24.9875 11.4287L25.0012 11.4412V23H19V17C19 16.4696 18.7893 15.9609 18.4142 15.5858C18.0391 15.2107 17.5304 15 17 15H13C12.4696 15 11.9609 15.2107 11.5858 15.5858C11.2107 15.9609 11 16.4696 11 17V23H5V11.4437ZM17 23H13V17H17V23Z"
        fill="currentColor"
      />
    </Icon>
  )
}
